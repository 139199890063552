import theme from '../../styles/theme';
import { ReactComponent as PlaybookLogo } from '../../assets/logos/PlaybookLogo.svg';
import { ReactComponent as TikTokLogo } from '../../assets/logos/TikTokLogo.svg';
import { ReactComponent as BotpressLogo } from '../../assets/logos/BotpressLogo.svg';
export const MAIN_DATA = {
  en: {
    title: 'MarTech & AI',
    subTitle:
      "From raw data processing to applications, we solve client's problems with various marketing technologies & AI.",
    mainCardsLogo: [
      {
        title: 'Playbook',
        logo: <PlaybookLogo />,
        description:
          'Advanced MMM SaaS, solving problems of previous attribution models to help brands to measure true incrementality of all marketing channels.',
        buttonType: 'playbookLearnMore',
        color: theme.color.secondary.black,
        titleColor: theme.color.secondary.white,
        descriptionColor: theme.color.secondary.white,
      },
    ],
    mainCards: [
      {
        title: 'Swen AI',
        description:
          'Swen AI is a news generation platform that provides various article ideas from multiple sources, drafts articles, and helps writers manage publishing.',
        buttonType: 'swenLearnMore',
        color: theme.color.neutral.spaceGray2,
        titleColor: theme.color.secondary.white,
        descriptionColor: theme.color.secondary.white,
      },
      {
        title: 'SocialBot AI',
        description:
          'SocialBot AI manages social channels, including Instagram, by replying to user comments and direct messages using verified media sources.',
        buttonType: 'socialbotLearnMore',
        color: theme.color.neutral.spaceGray3,
        titleColor: theme.color.secondary.white,
        descriptionColor: theme.color.secondary.white,
      },
    ],
  },
  ko: {
    title: 'MarTech & AI',
    subTitle:
      '데이터 처리부터 응용 프로그램까지, 다양한 마케팅 테크과 AI를 사용하여 고객의 문제를 해결합니다',
    mainCardsLogo: [
      {
        title: '플레이북',
        logo: <PlaybookLogo />,
        description:
          '마케팅 믹스 모델링 SaaS 플레이북은 이전의 어트리뷰션 모델의 문제를 해결하여 브랜드가 모든 마케팅 채널의 실제 증가 효과를 측정할 수 있도록 돕습니다.',
        buttonType: 'playbookLearnMore',
        color: theme.color.secondary.black,
        titleColor: theme.color.secondary.white,
        descriptionColor: theme.color.secondary.white,
      },
    ],
    mainCards: [
      {
        title: '스웬 AI',
        description:
          'Swen AI는 다양한 소스에서 기사 아이디어를 제공하고, 기사 초안 작성하며, 기사의 데스킹을 관리할 수 있도록 돕는 뉴스 생성 플랫폼입니다.',
        buttonType: 'swenLearnMore',
        color: theme.color.neutral.spaceGray2,
        titleColor: theme.color.secondary.white,
        descriptionColor: theme.color.secondary.white,
      },
      {
        title: '소셜봇 AI',
        description:
          'SocialBot AI는 검증된 미디어 소스를 사용하여 인스타그램을 포함한 소셜 채널에서 사용자 댓글과 다이렉트 메시지에 응답합니다.',
        buttonType: 'socialbotLearnMore',
        color: theme.color.neutral.spaceGray3,
        titleColor: theme.color.secondary.white,
        descriptionColor: theme.color.secondary.white,
      },
    ],
  },
};

export const SERVICE_DATA = {
  en: {
    title:
      'If you need AI application development, discuss it with the Panomix team.',
    subTitle:
      'From raw data processing to applications, our expertise lies in the details of every step in the data journey.',
    mainCardsLogo: [
      {
        title: 'TikTok Signals Partnership',
        logo: <TikTokLogo />,
        description:
          "Panomix has joined TikTok's data partnership to provide pixel and eAPI implementation for clients.",
        buttonType: 'tiktokLearnMore',
        color: theme.color.others.lightGreen,
        titleColor: theme.color.secondary.black,
        descriptionColor: theme.color.secondary.black,
      },
      {
        title: 'Botpress AI Chatbot',
        logo: <BotpressLogo />,
        description:
          'Panomix is a certified partner of Botpress, a leading AI chatbot platform. Contact us if you need support with implementing a Botpress chatbot.',
        buttonType: 'botpressLearnMore',
        color: theme.color.others.lightBlue,
        titleColor: theme.color.secondary.black,
        descriptionColor: theme.color.secondary.black,
      },
    ],
    mainCards: [
      {
        title: 'Custom AI applications',
        description:
          'Our custom AI application service identifies problems, designs tailored solutions, and develops applications to meet your unique business needs',
        buttonType: 'customLearnMore',
        color: theme.color.others.yellow,
        titleColor: theme.color.secondary.black,
        descriptionColor: theme.color.secondary.black,
      },
    ],
  },
  ko: {
    title:
      'AI 애플리케이션을 구상 중이시라면, 파노믹스 팀과 함께 상의해 보세요.',
    subTitle:
      '데이터 처리부터 애플리케이션까지 이어지는 데이터 여정의 모든 스텝들을 만들어갑니다.',
    mainCardsLogo: [
      {
        title: '틱톡 시그널 파트너십',
        logo: <TikTokLogo />,
        description:
          '파노믹스는 틱톡의 픽셀 및 eAPI 구현을 제공하는 오피셜 데이터 파트너사입니다.',
        buttonType: 'tiktokLearnMore',
        color: theme.color.others.lightGreen,
        titleColor: theme.color.secondary.black,
        descriptionColor: theme.color.secondary.black,
      },
      {
        title: '봇프레스 AI 챗봇',
        logo: <BotpressLogo />,
        description:
          '파노믹스는 AI 챗봇 플랫폼인 Botpress의 인증 파트너입니다. Botpress 챗봇 구현이 필요하시면 저희에게 문의하세요.',
        buttonType: 'botpressLearnMore',
        color: theme.color.others.lightBlue,
        titleColor: theme.color.secondary.black,
        descriptionColor: theme.color.secondary.black,
      },
    ],
    mainCards: [
      {
        title: '커스텀 AI 애플리케이션',
        description:
          '저희의 맞춤형 AI 애플리케이션 서비스는 고객사의 상황을 파악하고, 맞춤형 솔루션을 설계 및 개발하여 고객의 문제를 해결합니다.',
        buttonType: 'customLearnMore',
        color: theme.color.others.yellow,
        titleColor: theme.color.secondary.black,
        descriptionColor: theme.color.secondary.black,
      },
    ],
  },
};
