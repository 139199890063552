import React from 'react';
import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';
import { SERVICE_OPTIONS } from './SERVICE_OPTIONS';
import Button from '../../components/Button/Button';

export default function ServicesMobile() {
  return (
    <Wrapper>
      <HeadlineWrapper>
        <Headline>Comprehensive ML Analytics Services</Headline>
      </HeadlineWrapper>
      <MainCardWrapper>
        {SERVICE_OPTIONS.map((item, idx) => (
          <MainCardArea key={idx}>
            <ServiceName>{item.name}</ServiceName>
            <ServiceTitle>{item.title}</ServiceTitle>
            <ServiceDescription>{item.description}</ServiceDescription>
            <IconArea>{item.icon}</IconArea>
            <Button type={item.button.type} />
          </MainCardArea>
        ))}
      </MainCardWrapper>
      <Footer />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  bottom: 40px;
  margin-top: 80px;
  margin-bottom: 100px;
  transition: all ease 0.7s;
`;

const HeadlineWrapper = styled.div`
  display: flex;
  background-color: #1a1f27;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100vw;
  justify-content: center;
  text-align: center;
`;

const Headline = styled.div`
  color: #0b8ffc;
  font-size: 32px;
  line-height: 40px;
  font-family: Poppins;
  font-weight: 500;
  width: 275px;
`;

const MainCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
`;

const MainCardArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 320px;
  padding: 20px;
  gap: 10px;
  border-radius: 12px;
  box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.25);
`;

const ServiceName = styled.div`
  font-family: Poppins;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
`;

const ServiceTitle = styled.div`
  font-family: Arial;
  font-size: 16px;
  line-height: 22px;
  width: 260px;
  text-align: center;
  color: #0b8ffc;
`;

const ServiceDescription = styled.div`
  font-size: 12px;
  font-family: Arial;
  line-height: 16px;
  text-align: center;
`;

const IconArea = styled.div`
  margin-top: 10px;
`;
