import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import NavDesktop from '../../components/Nav/Desktop/NavDesktop';
import NavMobile from '../../components/Nav/Mobile/NavMobile';

import CookiePolicy from './contents/CookiePolicy';
import Terms from './contents/Terms';
import PrivacyPolicy from './contents/PrivacyPolicy';

import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';

export default function TermsAndPrivacy({ type }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [type]);

  const isDesktop = useMediaQuery({ minWidth: 981 });

  const PAGES = {
    cookies: <CookiePolicy />,
    terms: <Terms />,
    privacy: <PrivacyPolicy />,
  };

  return (
    <Wrapper>
      {isDesktop ? <NavDesktop /> : <NavMobile />}
      <AlignBox isDesktop={isDesktop}>{PAGES[type]}</AlignBox>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: auto;
`;

const AlignBox = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ isDesktop }) =>
    isDesktop ? '90px 0px 80px 0px' : '80px 0px 120px 0px'};
  width: 100%;
  max-width: 1200px;
`;
