import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../../contextApi/LanguageContext';
import theme from '../../../styles/theme';
import { ReactComponent as DataLakeImage } from '../../../assets/images/DataLake.svg';

export const MarketingDatalake = () => {
  const { language } = useContext(LanguageContext);

  const [currentDashboard, setCurrentDashboard] = useState(0);

  const DATA = {
    sectionTitle: {
      en: 'Marketing DataLake 2.0',
      ko: '마케팅 데이터레이크 2.0',
    },
    subTitle: {
      en: 'Marketing DataLake 2.0',
      ko: '마케팅 데이터레이크 2.0',
    },
    subDescription: {
      en: 'All marketing channels report in their unique ways. Our marketing data lake system retrieves data from all marketing channels in near real-time. To ensure the integrity of the data, we store raw data in our cloud-based data lake in a partitioned format, ensuring freshness, security, and usability.',
      ko: '모든 마케팅 채널은 각자 고유한 방식으로 보고합니다. 우리의 마케팅 데이터 레이크 시스템은 거의 실시간으로 모든 마케팅 채널에서 데이터를 추출합니다. 데이터의 원본성을 보장하기 위해 로우 데이터를 파티션 형식으로 구성하여 클라우드 기반 데이터 레이크에 저장하여 최신성, 보안 및 사용 용이성을 보장합니다.',
    },
  };

  return (
    <>
      <SectionTitle>{DATA.sectionTitle[language]}</SectionTitle>
      <DashboardGrid>
        <DashboardFirstGrid>
          <DataLakeImage />
        </DashboardFirstGrid>
        <DashboardSecondGrid>
          <DashboardTitle>{DATA.subTitle[language]}</DashboardTitle>
          <DashboardDesc>{DATA.subDescription[language]}</DashboardDesc>
        </DashboardSecondGrid>
      </DashboardGrid>
      <Divider />
    </>
  );
};

const SectionTitle = styled.h2`
  font-size: 44px;
  line-height: 50px;
  font-family: 'Poppins';
  font-weight: 300;
  width: auto;
  max-width: 680px;
  color: black;
  margin-bottom: 40px;
  align-text: left;
`;

const DashboardGrid = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
`;

const DashboardFirstGrid = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

const DashboardSecondGrid = styled.div`
  flex: 1;
`;

const DashboardTitle = styled.div`
  font-size: 22px;
  line-height: 32px;
  font-family: Arial;
  width: auto;
  color: black;
  font-weight: bold;
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 100%;
  margin-right: 40px;
  margin-top: 80px;
  margin-bottom: 48px;
`;
const DashboardDesc = styled.div`
  font-size: 18px;
  font-family: Arial;
  line-height: 26px;
  font-weight: regular;
  color: black;
  margin-top: 10px;
`;

const ItemImage = styled.img`
  max-width: 100%;
  height: auto;
  min-width: 600px;

  @media (max-width: 980px) {
    min-width: 300px;
    margin: 40px auto;
  }
`;
