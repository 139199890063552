import PartnerLogos from '../../assets/images/PartnerLogos.png';
import PartnerLogos_v from '../../assets/images/PartnerLogos_v.png';

import theme from '../../styles/theme';

export const INDEX_01 = {
  title: {
    desktop: 'Delivering right strategies to clients',
    mobile: (
      <>
        Delivering right strategies
        <br /> to clients
      </>
    ),
  },
  description: {
    desktop:
      'Our core value is to deliver right strategies and tools to clients.',
    mobile: (
      <>
        Our core value is
        <br />
        to deliver right strategies and tools to clients
      </>
    ),
  },
  button: {
    type: 'contactUs',
    buttonColor: theme.color.primary.green,
    nameColor: theme.color.secondary.black,
  },
  contents: {
    history: [
      {
        year: 2017,
        description: 'Company found',
        color: theme.color.primary.blue,
      },
      {
        year: 2018,
        description: 'Company focused on custom data analytics solutions',
        color: theme.color.primary.blue,
      },
      {
        year: 2019,
        description:
          'Became a marketing agency offering creatives, ad operations & analytics',
        color: theme.color.primary.blue,
      },
      {
        year: 2022,
        description: 'Launched marketing intelligence SaaS, Playbook',
        color: theme.color.primary.green,
      },
    ],
    partners: {
      title: 'Our partners',
      image: {
        desktop: PartnerLogos,
        mobile: PartnerLogos_v,
      },
    },
  },
};

export const INDEX_02 = {
  title: 'Creatives that are effective',
  description: {
    desktop: (
      <>
        We believe ceatives that have targeted objectives are more effective
        than
        <br /> just beautiful images and videos. Learn more about media mix
        plans
      </>
    ),
    mobile:
      'We believe ceatives that have targeted objectives are more effective than just beautiful images and videos. Learn more about media mix plans.',
  },
  button: {
    type: 'learnMore',
  },
  video: {
    desktop: '/videos/Panomix_showreel.mp4',
    mobile: '/videos/Panomix_showreel_v.mp4',
  },
};
