import React from 'react';

import styled from 'styled-components';
import Button from '../../../components/Button/Button';
import theme from '../../../styles/theme';
import { MAIN_DATA, SERVICE_DATA } from '../MAIN_DATA';
import ServiceCardMobile from '../../../components/ServiceCardMobile';
import { ReactComponent as Logo1 } from '../../../assets/logos/logo_Ariul.svg';
import { ReactComponent as Logo2 } from '../../../assets/logos/logo_Wishtrend.svg';
import { ReactComponent as Logo3 } from '../../../assets/logos/logo_Didier Dubot.svg';
import { ReactComponent as Logo4 } from '../../../assets/logos/logo_Ggumbi.svg';
import { ReactComponent as Logo5 } from '../../../assets/logos/logo_Hang5.svg';
import { ReactComponent as Logo6 } from '../../../assets/logos/logo_Infinity Ventures.svg';
import { ReactComponent as Logo7 } from '../../../assets/logos/logo_Be Attitude.svg';
import { ReactComponent as Logo8 } from '../../../assets/logos/logo_Klairs.svg';
import { ReactComponent as Logo9 } from '../../../assets/logos/logo_Nurihaus.svg';
import { ReactComponent as Logo10 } from '../../../assets/logos/logo_Untold Lable.svg';
import ProductCardMobile from '../../../components/ProductCardMobile';

export default function MobileContents(props) {
  const { language } = props;
  return (
    <Wrapper>
      <TopPage>
        <Title>{MAIN_DATA[language].title}</Title>
        <TitleDescription>{MAIN_DATA[language].subTitle}</TitleDescription>
        <Button type='scheduleDemo' />
        <SectionTitle>PRODUCTS</SectionTitle>
        <MainCardArea>
          {MAIN_DATA[language].mainCardsLogo.map((service, idx) => (
            <ProductCardMobile
              key={idx}
              color={service.color}
              logo={service.logo}
              description={service.description}
              buttonType={service.buttonType}
              titleColor={service.titleColor}
              descriptionColor={service.descriptionColor}
            />
          ))}
          {MAIN_DATA[language].mainCards.map((service, idx) => (
            <ServiceCardMobile
              key={idx}
              color={service.color}
              title={service.title}
              description={service.description}
              buttonType={service.buttonType}
              titleColor={service.titleColor}
              descriptionColor={service.descriptionColor}
            />
          ))}
        </MainCardArea>
        <BannerBox>
          <BannerTitle>{SERVICE_DATA[language].title}</BannerTitle>
          <div>
            <Button type='contactUs' isInNav={false} />
          </div>
        </BannerBox>
        <SectionTitle>SERVICES</SectionTitle>
        <MainCardArea>
          {SERVICE_DATA[language].mainCards.map((service, idx) => (
            <ServiceCardMobile
              key={idx}
              color={service.color}
              title={service.title}
              description={service.description}
              buttonType={service.buttonType}
              titleColor={service.titleColor}
              descriptionColor={service.descriptionColor}
            />
          ))}
          {SERVICE_DATA[language].mainCardsLogo.map((service, idx) => (
            <ProductCardMobile
              key={idx}
              color={service.color}
              logo={service.logo}
              description={service.description}
              buttonType={service.buttonType}
              titleColor={service.titleColor}
              descriptionColor={service.descriptionColor}
            />
          ))}
        </MainCardArea>
      </TopPage>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  bottom: 40px;
  margin-bottom: 100px;
`;

const TopPage = styled.div`
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  gap: 20px;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 38px;
  font-weight: medium;
  line-height: 38px;
  color: ${({ theme }) => theme.color.neutral.spaceGray1};
  text-align: center;

  width: auto;
  max-width: 480px;
  margin: 0px 24px 0px 24px;
`;

const TitleDescription = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 24px;
  font-weight: 200;
  color: ${({ theme }) => theme.color.neutral.spaceGray3};
  text-align: center;
  width: auto;
  max-width: 480px;
  margin: 0px 24px 0px 24px;
`;

const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.color.neutral.spaceGray3};
  font-family: 'Poppins';
  font-size: 18px;
  margin-top: 40px;
`;

const BannerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 180px;
  border: 0px solid black;
  overflow: hidden;
  background-color: none;
  margin-top: 40px;
`;

const BannerTitle = styled.h2`
  font-size: 18px;
  font-family: 'Roboto';
  line-height: 120%;
  color: black;
  text-align: center;
  font-weight: 400;
  margin-bottom: 30px;
`;

const LogoWrapper = styled.div`
  margin-top: 20px;
  max-width: 360px;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
`;

const LogoSlider = styled.div`
  display: inline-block;
  gap: 40px;
  ${({ theme }) => theme.animation.infiniteLoop}
`;

const MainCardArea = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 10px;
`;

const ModelWorksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 60px;
`;
const ModelHeadline = styled.div`
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: medium;
  line-height: 28px;
  color: ${({ theme }) => theme.color.neutral.spaceGray1};
  width: 240px;
  text-align: center;
`;

const ProductArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
`;
const ProductAreaLast = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
`;

const DetailArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Headline = styled.div`
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: medium;
  line-height: 24px;
  color: ${({ theme }) => theme.color.neutral.spaceGray1};
  width: 340px;
  text-align: center;
`;

const Description = styled.div`
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 22px;
  font-weight: 200;
  color: ${({ theme }) => theme.color.neutral.spaceGray3};
  width: 340px;
  text-align: center;
  margin-bottom: 10px;
`;

const MobileBottom = styled.div`
  magin-bottom: 0px;
`;
