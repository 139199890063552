import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import EP01 from './Pages/EP01';
import EP02 from './Pages/EP02';
import EP03 from './Pages/EP03';
import EP04 from './Pages/EP04';
import styled from 'styled-components';
import Footer from '../../components/Footer/Footer';
import NavDesktop from '../../components/Nav/Desktop/NavDesktop';
import NavMobile from '../../components/Nav/Mobile/NavMobile';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { Helmet } from 'react-helmet';
import EP05 from './Pages/EP05';

export default function BlogDetail() {
  const { language, setLanguage } = useContext(LanguageContext);
  const { EP } = useParams();
  const isDesktop = useMediaQuery({ minWidth: 981 });
  const EPISODES = {
    EP01: {
      title: "Playbook's Dynamic ML Model",
      description:
        "Discover how Playbook, a leading SaaS-based media mix modeling platform, is revolutionizing the world of marketing optimization. Learn about its innovative Dynamic Model, which automatically adapts to new data and ensures optimal convergence for maximum ROI. Explore Playbook's user-friendly interface and seamless data integrations, making data-driven decision-making accessible to companies of all sizes. Unleash the power of Playbook to save time, resources, and maximize your marketing ROI.",
      component: <EP01 />,
    },
    EP02: {
      title: 'Client Case - Boosters',
      description:
        "Learn about the challenges faced by Boosters, and how Playbook's advanced model transformed their marketing strategy, uncovering over 40% additional revenue contributions. Explore the impressive results of Branden's increased expenditure on Facebook Ads and the improvements in revenue and session quality.",
      component: <EP02 />,
    },
    EP03: {
      title: 'Paradox of Ad Platforms',
      description:
        "Explore the complexities of ad measurement in the era of Facebook advertising and the challenges marketers face in accurately assessing its true value. Discover how reporting discrepancies arise between actual revenue and Facebook-reported revenue, and the role of attribution in this context. Dive into the impact of incorporating non-Facebook channels into the marketing mix and the limitations of basic reporting systems. Delve into three different reporting models, including Playbook's ML model, and their varying attributions of revenue to Facebook Ads. At Panomix, we prioritize capturing incrementals generated by all marketing sources, advocating for the use of machine-learning algorithms to provide a more nuanced understanding of marketing contributions and enable precise measurement and optimization of advertising strategies. Join us in redefining ad measurement for a data-driven future.",
      component: <EP03 />,
    },
    EP04: {
      title: 'MMM - Data Preparation',
      description:
        "In today's data-driven business landscape, Media Mix Modeling (MMM) is a powerful tool for optimizing marketing strategies and achieving maximum returns. However, success with MMM requires meticulous data preparation and execution. This foundation begins with data collection and consolidation, encompassing a significant time period and all marketing efforts. Data transformation is crucial to make raw data analysis-ready, and incorporating external factors that influence marketing outcomes enhances predictive accuracy. By addressing these aspects, companies can harness the full potential of MMM, ensuring data-driven decisions that elevate marketing efforts to new heights of success.",
      component: <EP04 />,
    },
    EP05: {
      title: 'MMM - Improving Performance',
      description:
        'Without adequate performance metrics, Media Mix Models lose their predictive accuracy. Discover the methods we employed to enhance model performance and obtain trustworthy insights.',
      component: <EP05 />,
    },
  };
  return (
    <>
      <Helmet>
        <title>{EPISODES[EP].title}</title>
        <meta name='description' content={EPISODES[EP].description} />
      </Helmet>
      {isDesktop ? (
        <NavDesktop language={language} setLanguage={setLanguage} />
      ) : (
        <NavMobile language={language} setLanguage={setLanguage} />
      )}
      <Wrapper>{EPISODES[EP].component}</Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 160px 40px 260px 40px;

  @media (max-width: 980px) {
    margin: 100px 10px 40px 10px;
  }
  transition: all ease 4s;
  ${({ theme }) => theme.animation.fadeInBottom}
  @media (min-width: 1440px) {
    padding-left: calc((100% - 1440px) / 2);
    padding-right: calc((100% - 1440px) / 2);
  }
`;
