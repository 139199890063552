import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import NavDesktop from '../../components/Nav/Desktop/NavDesktop';
import NavMobile from '../../components/Nav/Mobile/NavMobile';
import Footer from '../../components/Footer/Footer';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { Helmet } from 'react-helmet';

export default function Blog() {
  const { language, setLanguage } = useContext(LanguageContext);
  const isDesktop = useMediaQuery({ minWidth: 981 });
  const navigate = useNavigate();
  const EPs = [
    {
      title: 'Exploring MMM (2/3)',
      alt: 'EP05',
      image: require('./Images/EP05.png'),
      date: 'Sep 08, 2023',
      url: '/blog/EP05',
    },
    {
      title: 'Exploring MMM (1/3)',
      alt: 'EP04',
      image: require('./Images/EP04.png'),
      date: 'Sep 06, 2023',
      url: '/blog/EP04',
    },
    {
      title: 'Paradox of Ad Platforms',
      alt: 'EP03',
      image: require('./Images/EP03.png'),
      date: 'July 4, 2023',
      url: '/blog/EP03',
    },
    {
      title: '[Case Study] Boosters',
      alt: 'EP02',
      image: require('./Images/EP02.png'),
      date: 'June 29, 2023',
      url: '/blog/EP02',
    },
    {
      title: "Playbook's Dynamic Modeling",
      alt: 'EP01',
      image: require('./Images/EP01.png'),
      date: 'May 12, 2023',
      url: '/blog/EP01',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Latest Articles</title>
        <meta
          name='description'
          content='Check out our latest articles on AI, ML, Marketing & more.'
        />
      </Helmet>
      {isDesktop ? (
        <NavDesktop language={language} setLanguage={setLanguage} />
      ) : (
        <NavMobile language={language} setLanguage={setLanguage} />
      )}
      <Wrapper>
        <Title>Blog</Title>
        <Description>
          About marketing, data, machine learning, insights, and more.
        </Description>
        <Divider />
        <ContentWrapper>
          {EPs.map((item, idx) => (
            <Item key={idx}>
              <ItemImage
                key={idx}
                src={item.image}
                alt={item.alt}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate(item.url);
                }}
              />
              <ItemTitle>{item.title}</ItemTitle>
              <ItemTime>{item.date}</ItemTime>
            </Item>
          ))}
        </ContentWrapper>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 250px 40px 180px 40px;

  @media (max-width: 980px) {
    margin: 100px 10px 180px 10px;
  }
  transition: all ease 4s;
  ${({ theme }) => theme.animation.fadeInBottom}
  @media (min-width: 1440px) {
    padding-left: calc((100% - 1440px) / 2);
    padding-right: calc((100% - 1440px) / 2);
  }
`;

const Title = styled.div`
  font-size: 84px;
  font-family: Poppins;
  margin-bottom: 40px;

  @media (max-width: 980px) {
    font-size: 48px;
    margin-bottom: 20px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  font-family: Poppins;
  font-weight: 200;
  margin-bottom: 20px;
  color: #44505f;

  @media (max-width: 980px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  grid-gap: 20px;
  margin-top: 20px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemImage = styled.img`
  width: 100%;
  height: auto;
  cursor: pointer;
`;

const ItemTitle = styled.div`
  margin-top: 10px;
  font-size: 18px;
  font-family: Arial;
  font-weight: 600;
  text-align: left;
  cursor: pointer;

  @media (max-width: 980px) {
    font-size: 12px;
  }
`;
const ItemTime = styled.div`
  margin-top: 10px;
  font-size: 18px;
  font-family: Arial;
  font-weight: 400;
  text-align: left;
  color: #44505f;
  @media (max-width: 980px) {
    font-size: 12px;
  }
`;
