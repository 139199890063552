import React from 'react';
import styled from 'styled-components';

export default function PrivacyPolicy() {
  return (
    <Wrapper>
      <Headline>Panomix Privacy Policy</Headline>
      Panomix.io collects some Personal Data from its Users.
      <br />
      <br />
      This document can be printed for reference by using the print command in
      the settings of any browser.
      <br />
      <br />
      Personal Data processed for the following purposes and using the following
      services:
      <br />
      <br />
      Access to Third-party Accounts
      <br />
      Facebook account access
      <br />
      Permissions requested: About me, Email address
      <br />
      <br />
      Advertising
      <br />
      Google Ad Manager
      <br />
      Personal data collected: Cookies, Usage Data
      <br />
      <br />
      Analytics
      <br />
      Google Analytics and Facebook Ads Conversion tracking (Facebook pixel)
      <br />
      Personal data collected: Tracker, Usage Data
      <br />
      <br />
      Registration and authentication
      <br />
      Google OAuth and Facebook Authentication
      <br />
      Personal data collected: Tracker; various types of Data as specified in
      the privacy policy of the service in the services’ privacy policies
      <br />
      <br />
      Direct Registration
      <br />
      Personal data collected: Dountry, Email address
      <br />
      <br />
      Remarketing and behavioral targeting
      <br />
      Facebook Remarketing, Google Ads Remarketing, and Remarketing with Google
      Analytics
      <br />
      Personal data collected: Cookies, Usage data
      <br />
      <br />
      Contact information
      <br />
      Owner and Data Controller
      <br />
      Panomix Inc. - 202 44 World Cup Buk-ro 4-gil
      <br />
      Mapo-gu, Seoul, Korea
      <br />
      Owner contact email: info@panomix.io
      <br />
      <br />
      Types of data collected
      <br />
      <br />
      The types of Personal Data which panomix.io collects, by itself or through
      third parties, includes but is not limited to cookies, usage data, Email
      address, username, country, website, and phone number.
      <br />
      <br />
      Complete details on each type of Personal Data collected are provided in
      the dedicated sections of this privacy policy or by specific explanation
      texts displayed prior to the Data collection. Personal Data may be freely
      provided by the User, or, in case of Usage Data, collected automatically
      when using this Application.
      <br />
      <br />
      Unless specified otherwise, all Data requested by this Application is
      mandatory and failure to provide this Data may make it impossible for this
      Application to provide its services. In cases where this Application
      specifically states that some Data is not mandatory, Users are free not to
      communicate this Data without consequences to the availability or the
      functioning of the Service. Users who are uncertain about which Personal
      Data is mandatory are welcome to contact the Owner. Any use of Cookies –
      or of other tracking tools – by this Application or by the owners of
      third-party services used by this Application serves the purpose of
      providing the Service required by the User, in addition to any other
      purposes described in the present document and in the Cookie Policy, if
      available.
      <br />
      <br />
      Users are responsible for any third-party Personal Data obtained,
      published or shared through this Application and confirm that they have
      the third party's consent to provide the Data to the Owner.
      <br />
      <br />
      <Link>
        We collect information on our Website(https://panomix.io/) and/or our
      </Link>
      Platforms that can identify a user or may, with reasonable efforts, cause
      the identification of a user (“Personal Information”) when you:
      <br />
      <br />
      Create a User account (“Account”): your first and last name, and your
      email address, phone number, company name, company website address,
      business types, country, field of activity, pictures, budget, credit card
      information etc.
      <br />
      <br />
      Submission of Social Media Platforms Information: Users may choose to
      share with us additional information from social media platforms (which
      may also be considered Personal Information) such as your Facebook ID,
      Google Ads Account ID, and Google Analytics Account ID.
      <br />
      <br />
      Commence direct communications with us: We collect Personal Information
      when you commence direct communications with us, for example via our
      Website inquiry form. When you commence direct communications with us, we
      will retain such communication(s) and the accompanying Personal
      Information for the amount of time necessary to adequately and
      professionally process your inquiries, respond to your requests and
      improve our services to our Users. When commencing a communication with
      us, we will usually collect your user ID, email address, and any
      additional information you may choose to provide.
      <br />
      <br />
      Submit your resume: We collect your Personal Information when you submit
      your resume such as your name, email, phone number, the content of your
      resume and any other information you choose to share with us, in order to
      properly process your application, and contact you should a position be
      relevant for you.
      <br />
      <br />
      Subscribe to receive marketing materials from us: When you consent to us
      sending you marketing materials, for example our newsletter or blog
      digest, we will collect your Personal Information, such as first name,
      last name, work email, phone number, company information, and average
      amount spent per month on social media.
      <br />
      <br />
      Register to a Panomix event: We collect Personal Information such as your
      first and last name, and email address when you register to an event we
      post on our Website.
      <br />
      <br />
      Collection of non-personal, statistical information: When browsing through
      our Website, we will collect non-personal, aggregate information about
      your interaction with our Website, including pages browsed, search
      inquiries, the type of browser you are using, non-identifiable cookies and
      your domain type and server ( and collectively, “Statistical
      Information”). We collect this type of Statistical Information in order to
      analyze and improve User’s use of the Website, customer support, sales
      intelligence and for debugging purposes.
      <br />
      <br />
      Purposes of the collection of information
      <br />
      <br />
      Legal basis for collecting and processing of Personal Information: We
      collect, process and use your information for the purposes described in
      this Privacy Policy, based at least on one of the following legal grounds
      <br />
      <br />
      With your consent: We ask for your agreement to process your information
      for specific purposes and you have the right to withdraw your consent at
      any time. For example, we ask for your consent to provide you with
      personalized content and ads through the use of cookies, or to provide you
      with marketing material pursuant to your “contact us” request, you
      submitted through our Website.
      <br />
      <br />
      When Performing this Agreement: We collect and process your Personal
      Information in order to provide you with the Services, following your
      acceptance of this Privacy Policy; to maintain and improve our Services;
      to develop new services and features for our Users; and to personalize the
      Services in order for you to get a better user experience. For example, we
      collect your login credentials to your Social Network Advertiser Account
      in order to provide you with the Services.
      <br />
      <br />
      Legitimate interests: We process your information for our legitimate
      interests while applying appropriate safeguards that protect your privacy.
      This means that we process your information for things like detecting,
      preventing, or otherwise addressing fraud, abuse, security, usability,
      functionality or technical issues with our Services, protecting against
      harm to the rights, property or safety of our properties, or our users, or
      the public as required or permitted by law; Enforcing legal claims,
      including investigation of potential violations of this Privacy Policy; in
      order to comply and/or fulfil our obligation under applicable laws,
      regulation, guidelines, industry standards and contractual requirements,
      legal process, subpoena or governmental request, as well as our Terms of
      Service and our billing process.
      <br />
      <br />
      While using Our Service, We may ask You to provide Us with certain
      personally identifiable information that can be used to contact or
      identify You. Personally identifiable information may include, but is not
      limited to:
      <br />
      <br />
      Email address
      <br />
      First name and last name
      <br />
      Phone number
      <br />
      Address, State, Province, ZIP/Postal code, City
      <br />
      Usage Data
      <br />
      <br />
      Usage Data
      <br />
      <br />
      Usage Data is collected automatically when using the Service.
      <br />
      <br />
      Usage Data may include information such as Your Device's Internet Protocol
      address (e.g. IP address), browser type, browser version, the pages of our
      Service that You visit, the time and date of Your visit, the time spent on
      those pages, unique device identifiers and other diagnostic data.
      <br />
      <br />
      Personal Information is collected in order to:
      <br />
      Provide, improve and operate our Services and related services. Send you
      updates, notices, notifications, announcements, and additional information
      related to the Services. Be able to provide the Customers with technical
      assistance and support and to provide the Customers with updates with
      respect to the Services.
      <br />
      <br />
      Display or send to you marketing and advertising material and general and
      personalized content and advertisement via the Services, the Website,
      email, telephone, mobile devices and/or when you visit other sites. Use it
      to create cumulative statistical data and other cumulative information
      analysis and/or other conclusive information that is non-personal, in
      which we might make use of in order to operate and improve our Services
      and provide related services.
      <br />
      <br />
      Prevent, delete, mitigate, and investigate fraud, security breaches of
      other potentially prohibited or illegal activities, including any breaches
      of this Privacy Policy or of our Terms of Use.
      <br />
      <br />
      Comply with any applicable title or regulation and/or response or defend
      against legal proceedings versus us or our affiliates.
      <br />
      <br />
      Methods of the collection of information
      <br />
      <br />
      There are two main methods we use:
      <br />
      <br />
      Use of the Services. We collect Non-Personal and Personal Information when
      you access or use the Services. In other words, we are aware of your usage
      of the Services and may gather, collect and record the information
      relating to such usage. We also collect Non-Personal and Personal
      Information derived from information we receive from third party services
      and providers we work with. Third-party derived information includes but
      is not limited to cookies, usage data, Email address, username, website,
      and phone number.
      <br />
      <br />
      Voluntarily Provided. We may collect Personal Information which you
      voluntarily provide when you use the Services. We may also collect
      Non-Personal Information through the processing, analysis and
      anonymization of Personal Information provided by you.
      <br />
      <br />
      Mode and place of processing the Data
      <br />
      <br />
      Methods of processing
      <br />
      <br />
      The Owner takes appropriate security measures to prevent unauthorized
      access, disclosure, modification, or unauthorized destruction of the Data.
      <br />
      <br />
      The Data processing is carried out using computers and/or IT enabled
      tools, following organizational procedures and modes strictly related to
      the purposes indicated. In addition to the Owner, in some cases, the Data
      may be accessible to certain types of persons in charge, involved with the
      operation of this Application (administration, sales, marketing, legal,
      system administration) or external parties (such as third-party technical
      service providers, mail carriers, hosting providers, IT companies,
      communications agencies) appointed, if necessary, as Data Processors by
      the Owner. The updated list of these parties may be requested from the
      Owner at any time.
      <br />
      <br />
      Panomix will not share any personal information it collects with any third
      party unless authorized by you including as part of the use of the
      Services, or under this Privacy Policy. Panomix may also disclose Personal
      Information in the following cases: (a) to satisfy any applicable law,
      regulation, legal process, subpoena or governmental request; (b) to
      enforce this Privacy Policy and/or the Terms of Use, including
      investigation of potential violations thereof; (c) to detect, prevent, or
      otherwise address fraud, security or technical issues; (d) to respond to
      User’s support requests; (e) respond to claims that any content available
      on the Site violates the rights of third-parties; (f) to respond to claims
      that contact information (e.g. name, e-mail address, etc.) of a
      third-party has been posted or transmitted without their consent or as a
      form of harassment; (g) when Panomix is undergoing any change in control,
      including by means of merger, acquisition or purchase or sale of all or
      substantially all of its assets; or (i) to collect, hold and/or manage
      your Personal Information through Panomix’s authorized third party service
      providers, as reasonable for business purposes, which may be located in a
      country that does not have the same data protection laws as your
      jurisdiction; (j) cooperate with third parties for the purpose of
      enhancing the Services. For avoidance of doubt, Panomix may transfer and
      disclose to third parties, or otherwise use, non-personal information at
      its own discretion.
      <br />
      <br />
      Legal basis of processing
      <br />
      <br />
      The Owner may process Personal Data relating to Users if one of the
      following applies:
      <br />
      <br />
      - Users have given their consent for one or more specific purposes. Note:
      Under some legislations the Owner may be allowed to process Personal Data
      until the User objects to such processing (“opt-out”), without having to
      rely on consent or any other of the following legal bases. This, however,
      does not apply, whenever the processing of Personal Data is subject to
      European data protection law;
      <br />
      - provision of Data is necessary for the performance of an agreement with
      the User and/or for any pre-contractual obligations thereof;
      <br />
      - processing is necessary for compliance with a legal obligation to which
      the Owner is subject;
      <br />
      - processing is related to a task that is carried out in the public
      interest or in the exercise of official authority vested in the Owner;
      <br />
      - processing is necessary for the purposes of the legitimate interests
      pursued by the Owner or by a third party.
      <br />
      <br />
      In any case, the Owner will gladly help to clarify the specific legal
      basis that applies to the processing, and in particular whether the
      provision of Personal Data is a statutory or contractual requirement, or a
      requirement necessary to enter into a contract.
      <br />
      <br />
      Place
      <br />
      The Data is processed at the Owner's operating offices and in any other
      places where the parties involved in the processing are located.
      <br />
      <br />
      Depending on the User's location, data transfers may involve transferring
      the User's Data to a country other than their own. To find out more about
      the place of processing of such transferred Data, Users can check the
      section containing details about the processing of Personal Data.
      <br />
      <br />
      Users are also entitled to learn about the legal basis of Data transfers
      to a country outside the European Union or to any international
      organization governed by public international law or set up by two or more
      countries, such as the UN, and about the security measures taken by the
      Owner to safeguard their Data.
      <br />
      <br />
      If any such transfer takes place, Users can find out more by checking the
      relevant sections of this document or inquire with the Owner using the
      information provided in the contact section.
      <br />
      <br />
      Retention
      <br />
      Personal Data shall be processed and stored for as long as required by the
      purpose they have been collected for.
      <br />
      <br />
      Therefore:
      <br />
      -Personal Data collected for purposes related to the performance of a
      contract between the Owner and the User shall be retained until such
      contract has been fully performed.
      <br />
      -Personal Data collected for the purposes of the Owner’s legitimate
      interests shall be retained as long as needed to fulfill such purposes.
      Users may find specific information regarding the legitimate interests
      pursued by the Owner within the relevant sections of this document or by
      contacting the Owner.
      <br />
      <br />
      The Owner may be allowed to retain Personal Data for a longer period
      whenever the User has given consent to such processing, as long as such
      consent is not withdrawn. Furthermore, the Owner may be obliged to retain
      Personal Data for a longer period whenever required to do so for the
      performance of a legal obligation or upon order of an authority.
      <br />
      <br />
      Once the retention period expires, Personal Data shall be deleted.
      Therefore, the right of access, the right to erasure, the right to
      rectification and the right to data portability cannot be enforced after
      expiration of the retention period.
      <br />
      <br />
      The purposes of processing
      <br />
      <br />
      The Data concerning the User is collected to allow the Owner to provide
      its Service, comply with its legal obligations, respond to enforcement
      requests, protect its rights and interests (or those of its Users or third
      parties), detect any malicious or fraudulent activity, as well as the
      following: Analytics and Registration and authentication.
      <br />
      <br />
      For specific information about the Personal Data used for each purpose,
      the User may refer to the section “Detailed information on the processing
      of Personal Data”.
      <br />
      <br />
      Detailed information on the processing of Personal Data
      <br />
      <br />
      Personal Data is collected for the following purposes and using the
      following services:
      <br />
      <br />
      Advertising
      <br />
      <br />
      This type of permission allows User Data to be utilized for advertising
      purposes. Advertisements are displayed in the form of banners and other
      forms on panomix.io, possibly based on User interests. The fact that some
      User Data is utilized for advertising purposes does not mean that all
      Personal Data is. Information about and the conditions of use are
      described below. Some of the services listed below may use Cookies or
      other Identifiers to identify Users or they may use a behavioral
      retargeting technique, i.e. displaying advertisements tailored to the
      User’s interests and behaviors, including those detected outside
      panomix.io. The privacy policies of the relevant services contain more
      information
      <br />
      <br />
      Google Ad Manager
      <br />
      Google Ad Manager is an advertising service provided by Google LLC. This
      service allows the Owner to run advertising campaigns in conjunction with
      external advertising networks that the Owner, unless otherwise specified
      in this document, has no direct relationship with.
      <br />
      The Owner may utilize Google Ads to help users manage ads with business
      profile, where the users will be asked to give access to dedicated
      service.
      <br />
      Place of processing: United States.
      <br />
      <br />
      Analytics
      <br />
      <br />
      The services contained in this section enable the Owner to monitor and
      analyze web traffic and can be used to keep track of User behavior.
      <br />
      Google Analytics (Google LLC)
      <br />
      Google Analytics is a web analysis service provided by Google LLC
      (“Google”). Google utilizes the Data collected to track and examine the
      use of this Application, to prepare reports on its activities and share
      them with other Google services. Google may use the Data collected to
      contextualize and personalize the ads of its own advertising network. To
      track conversions, review traffic quality, and show key metrics of
      performances to help users conduct marketing based on the measured data
      directly, the Owner pulls the data into the database and provide them with
      visualizations. Personal Data processed: Tracker; Usage Data.
      <br />
      Place of processing: United States ––.
      <br />
      <br />
      Registration and authentication
      <br />
      <br />
      By registering or authenticating, Users allow this Application to identify
      them and give them access to dedicated services. Depending on what is
      described below, third parties may provide registration and authentication
      services. In this case, this Application will be able to access some Data,
      stored by these third-party services, for registration or identification
      purposes. Some of the services listed below may also collect Personal Data
      for targeting and profiling purposes; to find out more, please refer to
      the description of each service.
      <br />
      <br />
      Google OAuth (Google LLC)
      <br />
      Google OAuth is a registration and authentication service provided by
      Google LLC and is connected to the Google network. Personal Data
      processed: various types of Data as specified in the privacy policy of the
      service. Place of processing: United States –.
      <br />
      <br />
      Facebook Authentication (Meta Platforms, Inc.)
      <br />
      Facebook Authentication is a registration and authentication service
      provided by Meta Platforms, Inc. and is connected to the Facebook social
      network.
      <br />
      Personal Data processed: Tracker; various types of Data as specified in
      the privacy policy of the service.
      <br />
      Place of processing: United States – .<br />
      <br />
      Sharing information with third parties
      <br />
      <br />
      Third Party Services: We are partnering with a number of selected service
      providers, whose services and solutions complement, facilitate and enhance
      our own. These include hosting, database and server co-location services
      data analytics services (e.g. Google Analytics)
      <br />
      <br />
      Such Third Party Service Providers may receive or otherwise have access to
      your Personal Information, depending on each of their particular roles and
      purposes in facilitating and enhancing the Services, and may only use your
      Personal Information for such purposes. Such disclosure or access is
      strictly subject to the recipient’s or user’s undertaking of
      confidentiality obligations, and the prevention of any independent right
      to use this data except as required to help us provide the Services. We
      are remained responsible and liable for any Personal Information
      processing done by Third Party Service Provider on its behalf, except for
      events outside of its reasonable control.
      <br />
      <br />
      Law enforcement, legal proceedings, and as authorized by law: We may
      disclose or otherwise allow access to Personal Information pursuant to a
      legal requirement or request, such as a subpoena, search warrant or court
      order, or in compliance with applicable laws and regulations. Such
      disclosure or access may occur with or without notice to you, if we have a
      good faith belief that we are legally required to do so, or that
      disclosure is appropriate in connection with efforts to investigate,
      prevent, or take action regarding actual or suspected illegal activity,
      fraud, or other wrongdoing.
      <br />
      <br />
      Protecting Rights and Safety: We may share your Personal Information with
      others, with or without notice to you, if we believe in good faith that
      this will help protect the rights, property or personal safety of our
      Company, any of our Users, or any members of the general public.
      <br />
      <br />
      Our Affiliated Companies: We may share Personal Information internally
      within our family of companies, for the purposes described in this Privacy
      Policy. In addition, should we or any of our affiliates undergo any change
      in control, including by means of merger, acquisition or purchase of
      substantially all of its assets, your Personal Information may be shared
      with the parties involved in such event. If we believe that such change in
      control might materially affect your Personal Information then stored with
      us, we will notify you of this event and the choices you may have via
      e-mail and/or prominent notice on our website or Services.
      <br />
      <br />
      For the removal of doubt, we may share your personal information in
      additional manners, pursuant to your explicit approval, or if we are
      legally obligated to do so. Additionally, we may transfer, share or
      otherwise use non-personal information in our sole discretion and without
      the need for further approval. Non-Personal of third parties(“Customer
      data”) Without derogating from your obligation to ensure that Customer
      Data does not include Personal Data of any third party, in the event such
      Customer Data does include Personal Data, you warrant to Panomix that
      Customer Data has been and will be collected, processed and provided to
      Panomix in accordance with applicable laws including laws on the
      protection of data subjects with regard to the processing of personal data
      and on the free movement of such data (collectively, “Data Protection
      Laws“)
      <br />
      <br />
      If a third party makes a claim against Panomix resulting from your failure
      to comply with this Privacy Policy, you will indemnify and hold Panomix
      harmless with respect to any and all damages awarded by the court to the
      third party provided Panomix do the following: (i) notify you as soon as
      reasonably practicable (or as required by applicable law); (ii) give you
      sole control of the defense and any settlement negotiations; and (iii)
      gives you the information, authority, and assistance you reasonably needs
      to defend against or settle the claim.
      <br />
      <br />
      Place to store personal information
      <br />
      <br />
      Information regarding the Users will be maintained, processed and stored
      by us and our authorized affiliates and service providers in Korea, and as
      necessary, in secured cloud storage, provided by our third party service
      provider. While the data protection laws in the above jurisdictions may be
      different than the laws of your residence or location, please know that
      we, our affiliates and our service providers that store or process your
      Personal Information on the our behalf are each committed to keep it
      protected and secured, pursuant to this Privacy Policy and industry
      standards, regardless of any lesser legal requirements that may apply in
      their jurisdiction. You hereby accept the place of storage and the
      transfer of information as described above.
      <br />
      <br />
      Rights of users
      <br />
      <br />
      Users may exercise certain rights regarding their Data processed by the
      Owner.
      <br />
      <br />
      In particular, Users have the right to do the following:
      <br />
      <br />
      Withdraw their consent at any time: Users have the right to withdraw
      consent where they have previously given their consent to the processing
      of their Personal Data.
      <br />
      <br />
      Object to processing of their Data: Users have the right to object to the
      processing of their Data if the processing is carried out on a legal basis
      other than consent. Further details are provided in the dedicated section
      below.
      <br />
      <br />
      Access their Data: Users have the right to learn if Data is being
      processed by the Owner, obtain disclosure regarding certain aspects of the
      processing and obtain a copy of the Data undergoing processing.
      <br />
      <br />
      Verify and seek rectification: Users have the right to verify the accuracy
      of their Data and ask for it to be updated or corrected.
      <br />
      <br />
      Restrict the processing of their Data: Users have the right, under certain
      circumstances, to restrict the processing of their Data. In this case, the
      Owner will not process their Data for any purpose other than storing it.
      <br />
      <br />
      Have their Personal Data deleted or otherwise removed: Users have the
      right, under certain circumstances, to obtain the erasure of their Data
      from the Owner.
      <br />
      <br />
      Receive their Data and have it transferred to another controller: Users
      have the right to receive their Data in a structured, commonly used and
      machine readable format and, if technically feasible, to have it
      transmitted to another controller without any hindrance. This provision is
      applicable provided that the Data is processed by automated means and that
      the processing is based on the User’s consent, on a contract which the
      User is part of or on pre-contractual obligations thereof.
      <br />
      <br />
      Lodge a complaint: Users have the right to bring a claim before their
      competent data protection authority. Legal basis of using personal
      information
      <br />
      <br />
      We will only collect, use and share your Personal Information where we are
      satisfied that we have an appropriate legal basis to do this. Such legal
      basis include :<br />
      <br />
      our use of your Personal Information is necessary to perform a contract or
      take steps to enter into a contract with you, for example for the purpose
      of providing you with our services, creating your user account,
      registering to an Panomix event, or communicating with you in relation to
      a query or complaint
      <br />
      <br />
      you have provided your consent to us using the Personal Information (e.g.,
      where you provide us with consents or opt-in to receive marketing
      materials and/or optional additional services or functionalities);
      <br />
      <br />
      our use of your Personal Information is necessary to comply with a
      relevant legal of regulatory obligation that we have (e.g., the disclosure
      of information to law enforcement or tax authorities.)
      <br />
      <br />
      If you would like to find out more about the legal basis for which we
      process personal information please contact us at info@panomix.io.
      <br />
      <br />
      Using Cookies and other tracking technologies
      <br />
      <br />
      A cookie is a small text file that is stored in a web browser directory by
      a website or an advertisement related action. Our Website uses Persistent
      cookies to (i) identify users, (ii) remember users' custom preferences,
      and (iii) help users complete tasks without having to re enter information
      when browsing from one page to another or when visiting the site later.
      Persistent cookie remains on the user's computer/device for a pre-defined
      period of time.
      <br />
      <br />
      Please note that your browser enables you to disable all cookies, however,
      this may interfere with your use of the Website or other internet
      websites.
      <br />
      <br />
      We also use Google Analytics which is one of the most widespread and
      trusted analytics solution on the web for helping us to understand how you
      use the site and ways that we can improve your experience. We also use
      Facebook’s functionality of re-marketing through their tracking cookies
      and pixel-based retargeting services. This means that if you provided your
      consent to Facebook (the “Social Ad Platforms”) to be provided with
      personalized commercial offers, you may be served with ads (including
      advertisements of third parties) based on your access to the Services or
      Website, outside of the Services and Website and across the internet. In
      such event the Social Ad Platforms, will place cookies on your web browser
      and use such cookies to serve you ads based on past visits to our Services
      and Website.
      <br />
      <br />
      Please visit the Social Ad Platforms Privacy policy to find out how they
      use such information:
      <br />
      <br />
      <Link>
        Google Ads: https://support.google.com/adspolicy/answer/6008942
      </Link>
      <br />
      <Link>Google Analytics: https://policies.google.com/privacy</Link>
      <br />
      <Link>Facebook: https://www.facebook.com/about/privacy/</Link>
      <br />
      <br />
      If you wish to opt-out of such re-targeting and tracking functionality of
      the Social Ad Platforms, you may do so at the following links:
      <br />
      <br />
      <Link>Google Ads: https://adssettings.google.co.il/authenticated</Link>
      <br />
      <Link>
        Google Analytics: https://support.google.com/analytics/answer/181881
      </Link>
      <br />
      <Link>Facebook: https://www.facebook.com/settings/?tab=ads</Link>
      <br />
      <br />
      PLEASE NOTE – such tracking and targeting by Social Ad Platforms, is
      provided pursuant to your engagement with such Social Ad Platforms and the
      actual nature and scope of Personal Information collection and processing
      performed by such Social Ad Platforms is not transparent to us. If you
      would like to learn more or make further inquiries with respect to such
      nature or scope please refer to each of such Social Ad Platforms directly.
      <br />
      <br />
      Direct marketing
      <br />
      <br />
      You hereby agree that we may use your contact details provided during the
      filling of a contact form or otherwise made available to us, for the
      purpose of informing you regarding our products and services which may
      interest you, and to send to you other marketing material. You may
      withdraw your consent via sending us written notice by email to the
      following info@panomix.io or by pressing the “Unsubscribe” button in the
      mail.
      <br />
      <br />
      Please note that the company may also contact you with important
      information regarding our Website. For example, we may notify you (through
      any of the means available to us) of changes or updates to our Website,
      payment issues, service maintenance, etc. You will not be able to opt-out
      of receive such service messages.
      <br />
      <br />
      Security
      <br />
      <br />
      We have implemented appropriate technical, organizational and security
      measures designed to reduce the risk of accidental destruction or loss, or
      the unauthorized disclosure or access to such information appropriate to
      the nature of the information concerned. However, please note that we
      cannot guarantee that the information will not be exposed as a result of
      unauthorized penetration to our servers. Nevertheless, we make
      commercially reasonable efforts to make the collection and security of
      such information consistent with this Privacy Policy and all applicable
      laws and regulations. As the security of information depends in part on
      the security of the computer, device or network you use to communicate
      with us and the security you use to protect your user IDs and passwords,
      please make sure to take appropriate measures to protect this information.
      <br />
      <br />
      Updating, Obtaining a copy of, or deleting your personal information If
      the law applicable to you grants you such rights, you may ask to access,
      correct, or delete your Personal Information that is stored in our
      systems. You may also ask for our confirmation as to whether or not we
      process your Personal Information.
      <br />
      <br />
      Subject to the limitations in law, you may request that we update,
      correct, or delete inaccurate or outdated information. You may also
      request that we suspend the use of any Personal Information whose accuracy
      you contest while we verify the status of that data.
      <br />
      <br />
      Subject the limitations in law, you may also be entitled to obtain the
      Personal Information you directly provided us (excluding data we obtained
      from other sources) in a structured, commonly used, and machine-readable
      format and may have the right to transmit such data to another party.
      <br />
      <br />
      If you wish to exercise any of these rights, contact us at:
      info@panomix.io When handling these requests, we may ask for additional
      information to confirm your identity and your request. Please note, upon
      request to delete your Personal Information, we may retain such data in
      whole or in part to comply with any applicable rule or regulation and/or
      response or defend against legal proceedings versus us or our affiliates
      <br />
      <br />
      To find out whether these rights apply to you and on any other privacy
      related matter, you can contact your local data protection authority if
      you have concerns regarding your rights under local law.
      <br />
      <br />
      Minor
      <br />
      <br />
      We do not offer our products or services for use by children. If you are
      under 18, you may not use the Website and/or our Platforms, or provide any
      information to the Website and/or our Platforms without involvement of a
      parent or a guardian. We do not knowingly collect information from, and/or
      about children.
      <br />
      <br />
      Changes to the privacy policy
      <br />
      <br />
      The terms of this Privacy Policy will govern the use of the Services and
      any information collected therein. Panomix reserves the right to change
      this policy at any time, so please revisit this page frequently. We will
      provide notice of substantial changes of this policy on the homepage of
      the Site and/or we will send you an email regarding such changes to the
      e-mail address that you may have provided us with. Such substantial
      changes will take effect seven (7) days after such notice was provided on
      our Site or sent by email. Otherwise, all other changes to this Privacy
      Policy are effective as of the stated “Last Revised” date and your
      continued use of the Site after the Last Revised date will constitute
      acceptance of, and agreement to be bound by, those changes.
      <br />
      <br />
      General information
      <br />
      <br />
      This Privacy Policy, its interpretation, and any claims and disputes
      related hereto, shall be governed by the laws of the country where we are
      located without respect to its criminal law principles. Any and all such
      claims and disputes shall be brought in, and you hereby consent to them
      being litigated in and decided exclusively by a court of competent
      jurisdiction located in the city where our headquarter is located in.
      <br />
      <br />
      This Privacy Policy was written in English, and may be translated into
      other languages for your convenience. If a translated (non-English)
      version of this Privacy Policy conflicts in any way with the English
      version, the provisions of the English version shall prevail.
      <br />
      <br />
      Panomix and the GDPR
      <br />
      <br />
      Now, Panomix is preparing for the General Data Protection Regulation!
      <br />
      <br />
      We believe that the GDPR is the most important point to protect users’
      privacy.
      <br />
      <br />
      As such, if you are looking for details regarding the GDPR compliance of
      Facebook, Twitter, Google, Instagram, and LinkedIn, we invite you to visit
      their respective resource centers:
      <br />
      <br />
      <Link>Facebook: https://www.facebook.com/business/gdpr</Link>
      <br />
      <Link>Twitter: https://gdpr.twitter.com/en.html</Link>
      <br />
      <Link>Google: https://cloud.google.com/security/gdpr/</Link>
      <br />
      <Link>Instagram: https://help.instagram.com/2000935033561463</Link>
      <br />
      <br />
      You also might want to review the terms and conditions and data policy of
      these 3rd parties as they will help you understand how they are handling
      personal data.
      <br />
      <br />
    </Wrapper>
  );
}
const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0px 30px;
  @media (min-width: 600px) {
    padding: 0px 120px;
  }

  font-size: 14px;
  font-family: Poppins;
  color: gray;

  word-break: break-word;
  word-wrap: break-word;
  text-align: left;
`;

const Headline = styled.h3`
  color: gray;
  font-family: Poppins;
  text-align: center;
  font-size: 30px;
  letter-spacing: -2px;
  @media (min-width: 600px) {
    font-size: 40px;
  }
  margin-bottom: 30px;
`;

const Link = styled.span`
  font-size: 14px;
  font-family: Poppins;
  color: gray;

  word-break: break-all;
`;
