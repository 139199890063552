import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { COPYRIGHT, TERMS_AND_POLYCY } from './FOOTER_DATA';
import styled from 'styled-components';
import PanomixLogo from '../../assets/logos/Panomix/Panomix_logo.svg';

export default function Footer() {
  const isUnder1440 = useMediaQuery({ minWidth: 980, maxWidth: 1440 });
  const isPadSize = useMediaQuery({ maxWidth: 979 });
  const isBelow425 = useMediaQuery({ maxWidth: 425 });
  const navigate = useNavigate();

  const [isAtEndOfPage, setIsAtEndOfPage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight
      ) {
        setIsAtEndOfPage(true);
      } else {
        setIsAtEndOfPage(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {isAtEndOfPage && (
        <Wrapper isPadSize={isPadSize}>
          <AlignBox isPadSize={isPadSize} isUnder1440={isUnder1440}>
            <Left isPadSize={isPadSize}>
              <Logo
                isPadSize={isPadSize}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.location.replace('/');
                }}
                src={PanomixLogo}
              />
              <LinkArea>
                {TERMS_AND_POLYCY.slice(0, isBelow425 ? 2 : 3).map(
                  ({ name, link }, idx) => (
                    <TermWrapper key={idx}>
                      <LinkButton
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          navigate(link);
                        }}
                      >
                        {name}
                      </LinkButton>

                      {!isBelow425
                        ? idx < 2 && <span style={{ color: 'gray' }}>|</span>
                        : idx === 0 && <span style={{ color: 'gray' }}>|</span>}
                    </TermWrapper>
                  )
                )}
              </LinkArea>

              {isBelow425 && (
                <LinkButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate(TERMS_AND_POLYCY[2].link);
                  }}
                >
                  {TERMS_AND_POLYCY[2].name}
                </LinkButton>
              )}
            </Left>

            <Copyright isPadSize={isPadSize}>{COPYRIGHT}</Copyright>
          </AlignBox>
        </Wrapper>
      )}
    </>
  );
}
const Wrapper = styled.footer`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: ${({ isPadSize }) => (isPadSize ? 'auto' : '60px')};

  color: gray;
  background-color: #282c34;

  z-index: 5;
`;

const AlignBox = styled.div`
  display: flex;
  flex-direction: ${({ isPadSize }) => (isPadSize ? 'column' : 'row')};
  align-items: center;
  justify-content: ${({ isPadSize }) =>
    isPadSize ? 'center' : 'space-between'};

  width: 100%;
  max-width: 1300px;
  padding: ${({ isUnder1440, isPadSize }) =>
    isUnder1440 ? (isPadSize ? '0px' : '0px 120px') : '0px'};
`;

const Left = styled.div`
  display: flex;
  flex-direction: ${({ isPadSize }) => (isPadSize ? 'column' : 'row')};
  align-items: center;
  margin-top: ${({ isPadSize }) => (isPadSize ? '15px' : '0px')};
`;

const Logo = styled.img`
  margin-right: ${({ isPadSize }) => (isPadSize ? '0px' : '40px')};
  margin-bottom: ${({ isPadSize }) => (isPadSize ? '10px' : '0px')};
  width: 100px;
  cursor: pointer;
`;

const LinkArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 12px;
`;

const TermWrapper = styled.div``;

const LinkButton = styled.button`
  color: gray;
  font-family: Arial;
  font-size: 12px;
  font-weight: 300;

  cursor: pointer;
`;

const Copyright = styled.span`
  padding-bottom: ${({ isPadSize }) => (isPadSize ? '10px' : ' 0px')};

  color: gray;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
`;
