import React, { useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../../contextApi/LanguageContext';

export default function MenuButton({ data }) {
  const { language } = useContext(LanguageContext);

  const moveToOtherPage = (url) => {
    window.location.href = url;
  };

  const moveToOtherWeb = (url) => {
    window.open(url, '_blank');
  };

  return (
    <MenuName
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        if (data.type === 'moveToOtherPage') {
          moveToOtherPage(data.url);
        } else if (data.type === 'moveToOtherWeb') {
          moveToOtherWeb(data.url);
        }
      }}
    >
      {data.title[language]}
    </MenuName>
  );
}

const MenuName = styled.button`
  padding: 20px 0 26px 0;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 300;
  line-height: 10px;
  cursor: pointer;
  color: white;
  transition: all ease 1s;
`;
