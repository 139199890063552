import React, { useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../../contextApi/LanguageContext';
import Button from '../../../components/Button/Button';

export const OverviewContent = () => {
  const { language } = useContext(LanguageContext);
  const DATA = {
    sectionTitle: {
      en: 'GenAI products for data driven businesses',
      ko: '데이터 드리븐 비지니스를 위한 GenAI 제품들',
    },
    sectionBody: {
      en: (
        <>
          <SectionBody>
            GenAI service harnesses a diverse range of solutions and tools to
            craft tailored genAI solutions, including customer service chatbots
            and content management systems. By synergizing various tools and
            solutions, we adeptly fulfill clients' requirements, leveraging
            advanced GenAI models like GPT and Llama to achieve exceptional
            outcomes.
          </SectionBody>
        </>
      ),
      ko: (
        <>
          <SectionBody>
            GenAI 서비스는 고객 서비스 챗봇, 콘텐츠 관리 시스템을 포함한 맞춤형
            생성형AI 솔루션을 구축하기 위해 다양한 솔루션과 도구를 활용합니다.
            고객의 상황에 맞춰 GPT 및 Llama와 같은 고급 GenAI 모델을 활용하여
            탁월한 결과를 이끌어냅니다.
          </SectionBody>
        </>
      ),
    },
  };

  return (
    <>
      <SectionTitle>{DATA.sectionTitle[language]}</SectionTitle>
      <SectionBody>{DATA.sectionBody[language]}</SectionBody>
      <div style={{ marginTop: '40px' }}>
        <Button type='requestDemo' />
      </div>
      <Divider />
    </>
  );
};

const SectionTitle = styled.h2`
  font-size: 44px;
  line-height: 50px;
  font-family: 'Poppins';
  font-weight: 300;
  width: auto;
  max-width: 620px;
  color: black;
  margin-bottom: 40px;
  align-text: left;
`;

const SectionBody = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-family: Arial;
  width: auto;
  color: black;
  font-weight: 200;
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 100%;
  margin-right: 40px;
  margin-top: 80px;
  margin-bottom: 48px;
`;
