import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import NavDesktop from '../../components/Nav/Desktop/NavDesktop';
import NavMobile from '../../components/Nav/Mobile/NavMobile';

import Footer from '../../components/Footer/Footer';
import Button from '../../components/Button/Button';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { PLAYBOOK_DATA } from './PLAYBOOK_DATA';
import {
  Wrapper,
  MWrapper,
  MTitleArea,
  MTitle,
  MDescriptionArea,
  MTitleDescription,
  ButtonArea,
  MGrid,
  MImage,
} from '../PageStyles';

import MobileSideMenu from '../../components/SideMenu/MobileSideMenu';

import { ReactComponent as PlaybookLogo } from '../../assets/logos/Panomix/Playbook.svg';
import PlaybookImage from '../../assets/images/PlaybookImage.svg';

function PlaybookMobile() {
  const { language, setLanguage } = useContext(LanguageContext);

  const isDesktop = useMediaQuery({ minWidth: 981 });

  return (
    <>
      <MWrapper>
        <MTitle>{PLAYBOOK_DATA.title[language]}</MTitle>
        <PlaybookLogo style={{ height: '22px' }} />

        <MTitleDescription>
          {PLAYBOOK_DATA.titleDescription[language]}
        </MTitleDescription>

        <Button type='scheduleDemo' />
        <MImage src={PlaybookImage} />
      </MWrapper>

      <MobileSideMenu sections={PLAYBOOK_DATA.sections} />
      <Footer />
    </>
  );
}

export default PlaybookMobile;
