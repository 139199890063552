export const MAIN_MENU_OPTIONS = [
  {
    type: 'moveToOtherPage',
    name: 'About',
    url: '/about',
  },
  {
    type: 'moveToOtherPage',
    name: 'Services',
    url: '/services',
  },
  {
    type: 'moveToOtherWeb',
    name: 'Playbook',
    url: 'https://playbook.panomix.io/',
  },
  {
    type: 'moveToOtherPage',
    name: 'Blog',
    url: '/blog',
  },
  {
    type: 'moveToOtherWeb',
    name: 'Careers',
    url: 'https://www.notion.so/panomix/Careers-68e5d519e9c547ad9cf5ae739b565a67',
  },
];

export const SIMPLE_MENU_OPTIONS = [
  // {
  //   name: 'playbook',
  //   type: 'moveToOtherPage',
  //   title: {
  //     en: 'Playbook',
  //     ko: '플레이북',
  //   },
  //   url: '/playbook',
  // },
  // {
  //   name: 'genAI',
  //   type: 'moveToOtherPage',
  //   title: {
  //     en: 'GenAI',
  //     ko: '생성형AI',
  //   },
  //   url: '/ai',
  // },
  // {
  //   name: 'blog',
  //   type: 'moveToOtherPage',
  //   title: {
  //     en: 'Blog',
  //     ko: '블로그',
  //   },
  //   url: '/blog',
  // },
  {
    name: 'company',
    type: 'moveToOtherPage',
    title: {
      en: 'Company',
      ko: '회사 소개',
    },
    url: '/about',
  },
];

export const MENU_OPTIONS = [
  {
    name: 'products',
    title: {
      en: 'Products',
      ko: '제품',
    },
    description: {
      en: 'MarTech & AI applications with proven client use cases.',
      ko: '클라이언트 성공 사례를 갖춘 MarTech 및 AI 솔루션을 만나보세요.',
    },
    options: [
      {
        name: 'playbook',
        title: {
          en: 'Playbook',
          ko: '플레이북',
        },
        description: {
          en: 'Scaled Media Mix Modeling Analytics',
          ko: '강화된 미디어 믹스 모델링 분석',
        },
        catchphrase: {
          en: 'Build 10,000+ models, not one.',
          ko: '하나가 아닌 10,000개 이상의 모델을 만드세요.',
        },
        icon: '',
        url: 'https://panomix.github.io/panomix/playbook/overview/',
        type: 'moveToOtherWeb',
      },
      {
        name: 'swen',
        title: {
          en: 'Swen AI',
          ko: '스웬 AI',
        },
        description: {
          en: 'AI News Generation Platform',
          ko: 'AI 기반 뉴스 생성 플랫폼',
        },
        catchphrase: {
          en: 'Research, write, and publish articles with AI.',
          ko: 'AI를 활용하여 기사 소재를 찾고 작성하며 게시하세요.',
        },
        icon: '',
        url: 'https://panomix.github.io/panomix/swen/overview/',
        type: 'moveToOtherWeb',
      },
      {
        name: 'socialbot',
        title: {
          en: 'SocialBot AI',
          ko: '소셜봇 AI',
        },
        description: {
          en: 'AI Social Channel Manager',
          ko: 'AI 소셜 네트워크 매니저',
        },
        catchphrase: {
          en: 'Enhance brand experience with AI-powered social marketing.',
          ko: 'AI 기반 소셜 마케팅을 활용하여 브랜드 경험을 개선하세요.',
        },
        icon: '',
        url: 'https://panomix.github.io/panomix/socialbot/overview/',
        type: 'moveToOtherWeb',
      },
    ],
  },
  {
    name: 'services',
    title: {
      en: 'Services',
      ko: '서비스',
    },
    description: {
      en: "To solve our clients' problems, we develop tailored applications and collaborate with industry leaders.",
      ko: '고객의 문제를 해결하기 위해 맞춤형 애플리케이션을 개발하고 업계 리더와 협력합니다.',
    },
    options: [
      {
        name: 'customAI',
        title: {
          en: 'Custom AI application',
          ko: '커스텀 AI 애플리케이션',
        },
        description: {
          en: 'Data, Embedding, VectorDB, Application, OCR, and more.',
          ko: 'Data, Embedding, VectorDB, Application, OCR, and more.',
        },
        catchphrase: {
          en: 'Build an AI Application to stand out from your industry.',
          ko: '업계에서의 입지를 높이는 AI 애플리케이션을 제작하세요.',
        },
        icon: '',
        url: 'https://panomix.github.io/panomix/custom/overview/',
        type: 'moveToOtherWeb',
      },
      {
        name: 'tiktok',
        title: {
          en: 'TikTok Marketing Partner',
          ko: '틱톡 공식 마케팅 파트너',
        },
        description: {
          en: 'Data Connection Developers',
          ko: '데이터 커넥션 디벨로퍼',
        },
        catchphrase: {
          en: 'Talk to your TikTok representative to start collecting data.',
          ko: '데이터 수집을 시작하려면 TikTok 담당자에게 문의하세요.',
        },
        icon: '',
        url: 'https://panomix.github.io/panomix/tiktok/overview/',
        type: 'moveToOtherWeb',
      },
      {
        name: 'botpress',
        title: {
          en: 'Botpress AI Chatbot',
          ko: '봇프레스 AI 챗봇 서비스',
        },
        description: {
          en: 'Botpress Certified Partner',
          ko: '봇프레스 공식 파트너',
        },
        catchphrase: {
          en: "Build an AI chatbot using the world's leading platform.",
          ko: '업계 최고의 플랫폼을 이용해 AI 챗봇을 구축하세요.',
        },
        icon: '',
        url: 'https://panomix.github.io/panomix/botpress/overview/',
        type: 'moveToOtherWeb',
      },
    ],
  },
];
