import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../components/Button/Button';
import styled from 'styled-components';

const PricingTier = ({ data }) => {
  const { title, description, desc_list, button, benefits } = data;

  return (
    <CardWrapper>
      <TitleArea>
        <TierTitle>{title}</TierTitle>
        <TierDescription>{description}</TierDescription>
      </TitleArea>
      <DescWrapper>
        {desc_list.map((benefit, idx) => (
          <DescList key={idx}>
            <FontAwesomeIcon icon={faCheck} />
            <DescItem>{benefit}</DescItem>
          </DescList>
        ))}
      </DescWrapper>
      <ButtonArea>
        <Button type={button.type} isInNav={false} />
      </ButtonArea>
      <BenefitArea>
        <BenefitTitle>{benefits.title}</BenefitTitle>
        <BenefitSubTitle>{benefits.subTitle}</BenefitSubTitle>
        {benefits.list.map((benefit, idx) => (
          <List key={idx}>
            <BenefitItem>{benefit}</BenefitItem>
          </List>
        ))}
      </BenefitArea>
    </CardWrapper>
  );
};

export default PricingTier;

const CardWrapper = styled.section`
  display:flex,
  flex-direction: column,
  padding: 33px;
  border-radius: 8px;

  max-width: 360px;
  min-width: 220px;

  background-color: #fff;
  color: black;
  border: 1px solid black;
`;

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  justify-self: flex-start;
  margin: 30px;
`;

const TierTitle = styled.h4`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -3%;
  margin-bottom: 12px;
`;

const TierDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.neutral.spaceGray3};
`;

const ButtonArea = styled.div`
  display: flex;
  align-item: center;
  justify-content: center;
  margin: 30px;
`;

const BenefitArea = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 30px;
`;

const BenefitTitle = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 2px;
`;
const BenefitSubTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  margin-bottom: 18px;
  color: ${({ theme }) => theme.color.primary.blue};
`;

const List = styled.li`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0px 0px 8px 0px;
`;

const BenefitItem = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: ${({ theme }) => theme.color.neutral.spaceGray3};
`;

const DescWrapper = styled.div`
  margin-top: 10px;
  margin-left: 30px;
`;

const DescList = styled.li`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0px 0px 8px 0px;
`;

const DescItem = styled.span`
  margin: 0px 0px 0px 17px;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: ${({ theme }) => theme.color.neutral.blue};
`;
