import styled from 'styled-components';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { LanguageContext } from '../../contextApi/LanguageContext';
import theme from '../../styles/theme';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const Content = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

function MobileSideMenu(props) {
  const { sections } = props;

  return (
    <Wrapper>
      <Content>
        {sections.map((section) => (
          <Section key={section.key} id={section.key}>
            {section.content.mobile}
          </Section>
        ))}
      </Content>
    </Wrapper>
  );
}

export default MobileSideMenu;
