import styled from 'styled-components';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { LanguageContext } from '../../contextApi/LanguageContext';
import theme from '../../styles/theme';

const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Sidebar = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  width: 25%;
  top: 88px;
  height: calc(100vh - 68px);
  max-height: calc(100vh - 108px);
  display: flex;
  flex-direction: column;
  padding: 40px 0px 0px 40px;
`;

const MenuBox = styled.div`
  padding: 10px;
  cursor: pointer;
  border-left: 4px solid
    ${(props) =>
      props.active ? theme.color.primary.blue : theme.color.neutral.spaceGray4};
  color: ${(props) =>
    props.active ? 'black' : theme.color.neutral.spaceGray4};
  font-family: 'Arial';
  font-size: 16px;
  line-height: 24px;
`;

const Content = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 68px);
  max-height: calc(100vh - 68px);
  padding: 40px;
  box-sizing: border-box;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

function SideMenu(props) {
  const { language } = useContext(LanguageContext);
  const { sections } = props;
  const [activeSection, setActiveSection] = useState(0);

  const sectionRefs = useRef({});

  useEffect(() => {
    sections.forEach((section) => {
      sectionRefs.current[section.key] = React.createRef();
    });
  }, [sections]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const windowTop = window.scrollY;
      const windowBottom = windowTop + windowHeight;

      let activeIndex = activeSection;

      for (let i = 0; i < sections.length; i++) {
        const sectionRect =
          sectionRefs.current[
            sections[i].key
          ]?.current?.getBoundingClientRect();
        const sectionTop = sectionRect?.top + windowTop - 100; // Adjust as needed
        const sectionBottom = sectionRect?.bottom + windowTop - 100; // Adjust as needed

        if (sectionTop <= windowBottom && sectionBottom >= windowTop) {
          activeIndex = i;
          break;
        }
      }

      setActiveSection(activeIndex);
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [sections, activeSection]);

  const handleMenuClick = (index) => {
    setActiveSection(index);
    document
      .querySelector(`#${sections[index].key}`)
      .scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Wrapper>
      <Sidebar>
        {sections.map((section, index) => (
          <MenuBox
            key={section.key}
            active={activeSection === index}
            onClick={() => handleMenuClick(index)}
          >
            {section.title[language]}
          </MenuBox>
        ))}
      </Sidebar>
      <Content>
        {sections.map((section) => (
          <Section
            key={section.key}
            id={section.key}
            ref={sectionRefs.current[section.key]}
          >
            {section.content.desktop}
          </Section>
        ))}
      </Content>
    </Wrapper>
  );
}

export default SideMenu;
