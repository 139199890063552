import React from 'react';
import styled from 'styled-components';

export default function EP02() {
  return (
    <>
      <TitleArea>Paradox of Ad Platforms</TitleArea>
      <Author>Author</Author>
      <AuthorName>Sean Han, CEO</AuthorName>
      <Divider />
      <Body>
        <SubTitle>Introduction</SubTitle>
        <BodyText>
          <div>
            In today's connected world, ad platforms like Facebook have become
            the most significant advertising channels for brands to effectively
            reach their potential customers. The pixel-based ad platform,
            Facebook, offers various targeting options that utilize user data
            collected from various pixels and SDKs implemented in advertisers'
            domains, along with Facebook in-app data. There is no doubt that
            Facebook is the most valuable medium out there. However, when it
            comes to reporting, marketers have been questioning its true value.
          </div>
        </BodyText>
        <SubTitle>Closer Look</SubTitle>
        <BodyText>
          <div>
            Let's examine the graph below. The graph displays both the actual
            daily revenue and the daily attributed revenue reported by Facebook
            Ads in a line graph. It is evident that the line representing
            Facebook-reported revenue closely aligns with the peaks and valleys
            of the actual revenue. When marketers look at this, it is natural to
            be suspicious and ask whether Facebook spending was actually
            increasing or decreasing on a daily basis.
          </div>
          <div alignitems='center'>
            <ItemImage
              src={require('./Figures/Facebook_comparison.png')}
              alt='FacebookComparison'
            />
          </div>
          <div>
            When we examine the daily ad spends, it becomes apparent that the
            trend of Facebook ad spends does not align as closely with the
            actual revenue compared to the Facebook-reported revenue. In fact,
            we can observe that many of the peaks in actual revenue are not well
            explained by the corresponding Facebook spends.
          </div>
          <div alignitems='center'>
            <ItemImage
              src={require('./Figures/spend_trend.png')}
              alt='SpendTrend'
            />
          </div>
        </BodyText>
        <SubTitle>
          How can the reporting closely align with the actual revenue?
        </SubTitle>
        <BodyText>
          <div>
            In order to closely follow the trend in such a manner, it would
            require the daily percentage of purchasers who saw Facebook ads to
            consistently represent a certain proportion of the total purchasers.
            Considering that more than half of the population in Korea uses Meta
            platforms, it is relatively easy to target individuals who have
            shown interest using its precise targeting technology. Advertisers
            undoubtedly benefit from these capabilities. However, there is an
            important factor that remains unknown, as illustrated in the figure
            below.
          </div>
          <div alignitems='center'>
            <ItemImage
              src={require('./Figures/EP03Figure.png')}
              alt='Attribution'
            />
          </div>
          <div>
            With the 100% attribution reporting and the ability to target more
            than half of the population, it becomes quite self-explanatory to
            follow the trend. When advertisers incorporate non-Facebook channels
            into their marketing mix, the revenue reported by Facebook may
            include individuals who already had some intention to make a
            purchase, or even individuals with a 100% intention to buy,
            regardless of seeing ads on Facebook platforms.
          </div>
          <br></br>
          <div>
            For the above case, the advertiser utilizes various marketing
            channels, including social, paid search, SMS, messenger, affiliates,
            and other marketing platforms. When there are multiple channels in
            the media mix, a sale cannot be attributed to a single source alone.
            In fact, it is a combination of multiple touchpoints. However,
            Facebook's reporting system is of a basic type, reporting 100% of
            the sale, rather than the "incremental" contribution. This
            limitation applies not only to Facebook but also to other ad
            platforms with similar reporting systems.
          </div>
        </BodyText>

        <SubTitle>Exporing reporting models</SubTitle>
        <BodyText>
          <div>
            Let's examine three different reporting models. The graph below
            displays four lines representing different metrics: actual revenue,
            Facebook's last-click model based on GA source, Facebook reported
            revenue, and Playbook ML modeled revenue of Facebook Ads.
            Interestingly, Playbook's ML model, which incorporates machine
            learning algorithms including those used in Robyn (Meta's
            Open-Sourced MMM package), tends to follow Facebook's spending
            rather than the actual revenue.
          </div>
          <div alignitems='center'>
            <ItemImage
              src={require('./Figures/channel_comparisons.png')}
              alt='ChannelComparisons'
            />
          </div>
          <div>
            In total, the last-click model attributed approximately 15% of the
            total revenue to Facebook Ads, whereas the Facebook reporting
            attributed over 66% of the total revenue to Facebook Ads. On the
            other hand, the Playbook ML model attributed around 35% of the total
            revenue to Facebook Ads in this specific case.
          </div>
        </BodyText>
        <SubTitle>Conclusion</SubTitle>
        <BodyText>
          <div>
            At Panomix, the belief is that ad measurement should prioritize
            capturing the incrementals generated by all marketing sources,
            considering that these incrementals can vary naturally for each
            purchase. As a result, utilizing machine-learning algorithms is
            considered the most suitable method for measurement, moving beyond
            simplistic attribution models.
          </div>
          <br></br>
          <div>
            This approach acknowledges the intricacy of attributing the impact
            of multiple marketing channels and emphasizes the importance of
            employing advanced analytical techniques to comprehensively assess
            the true incremental value of each source in driving conversions.
            Machine-learning algorithms offer a more sophisticated and nuanced
            understanding of the contributions made by different marketing
            efforts, enabling precise measurement and optimization of
            advertising strategies. By leveraging these techniques, advertisers
            can gain deeper insights into the effectiveness of their campaigns
            and make data-driven decisions to maximize their return on
            investment.
          </div>
        </BodyText>
      </Body>
    </>
  );
}

const TitleArea = styled.div`
  font-family: 'Poppins';
  font-size: 72px;
  line-height: 84px;
  width: 600px;

  @media (max-width: 980px) {
    font-size: 48px;
    line-height: 54px;
    width: 360px;
  }
`;

const Author = styled.div`
  font-family: 'Arial';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-top: 80px;
`;

const AuthorName = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 24px;
`;
const Divider = styled.div`
  height: 1px;
  background-color: black;
  margin-top: 14px;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1440px) {
    margin: 80px 280px 80px 280px;
  }
  @media (max-width: 1439px) {
    margin: 80px 180px 80px 180px;
  }
  @media (max-width: 980px) {
    margin: 20px 10px 140px 10px;
  } ;
`;

const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  margin-top: 50px;
  margin-bottom: 10px;

  @media (max-width: 980px) {
    margin-top: 30px;
  }
`;

const BodyText = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 32px;
  margin-top: 10px;
`;

const ItemImage = styled.img`
  display: block;
  margin: 80px auto; /* Centers the image horizontally */
  max-width: 100%;
  height: auto;
  min-width: 600px;

  @media (max-width: 980px) {
    min-width: 300px;
    margin: 40px auto;
  }
`;
