import React, { useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import NavDesktop from '../../components/Nav/Desktop/NavDesktop';
import NavMobile from '../../components/Nav/Mobile/NavMobile';

import AboutUsContent from './AboutUsContent';
import AboutUsContentMobile from './AboutUsContentMobile';

import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { Helmet } from 'react-helmet';

function AboutUs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  const { language, setLanguage } = useContext(LanguageContext);
  const isPC = useMediaQuery({
    minWidth: 980,
  });

  const isMobile = useMediaQuery({
    maxWidth: 979,
  });

  return (
    <>
      <Helmet>
        <title>Panoramas in Mix</title>
        <meta
          name='description'
          content='Serving clients with AI, ML & Marketing solutions since 2017.'
        />
      </Helmet>
      {isPC ? (
        <NavDesktop language={language} setLanguage={setLanguage} />
      ) : (
        <NavMobile language={language} setLanguage={setLanguage} />
      )}
      <Wrapper>
        {isPC && <AboutUsContent />}
        {isMobile && <AboutUsContentMobile />}
      </Wrapper>
      <Footer />
    </>
  );
}

export default AboutUs;

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;

  margin: 0px 0px 80px 0px;
  padding: 0px 120px;
  transition: all ease 4s;
  ${({ theme }) => theme.animation.fadeInBottom}
  }
`;
