import React from 'react';
import styled from 'styled-components';

export default function CookiePolicy() {
  return (
    <Wrapper>
      <Headline>Panomix Cookie Policy</Headline>
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Definitions and key terms
      </span>
      <br />
      To help explain things as clearly as possible in this Cookie Policy, every
      time any of these terms are referenced, are strictly defined as:
      <br />
      <br />
      Cookie: small amount of data generated by a website and saved by your web
      browser. It is used to identify your browser, provide analytics, remember
      information about you such as your language preference or login
      information.
      <br />
      <br />
      Company: when this policy mentions “Company,” “we,” “us,” or “our,” it
      refers to Panomix/Playbook, that is responsible for your information under
      this Cookie Policy.
      <br />
      <br />
      Device: any internet connected device such as a phone, tablet, computer or
      any other device that can be used to visit Panomix/Playbook and use the
      services.
      <br />
      <br />
      Personal Data: any information that directly, indirectly, or in connection
      with other information — including a personal identification number —
      allows for the identification or identifiability of a natural person.
      <br />
      <br />
      Service: refers to the service provided by Panomix/Playbook as described
      in the relative terms (if available) and on this platform. Third-party
      service: refers to advertisers, contest sponsors, promotional and
      marketing partners, and others who provide our content or whose products
      or services we think may interest you.
      <br />
      <br />
      Website: site, which can be accessed via this URL: panomix.io
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Introduction
      </span>
      <br />
      This Cookie Policy explains how Panomix/Playbook and its affiliates
      (collectively "Panomix/Playbook", "we", "us", and "ours"), use cookies and
      similar technologies to recognize you when you visit our website,
      including without limitation panomix.io and any related URLs, mobile or
      localized versions and related domains / sub-domains ("Websites"). It
      explains what these technologies are and why we use them, as well as the
      choices for how to control them.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        What is a cookie?
      </span>
      <br />
      A cookie is a small text file that is stored on your computer or other
      internet connected device in order to identify your browser, provide
      analytics, remember information about you such as your language preference
      or login information. They're completely safe and can't be used to run
      programs or deliver viruses to your device.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Why do we use cookies?
        <br />
      </span>
      We use first party and/or third party cookies on our website for various
      purposes such as:
      <br />
      <br />
      To facilitate the operation and functionality of our website;
      <br />
      To improve your experience of our website and make navigating around them
      quicker and easier;
      <br />
      To allow us to make a bespoke user experience for you and for us to
      understand what is useful or of interest to you;
      <br />
      To analyze how our website is used and how best we can customize it;
      <br />
      To identify future prospects and personalize marketing and sales
      interactions with it;
      <br />
      To facilitate the tailoring of online advertising to your interests.
      <br />
      <br />
      You: a person or entity that is registered with Panomix/Playbook to use
      the Services.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        What type of cookies does Panomix/Playbook use?
      </span>
      <br />
      Cookies can either be session cookies or persistent cookies. A session
      cookie expires automatically when you close your browser. A persistent
      cookie will remain until it expires or you delete your cookies.
      <br />
      <br />
      Expiration dates are set in the cookies themselves; some may expire after
      a few minutes while others may expire after multiple years.
      <br />
      <br />
      Cookies placed by the website you’re visiting are called “first party
      cookies”.
      <br />
      <br />
      Strictly Necessary cookies are necessary for our website to function and
      cannot be switched off in our systems. They are essential in order to
      enable you to navigate around the website and use its features. If you
      remove or disable these cookies, we cannot guarantee that you will be able
      to use our website.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        We use the following types of cookies in our website:
      </span>
      <br />
      Essential Cookies : We use essential cookies to make our website work.
      These cookies are strictly necessary to enable core functionality such as
      security, network management, your cookie preferences and accessibility.
      Without them you wouldn't be able to use basic services. You may disable
      these by changing your browser settings, but this may affect how the
      Websites function.
      <br />
      <br />
      Performance and Functionality Cookies : These cookies are used to enhance
      the performance and functionality of our website but are non-essential to
      their use. However, without these cookies, certain functionality like
      videos may become unavailable or you would be required to enter your login
      details every time you visit the website as we would not be able to
      remember that you had logged in previously.
      <br />
      <br />
      Analytics and Customization Cookies : These cookies collect information
      that is used to help us understand how our website is being used or how
      effective our marketing campaigns are, or to help us customize our website
      for you.
      <br />
      <br />
      We use cookies served by Google Analytics to collect limited data directly
      from end-user browsers to enable us to better understand your use of our
      website. Further information on how Google collects and uses this data can
      be found at: https://www.google.com/policies/privacy/partners/. You can
      opt-out of all Google supported analytics on our Websites by visiting:
      https://tools.google.com/dlpage/gaoptout.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        How you can manage cookies?
        <br />
      </span>
      Most browsers allow you to control cookies through their 'settings'
      preferences. However, if you limit the ability of websites to set cookies,
      you may worsen your overall user experience, since it will no longer be
      personalized to you. It may also stop you from saving customized settings
      like login information. Browser manufacturers provide help pages relating
      to cookie management in their products.
      <br />
      <br />
      Browser manufacturers provide help pages relating to cookie management in
      their products. Please see below for more information.
      <br />
      <br />
      Google Chrome Internet Explorer Mozilla Firefox Safari (Desktop) Safari
      (Mobile) Android Browser Opera Opera Mobile
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Blocking and disabling cookies and similar technologies
      </span>
      <br />
      Wherever you're located you may also set your browser to block cookies and
      similar technologies, but this action may block our essential cookies and
      prevent our website from functioning properly, and you may not be able to
      fully utilize all of its features and services. You should also be aware
      that you may also lose some saved information (e.g. saved login details,
      site preferences) if you block cookies on your browser. Different browsers
      make different controls available to you. Disabling a cookie or category
      of cookie does not delete the cookie from your browser, you will need to
      do this yourself from within your browser, you should visit your browser's
      help menu for more information.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Changes To Our Cookie Policy
      </span>
      <br />
      We may change our Service and policies, and we may need to make changes to
      this Cookie Policy so that they accurately reflect our Service and
      policies. Unless otherwise required by law, we will notify you (for
      example, through our Service) before we make changes to this Cookie Policy
      and give you an opportunity to review them before they go into effect.
      Then, if you continue to use the Service, you will be bound by the updated
      Cookie Policy. If you do not want to agree to this or any updated Cookie
      Policy, you can delete your account.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Your Consent
      </span>
      <br />
      By using our website, registering an account, or making a purchase, you
      hereby consent to our Cookie Policy and agree to its terms.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Contact Us
      </span>
      <br />
      Don't hesitate to contact us if you have any questions regarding our
      Cookie Policy.
      <br />
      <br />
      Via Email: info@panomix.io
    </Wrapper>
  );
}

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0px 30px;
  @media (min-width: 600px) {
    padding: 0px 120px;
  }

  font-size: 14px;
  font-family: Poppins;
  color: gray;

  word-break: break-word;
  word-wrap: break-word;
  text-align: left;
`;

const Headline = styled.h3`
  color: gray;
  font-family: Poppins;
  text-align: center;
  font-size: 30px;
  letter-spacing: -2px;
  @media (min-width: 600px) {
    font-size: 40px;
  }
  margin-bottom: 30px;
`;
