import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import NavDesktop from '../../components/Nav/Desktop/NavDesktop';
import NavMobile from '../../components/Nav/Mobile/NavMobile';
import Footer from '../../components/Footer/Footer';
import Headline from '../../components/Headline';

import playbookLogo from '../../assets/logos/Panomix/Playbook.svg';
import PricingTier from './contents/PricingTier';
import PricingDetail from './contents/PricingDetail';
import { PRICING_DATA } from './PRICING_DATA';
import styled, { css } from 'styled-components';

export default function Pricing() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const isPC = useMediaQuery({
    minWidth: 1150,
  });

  const isPad = useMediaQuery({
    maxWidth: 1149,
  });

  const isMobile = useMediaQuery({
    maxWidth: 979,
  });

  return (
    <PricingWrapper>
      {isPC && <NavDesktop />}
      {isMobile && <NavMobile />}

      <Headline text={PRICING_DATA.title} />
      <PlaybookLogo src={playbookLogo} />

      <AlignBox>
        <PricingTiers isPC={isPC} isPad={isPad}>
          {PRICING_DATA.pricingTiers.map((data, idx) => (
            <PricingTier key={idx} data={data} />
          ))}
        </PricingTiers>

        {/* <PricingDetails isPC={isPC} isPad={isPad}>
          {PRICING_DATA.pricingDetails.map((data, idx) => (
            <PricingDetail key={idx} data={data} />
          ))}
        </PricingDetails> */}
      </AlignBox>
      <Footer />
    </PricingWrapper>
  );
}

const PricingWrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 120px 0px;
  padding: 0px 120px;

  @media (max-width: 980px) {
    margin: 120px 0px;
    padding: 0px 40px;
  }
`;

const PlaybookLogo = styled.img`
  width: 167px;
  padding-top: 40px;
`;

const AlignBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PricingTiers = styled.article`
  margin: 35px;
  min-width: 320px;

  ${({ isPC }) =>
    isPC &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      gap: 10px;
    `}

  ${({ isPad }) =>
    isPad &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
    `}
`;

const PricingDetails = styled.article`
  margin: 35px;

  ${({ isPC }) =>
    isPC &&
    css`
      display: flex;
      flex-direction: column;
      align-self: flex-start;
    `}

  ${({ isPad }) =>
    isPad &&
    css`
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      gap: 20px;

      max-width: 360px;
      min-width: 220px;
    `};
`;
