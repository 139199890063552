import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import NavDesktop from '../../components/Nav/Desktop/NavDesktop';
import NavMobile from '../../components/Nav/Mobile/NavMobile';

import Footer from '../../components/Footer/Footer';
import Button from '../../components/Button/Button';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { AI_DATA } from './AI_DATA';
import { MWrapper, MTitle, MTitleDescription, MImage } from '../PageStyles';

import MobileSideMenu from '../../components/SideMenu/MobileSideMenu';

import GenAIImage from '../../assets/images/GenAIStack.svg';

function GenerativeAIMobile() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const { language } = useContext(LanguageContext);

  return (
    <>
      <MWrapper>
        <MTitle>{AI_DATA.title[language]}</MTitle>
        <MTitleDescription>
          {AI_DATA.titleDescription[language]}
        </MTitleDescription>
        <Button type='scheduleDemo' />

        <MImage src={GenAIImage} />
        <MobileSideMenu sections={AI_DATA.sections} />
      </MWrapper>
    </>
  );
}

export default GenerativeAIMobile;
