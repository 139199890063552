import React from 'react';
import styled from 'styled-components';

export default function Headline({ text, align, color }) {
  return (
    <Wrapper align={align} color={color}>
      {text}
    </Wrapper>
  );
}

const Wrapper = styled.h2`
  font-family: 'Poppins';

  letter-spacing: -0.03em;
  text-align: ${({ align }) => align || 'center'};
  color: ${({ color }) => color || 'black'};

  @media (max-width: 980px) {
    font-size: 34px;
    line-height: 42px;
  }

  @media (min-width: 981px) {
    font-size: 72px;
    line-height: 84px;
    width: 780px;
  }
`;
