import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../../contextApi/LanguageContext';
import theme from '../../../styles/theme';

export const DashboardContent = () => {
  const { language } = useContext(LanguageContext);

  const [currentDashboard, setCurrentDashboard] = useState(0);

  const DATA = {
    sectionTitle: {
      en: 'Dashboards',
      ko: '대시보드',
    },
    dashboards: [
      {
        title: 'KPI Analytics',
        description: {
          en: "The KPI Analytics dashboard furnishes essential performance metrics like sessions, revenue, ad spend, and new users within a selected timeframe. It employs a Stacked Line Chart, grounded in MMM model results, to visually depict how different channels contribute to these KPIs. Additionally, a concise table offers a clear breakdown of each channel's impact on the metrics, aiding informed decision-making.",
          ko: 'KPI 분석 대시보드는 선택한 기간 내 세션, 수익, 총 광고 비용, 신규 사용자 등 주요 성과 지표를 제공합니다. MMM 모델 결과를 기반으로 한 스택된 라인 차트를 활용하여 다양한 채널이 이러한 KPI에 기여하는 방식을 시각적으로 보여줍니다. 더불어 간결한 테이블은 각 채널이 지표에 미치는 영향을 명확하게 나타내어 정보 기반 의사 결정을 지원합니다.',
        },
        image: require('../../../assets/images/KPIAnalytics.png'),
      },
      {
        title: 'Paid Channel Analytics',
        description: {
          en: 'The Paid Channel Analytics dashboard centers around advertising platforms such as Meta Ads, Google Ads, and TikTok Ads. Drawing insights from the MMM outcomes, it emphasizes contribution metrics. Within this dashboard, you can effortlessly visualize campaign effectiveness using a scatter chart. This chart portrays the combined contribution on the Y-axis and CPA on the X-axis, based on the chosen KPI and time frame.',
          ko: '유료 채널 분석 대시보드는 메타 광고, 구글 광고, 틱톡 광고와 같은 광고 플랫폼에 초점을 맞춥니다. 이러한 대시보드는 MMM 결과에서 유도된 기여 지표에 중점을 둡니다. 유료 채널 분석 내에서 선택한 KPI와 기간에 따라 전체 기여도를 Y축에, CPA를 X축에 나타내는 스캐터 차트를 통해 캠페인 성과를 쉽게 시각화할 수 있습니다.',
        },
        image: require('../../../assets/images/PaidChannelAnalytics.png'),
      },
      {
        title: 'Model Performance',
        description: {
          en: "The Model Performance dashboard displays the performance of MMM on various KPIs such as new users, sessions, and revenue. Through a line chart comparing actual and predicted trends, users can easily visualize the model's effectiveness, gaining confidence in their decision-making process.",
          ko: '모델 성능 대시보드는 새로운 사용자, 세션, 수익과 같은 각 KPI의 MMM 성능을 보여주는 곳입니다. 실제와 예측된 선의 라인 그래프를 통해 사용자들은 모델의 성능을 시각화하여 의사 결정에 대한 확신을 얻을 수 있습니다.',
        },
        image: require('../../../assets/images/ModelPerformance.png'),
      },
    ],
  };

  const handleClick = (index) => {
    setCurrentDashboard(index);
  };

  return (
    <>
      <SectionTitle>{DATA.sectionTitle[language]}</SectionTitle>
      <DashboardGrid>
        <DashboardFirstGrid>
          <MenuGrid>
            {DATA.dashboards.map((dashboard, idx) => (
              <MenuBox
                key={idx}
                active={currentDashboard === idx}
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(idx);
                }}
              >
                {dashboard.title}
              </MenuBox>
            ))}
          </MenuGrid>
          <ItemImage
            src={DATA.dashboards[currentDashboard].image}
            alt='KPIAnalytics'
          />
        </DashboardFirstGrid>
        <DashboardSecondGrid>
          <DashboardTitle>
            {DATA.dashboards[currentDashboard].title}
          </DashboardTitle>
          <DashboardDesc>
            {DATA.dashboards[currentDashboard].description[language]}
          </DashboardDesc>
        </DashboardSecondGrid>
      </DashboardGrid>
      <Divider />
    </>
  );
};

const SectionTitle = styled.h2`
  font-size: 44px;
  line-height: 50px;
  font-family: 'Poppins';
  font-weight: 300;
  width: auto;
  max-width: 680px;
  color: black;
  margin-bottom: 40px;
  align-text: left;
`;

const DashboardGrid = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 40px;
`;

const DashboardFirstGrid = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 10px;
`;

const MenuBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: auto;
  border-bottom: 4px solid
    ${(props) => (props.active ? theme.color.primary.blue : 'transparent')};
  color: ${(props) =>
    props.active ? 'black' : theme.color.neutral.spaceGray4};
  padding-bottom: 10px;
`;

const DashboardSecondGrid = styled.div`
  flex: 1;
`;

const DashboardTitle = styled.div`
  font-size: 22px;
  line-height: 32px;
  font-family: Arial;
  width: auto;
  color: black;
  font-weight: bold;
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 100%;
  margin-right: 40px;
  margin-top: 80px;
  margin-bottom: 48px;
`;
const DashboardDesc = styled.div`
  font-size: 18px;
  font-family: Arial;
  line-height: 26px;
  font-weight: regular;
  color: black;
  margin-top: 10px;
`;

const ItemImage = styled.img`
  max-width: 100%;
  height: auto;
  min-width: 600px;

  @media (max-width: 980px) {
    min-width: 300px;
    margin: 40px auto;
  }
`;
