import theme from '../../styles/theme';

export const PLAYBOOK_BUTTON_OPTION = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Log In',
    ko: '로그인',
  },
  url: 'https://playbook.panomix.io',

  buttonColor: theme.color.neutral.spaceGray4,
  nameColor: theme.color.secondary.white,
};

export const LEARNMORE_BUTTON_OPTION = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Learn More',
    ko: '더 알아보기',
  },
  url: 'https://panomix.github.io/panomix/',
  buttonColor: theme.color.neutral.spaceGray1,
  nameColor: theme.color.secondary.white,
};

export const CONTACT_US_BUTTON_OPTION = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Contact Us',
    ko: '상담하기',
  },
  url: 'https://calendly.com/panomix/products',
  buttonColor: theme.color.primary.blue,
  nameColor: theme.color.secondary.white,
};

export const REQUEST_DEMO_BUTTON_OPTION = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Request Demo',
    ko: '데모 요청하기',
  },
  url: 'https://calendly.com/panomix/products',
  buttonColor: theme.color.neutral.spaceGray1,
  nameColor: theme.color.secondary.white,
};

export const SCHEDULE_DEMO_BUTTON_OPTION = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Schedule Demo',
    ko: '데모 요청하기',
  },
  url: 'https://calendly.com/panomix/products',
  buttonColor: theme.color.primary.blue,
  nameColor: theme.color.secondary.white,
};

export const AI_CHAT_BUTTON_OPTION = {
  type: 'triggerBot',
  name: {
    en: 'Ask AI',
    ko: 'AI에게 문의하기',
  },
  url: 'https://calendly.com/panomix/products',
  buttonColor: theme.color.neutral.spaceGray2,
  nameColor: theme.color.secondary.white,
};

export const PLAYBOOK_LEARN_MORE = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Learn More',
    ko: '플레이북 알아보기',
  },
  url: 'https://panomix.github.io/panomix/playbook/overview/',
  buttonColor: theme.color.neutral.spaceGray1,
  nameColor: theme.color.secondary.white,
};

export const SWEN_LEARN_MORE = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Learn More',
    ko: '스웬AI 알아보기',
  },
  url: 'https://panomix.github.io/panomix/swen/overview/',
  buttonColor: theme.color.neutral.spaceGray1,
  nameColor: theme.color.secondary.white,
};
export const SOCIALBOT_LEARN_MORE = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Learn More',
    ko: '소셜봇 알아보기',
  },
  url: 'https://panomix.github.io/panomix/socialbot/overview/',
  buttonColor: theme.color.neutral.spaceGray1,
  nameColor: theme.color.secondary.white,
};

export const CUSTOM_LEARN_MORE = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Learn More',
    ko: '커스텀AI 알아보기',
  },
  url: 'https://panomix.github.io/panomix/custom/overview/',
  buttonColor: theme.color.neutral.spaceGray1,
  nameColor: theme.color.secondary.white,
};

export const TIKTOK_LEARN_MORE = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Learn More',
    ko: '틱톡 알아보기',
  },
  url: 'https://panomix.github.io/panomix/tiktok/overview/',
  buttonColor: theme.color.neutral.spaceGray1,
  nameColor: theme.color.secondary.white,
};

export const BOTPRESS_LEARN_MORE = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Learn More',
    ko: '봇프레스 알아보기',
  },
  url: 'https://panomix.github.io/panomix/botpress/overview/',
  buttonColor: theme.color.neutral.spaceGray1,
  nameColor: theme.color.secondary.white,
};

export const CONSULTING_LEARN_MORE = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Contact Us',
    ko: '상담하기',
  },
  url: 'https://calendly.com/panomix/products',
  buttonColor: theme.color.primary.green,
  nameColor: theme.color.secondary.black,
};

export const PLAYBOOK_SIGN_IN = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Sing In',
    ko: '로그인',
  },
  url: 'https://playbook.panomix.io',
  buttonColor: theme.color.neutral.spaceGray4,
  nameColor: theme.color.secondary.black,
};
