import React from 'react';
import styled from 'styled-components';

export default function Description({ text, align }) {
  return <Wrapper align={align}>{text}</Wrapper>;
}

const Wrapper = styled.span`
  font-family: 'Arial';
  font-weight: 400;
  letter-spacing: -0.03em;
  text-align: ${({ align }) => align || 'center'};
  color: #fff;

  @media (max-width: 980px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: 981px) {
    font-size: 18px;
    line-height: 24px;
  }
`;
