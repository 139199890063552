import React from 'react';
import styled from 'styled-components';
import PercentCard from '../../Main/Desktop/PercentCard';

export default function EP02() {
  const bulletedParagraphStyle = {
    listStyleType: 'disc', // Use disc for filled circles, or other values like square, decimal, etc.
    paddingLeft: '1rem',
  };
  const listItemStyle = {
    marginBottom: '0.5rem', // Adjust the value as per your desired line spacing size
  };
  return (
    <>
      <TitleArea>{'[Case Study] Boosters'}</TitleArea>
      <Author>Author</Author>
      <AuthorName>Sean Park, COO</AuthorName>
      <Divider />
      <Body>
        <MetricArea>
          <MetricWrapper>
            <Metric>39%</Metric>
            <MetricTitle>potential increase in ROAS</MetricTitle>
          </MetricWrapper>
          <MetricWrapper>
            <Metric>40%</Metric>
            <MetricTitle>
              more in explained contributors than last click model
            </MetricTitle>
          </MetricWrapper>
          <MetricWrapper>
            <Metric>92%</Metric>
            <MetricTitle>model fit on total revenue</MetricTitle>
          </MetricWrapper>
        </MetricArea>
        <SubTitle>Boosters</SubTitle>
        <BodyText>
          <div>
            Boosters is a Seoul-based "brand aggregator" that fosters the growth
            of high-potential brands through active investment and acquisition.
            Equipped with a unique strategy of direct-to-consumer commerce and
            adept multichannel marketing techniques, Boosters has rapidly scaled
            up its acquired brands.
          </div>
          <br></br>
          <div>
            This South Korean firm is one of the nation's leading brand
            aggregators, enhancing the value of each brand under its umbrella.
            Boosters' approach involves not only bolstering individual brands
            but also acquiring diverse commerce value chains that can synergize
            with their brands. As it ambitiously expands its portfolio, Boosters
            exemplifies the dynamism that increasingly characterizes South
            Korea's commercial sector.
          </div>
        </BodyText>
        <SubTitle>Challenges</SubTitle>
        <BodyText>
          <div>
            Branden, a branch of the enterprise specializing in travel gear,
            became disgruntled due to its inability to accurately assess the
            effectiveness of its marketing initiatives. This was largely
            attributed to its heavy reliance on the last-click attribution
            model, which utilized UTM links that only captured a limited portion
            of their revenue.
          </div>
          <br></br>
          <div>This shortcoming can be attributed to several factors:</div>
          <br></br>
          <div>
            <ul style={bulletedParagraphStyle}>
              <li style={listItemStyle}>
                Discrepancies in the reports generated by different advertising
                platforms, each utilizing their unique attribution models, often
                resulted in inconsistent aggregated metrics. This inconsistency
                became particularly problematic when multiple campaigns were
                running concurrently across various channels.
              </li>
              <li style={listItemStyle}>
                A significant portion of transactions went untracked in Google
                Analytics 4 (GA4) due to limitations inherent in third-party
                payment systems such as Naver Pay, which are incompatible with
                this feature.
              </li>
            </ul>
          </div>
        </BodyText>

        <SubTitle>Playbook Models</SubTitle>
        <BodyText>
          <div>
            Playbook has developed a model centered around three crucial Key
            Performance Indicators (KPIs): 'sessions', 'new users', and
            'revenue'. The 'sessions' and 'new users' metrics are derived from
            Google Analytics, while the 'revenue' metric is constructed using a
            combination of data collected from Cafe24 and raw revenue figures.
            By utilizing this unified and reliable data source, the model
            achieves a revenue fit of 0.92, indicating that it accurately
            predicts 92% of the revenue.
          </div>
        </BodyText>
        <div alignitems='center'>
          <ItemImage
            src={require('./Figures/ModelPerformanceBoosters.png')}
            alt='ModelPerformanceBoosters'
          />
        </div>
        <SubTitle>Findings</SubTitle>
        <BodyText>
          <div>
            During the process of refining the overall understanding of
            contributing factors, the Playbook model revealed over 40%
            additional revenue contributions.
          </div>
          <br></br>
          <div>
            <ul style={bulletedParagraphStyle}>
              <li style={listItemStyle}>
                The contribution of 'Facebook Ads' was nearly three times higher
                than what was estimated by the last-click attribution model.
              </li>
              <li style={listItemStyle}>
                The contribution of Naver's products experienced a significant
                increase.
              </li>
              <li style={listItemStyle}>
                PPL (Product Placement) represented by content from influencers
                like YouTubers witnessed a substantial increase of over 50%.
              </li>
              <li style={listItemStyle}>
                The share of Google Search's Cost-Per-Click (CPC) decreased in
                the Playbook ML model.
              </li>
              <li style={listItemStyle}>
                The share of Kakao Biz Messenger experienced a significant
                surge, growing to nearly five times.
              </li>
            </ul>
          </div>
        </BodyText>
        <div alignitems='center'>
          <ItemImage
            src={require('./Figures/BoostersLCVSML.png')}
            alt='BoostersLCVSML'
            width='1000px'
          />
        </div>
        <SubTitle>Follow-ups</SubTitle>
        <BodyText>
          <div>
            In the 30-day period from May 23 to June 22, Branden increased its
            expenditure on Facebook Ads by 37% compared to the previous period.
            This surge appears to have yielded tangible rewards, with their
            revenue growing by 53% and sessions increasing by 49%. Additionally,
            despite the significant increase in sessions, the revenue per
            session increased by 3% more, indicating an improvement in the
            quality of traffic.
          </div>
        </BodyText>
        <CardArea>
          <PercentCard title='Sessions' percentage={48.6} />
          <PercentCard title='Avg. Daily Total Users' percentage={30.9} />
          <PercentCard title='Avg. Daily New Users' percentage={51.5} />
          <PercentCard title='Total Ad Spend' percentage={37.0} />
          <PercentCard title='Revenue' percentage={52.9} />
          <PercentCard title='Sales' percentage={46.0} />
          <PercentCard title='Revenue Per Sale' percentage={4.7} />
          <PercentCard title='Revenue Per Session' percentage={2.9} />
        </CardArea>
        <SubTitle>Conclusion</SubTitle>
        <BodyText>
          <div>
            It is indeed noteworthy that despite implementing last-click
            attribution and using UTM links on all campaigns, which offer a more
            direct and reliable way to track the consumer journey compared to
            other attribution models, there is still a substantial portion of
            overall sales that cannot be attributed or explained.
          </div>
        </BodyText>
      </Body>
    </>
  );
}

const TitleArea = styled.div`
  font-family: 'Poppins';
  font-size: 72px;
  line-height: 84px;
  width: 600px;

  @media (max-width: 980px) {
    font-size: 48px;
    line-height: 54px;
    width: 360px;
  }
`;

const Author = styled.div`
  font-family: 'Arial';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-top: 80px;
`;

const AuthorName = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 24px;
`;
const Divider = styled.div`
  height: 1px;
  background-color: black;
  margin-top: 14px;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1440px) {
    margin: 80px 280px 80px 280px;
  }
  @media (max-width: 1439px) {
    margin: 80px 180px 80px 180px;
  }
  @media (max-width: 980px) {
    margin: 20px 10px 140px 10px;
  } ;
`;

const MetricArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-items: center;
  margin-top: 20px;
  margin-bottom: 40px;

  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
  }
`;
const MetricWrapper = styled.div`
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Metric = styled.div`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 72px;
  margin-bottom: 10px;
`;

const MetricTitle = styled.div`
  font-family: 'Arial';
  font-size: 20px;
  width: 280px;
  margin-bottom: 10px;
`;

const CardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2px;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 40px;

  @media (max-width: 400px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;
const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  margin-top: 50px;
  margin-bottom: 10px;

  @media (max-width: 980px) {
    margin-top: 30px;
  }
`;

const BodyText = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 32px;
  margin-top: 10px;
`;
const ItemImage = styled.img`
  display: block;
  margin: 80px auto; /* Centers the image horizontally */
  max-width: 100%;
  height: auto;
  min-width: 600px;

  @media (max-width: 980px) {
    min-width: 300px;
    margin: 40px auto;
  }
`;
