import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import NavDesktop from '../../components/Nav/Desktop/NavDesktop';
import NavMobile from '../../components/Nav/Mobile/NavMobile';

import Footer from '../../components/Footer/Footer';
import Button from '../../components/Button/Button';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { AI_DATA } from './AI_DATA';
import {
  Wrapper,
  MainWrapper,
  MainGrid,
  TitleArea,
  Title,
  DescriptionArea,
  TitleDescription,
  ButtonArea,
} from '../PageStyles';

import SideMenu from '../../components/SideMenu/SideMenu';

import { ReactComponent as GenAIImage } from '../../assets/images/GenAIStack.svg';
import GenerativeAIMobile from './GenerativeAIMobile';
import { Helmet } from 'react-helmet';

function GenerativeAI() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const { language, setLanguage } = useContext(LanguageContext);

  const isDesktop = useMediaQuery({ minWidth: 981 });

  return (
    <>
      {isDesktop ? (
        <NavDesktop language={language} setLanguage={setLanguage} />
      ) : (
        <NavMobile language={language} setLanguage={setLanguage} />
      )}
      {isDesktop ? (
        <>
          <Helmet>
            <title>Geneative AI Automation Stacks</title>
            <meta
              name='description'
              content='
          Combining multiple products & solutions to architect GenAI solutions such as customer service chatbot and automated content management system.'
            />
          </Helmet>
          <Wrapper>
            <MainWrapper>
              <MainGrid>
                <TitleArea>
                  <Title>{AI_DATA.title[language]}</Title>
                  <DescriptionArea>
                    <TitleDescription>
                      {AI_DATA.titleDescription[language]}
                    </TitleDescription>
                    <ButtonArea>
                      <Button type='scheduleDemo' />
                      <Button type='askAI' />
                    </ButtonArea>
                  </DescriptionArea>
                </TitleArea>
                <div
                  style={{ marginTop: '2rem', width: 'auto', height: 'auto' }}
                >
                  <GenAIImage />
                </div>
              </MainGrid>
            </MainWrapper>
            <SideMenu sections={AI_DATA.sections} />
          </Wrapper>
        </>
      ) : (
        <GenerativeAIMobile />
      )}
      <Footer />
    </>
  );
}

export default GenerativeAI;
