import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import NavDesktop from '../../components/Nav/Desktop/NavDesktop';
import NavMobile from '../../components/Nav/Mobile/NavMobile';

import Footer from '../../components/Footer/Footer';
import Button from '../../components/Button/Button';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { PLAYBOOK_DATA } from './PLAYBOOK_DATA';
import {
  Wrapper,
  MainWrapper,
  MainGrid,
  TitleArea,
  Title,
  DescriptionArea,
  TitleDescription,
  ButtonArea,
} from '../PageStyles';
import PlaybookMobile from './PlaybookMobile';

import SideMenu from '../../components/SideMenu/SideMenu';

import { ReactComponent as PlaybookLogo } from '../../assets/logos/Panomix/Playbook.svg';
import { ReactComponent as PlaybookImage } from '../../assets/images/PlaybookImage.svg';
import { Helmet } from 'react-helmet';

function Playbook() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const { language, setLanguage } = useContext(LanguageContext);

  const isDesktop = useMediaQuery({ minWidth: 981 });

  return (
    <>
      <Helmet>
        <title>Advanced Media Mix Modeling</title>
        <meta
          name='description'
          content='Discover Playbook, a marketing analytics SaaS with advanced media mix modeling (MMM) analytics for optimal performance insights.'
        />
      </Helmet>
      {isDesktop ? (
        <NavDesktop language={language} setLanguage={setLanguage} />
      ) : (
        <NavMobile language={language} setLanguage={setLanguage} />
      )}
      {isDesktop ? (
        <>
          <Wrapper>
            <MainWrapper>
              <MainGrid>
                <TitleArea>
                  <Title>{PLAYBOOK_DATA.title[language]}</Title>
                  <DescriptionArea>
                    <PlaybookLogo />
                    <TitleDescription>
                      {PLAYBOOK_DATA.titleDescription[language]}
                    </TitleDescription>
                    <ButtonArea>
                      <Button type='scheduleDemo' />
                      <Button type='askAI' />
                    </ButtonArea>
                  </DescriptionArea>
                </TitleArea>
                <div
                  style={{ marginTop: '2rem', width: 'auto', height: 'auto' }}
                >
                  <PlaybookImage />
                </div>
              </MainGrid>
            </MainWrapper>
            <SideMenu sections={PLAYBOOK_DATA.sections} />
          </Wrapper>
          <Footer />
        </>
      ) : (
        <PlaybookMobile />
      )}
    </>
  );
}

export default Playbook;
