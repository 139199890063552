import React from 'react';
import { OverviewContent } from './PlaybookDetails/OverviewContent';
import { FeatureContent } from './PlaybookDetails/FeatureContent';
import { DashboardContent } from './PlaybookDetails/DashboardContent';
import { DataGovContent } from './PlaybookDetails/DataGovContent';
import { MarketingDatalake } from './PlaybookDetails/MarketingDataLake';
import { ModelMaintenance } from './PlaybookDetails/ModelMaintenance';
import { OverviewContentMobile } from './PlaybookDetails/OverviewContentMobile';
import { DashboardContentMobile } from './PlaybookDetails/DashboardContentMobile';
import { ModelMaintenanceMobile } from './PlaybookDetails/ModelMaintenanceMobile';
import { MarketingDatalakeMobile } from './PlaybookDetails/MarketingDataLakeMobile';
import { DataGovContentMobile } from './PlaybookDetails/DataGovContentMobile';
import { FeatureContentMobile } from './PlaybookDetails/FeatureContentMobile';

export const PLAYBOOK_DATA = {
  title: {
    en: 'Final Destination for Media Mix Modeling',
    ko: '미디어 믹스 모델링을 통한 마케팅 효율 극대화',
  },
  titleDescription: {
    en: 'Measure and forecast campaigns with high performing & consistent MMM, powered by advanced features. Schedule a demo now or learn more with our GenAI chatbot.',
    ko: '높은 퍼포먼스와 일관된 MMM 모델을 통해 마케팅 캠페인을 측정하고 예측합니다. 생성형AI 챗봇을 통해 자세히 알아보거나 라이브 데모를 요청하세요.',
  },
  sections: [
    {
      key: 'overview',
      title: {
        en: 'Overview',
        ko: '개요',
      },
      content: {
        desktop: <OverviewContent />,
        mobile: <OverviewContentMobile />,
      },
    },
    {
      key: 'features',
      title: {
        en: 'Features',
        ko: '주요 기능',
      },
      content: {
        desktop: <FeatureContent />,
        mobile: <FeatureContentMobile />,
      },
    },
    {
      key: 'dashboards',
      title: {
        en: 'Dashboards',
        ko: '대시보드',
      },
      content: {
        desktop: <DashboardContent />,
        // mobile: <DashboardContentMobile />,
      },
    },
    {
      key: 'ModelMaintenance',
      title: {
        en: 'Model Maintenance',
        ko: '모델 메인터넌스',
      },
      content: {
        desktop: <ModelMaintenance />,
        mobile: <ModelMaintenanceMobile />,
      },
    },

    {
      key: 'marketingDatalake',
      title: {
        en: 'Marketing DataLake',
        ko: '마케팅 데이터레이크',
      },
      content: {
        desktop: <MarketingDatalake />,
        // mobile: <MarketingDatalakeMobile />,
      },
    },
    {
      key: 'dataGovernance',
      title: {
        en: 'Data Governance',
        ko: '데이터 거버넌스',
      },
      content: {
        desktop: <DataGovContent />,
        mobile: <DataGovContentMobile />,
      },
    },
  ],
};
