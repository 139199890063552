import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import PanomixLogo from '../../../assets/logos/Panomix/Panomix.svg';
import { ReactComponent as CheveronSVG } from '../../../assets/icons/cheveron-down.svg';
import Button from '../../Button/Button';
import { SIMPLE_MENU_OPTIONS, MENU_OPTIONS } from '../NAV_DATA';
import theme from '../../../styles/theme';

export default function NavDesktop(props) {
  const navigate = useNavigate();
  const [hoveredMenu, setHoveredMenu] = useState(null); // Tracks which menu item is hovered
  const [isDropMenuVisible, setIsDropMenuVisible] = useState(false); // Controls dropdown visibility

  const handleMouseEnter = (menu) => {
    setHoveredMenu(menu);
    setIsDropMenuVisible(true); // Show dropdown when hovering over menu
  };

  const handleMouseLeave = () => {
    setIsDropMenuVisible(false); // Hide dropdown when mouse leaves both navbar and dropdown
  };
  const { language, setLanguage } = props;
  const moveToOtherPage = (url) => {
    navigate(url);
  };
  const moveToOtherWeb = (url) => {
    window.open(url, '_blank');
  };
  return (
    <>
      <Wrapper>
        <MenuArea>
          <Logo
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              window.location.replace('/');
            }}
            src={PanomixLogo}
          />
          {MENU_OPTIONS.map((menu, idx) => (
            <MenuWrapper
              key={idx}
              onMouseEnter={() => handleMouseEnter(menu)} // Pass the current menu item
            >
              <MenuName>{menu.title[language]}</MenuName>
              <CheveronSVG />
            </MenuWrapper>
          ))}
          {SIMPLE_MENU_OPTIONS.map((menu, idx) => (
            <MenuWrapper
              key={idx}
              onClick={(e) => {
                e.preventDefault();
                moveToOtherPage(menu.url);
              }}
            >
              <MenuName>{menu.title[language]}</MenuName>
            </MenuWrapper>
          ))}
        </MenuArea>
        <ButtonArea>
          <LanguageButton>
            <TextButton
              onClick={() => setLanguage('en')}
              isActive={language === 'en'}
            >
              EN
            </TextButton>
            |
            <TextButton
              onClick={() => setLanguage('ko')}
              isActive={language === 'ko'}
            >
              KR
            </TextButton>
          </LanguageButton>
          <Button type='contactUs' isInNav={true} />
        </ButtonArea>
      </Wrapper>

      {/* Dropdown menu that appears when an item is hovered */}
      {isDropMenuVisible && hoveredMenu && (
        <DropMenuArea
          onMouseEnter={() => handleMouseEnter(hoveredMenu)} // Keep menu open when hovering on the dropdown
          onMouseLeave={handleMouseLeave}
        >
          <ItemWrapper>
            <MenuTitleWrapper>
              <MenuTitle>{hoveredMenu.title[language]}</MenuTitle>
              <MenuDescription>
                {hoveredMenu.description[language]}
              </MenuDescription>
            </MenuTitleWrapper>
            <ItemGrid>
              {hoveredMenu.options && // Check if the hovered menu has options
                hoveredMenu.options.map((option, idx) => (
                  <MenuTitleWrapper
                    key={idx}
                    onClick={(e) => {
                      e.preventDefault();
                      moveToOtherWeb(option.url);
                    }}
                  >
                    <ProductTitle>{option.title[language]}</ProductTitle>
                    <ProductDescription>
                      {option.description[language]}
                    </ProductDescription>
                    <CatchPhraseWrapper>
                      {option.catchphrase[language]}
                    </CatchPhraseWrapper>
                  </MenuTitleWrapper>
                ))}
            </ItemGrid>
          </ItemWrapper>
        </DropMenuArea>
      )}
    </>
  );
}

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 68px;
  background-color: ${({ theme }) => theme.color.secondary.white};
  z-index: 5;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  @media (min-width: 1440px) {
    padding-left: calc((100% - 1440px) / 2);
    padding-right: calc((100% - 1440px) / 2);
  }
`;

const Logo = styled.img`
  height: 22px;
  cursor: pointer;
  margin: 0px 40px 0px 40px;
`;

const MenuArea = styled.div`
  display: flex;
  gap: 14px;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const MenuName = styled.button`
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.375;
  color: black;
  cursor: pointer;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  gap: 10px;
`;

const LanguageButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  color: black;
`;

const TextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ isActive }) =>
    isActive ? 'black' : theme.color.neutral.spaceGray4};
  cursor: pointer;
`;

const DropMenuArea = styled.div`
  width: 100%;
  height: 280px;
  background-color: white;
  position: fixed;
  top: 68px;
  z-index: 1000;
  @media (min-width: 1440px) {
    padding-left: calc((100% - 1440px) / 2);
    padding-right: calc((100% - 1440px) / 2);
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 40px 0px 40px;
`;

const MenuTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;

const MenuTitle = styled.h1`
  color: black;
  font-family: 'Poppins';
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const MenuDescription = styled.p`
  color: black;
  font-family: 'Roboto';
  font-size: 1rem;
  width: 260px;
  line-height: 1.5rem;
`;

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 40px;
`;

const ProductTitle = styled.h1`
  color: #0b8ffc;
  font-family: 'Poppins';
  font-size: 1.275rem;
  margin-bottom: 10px;
  cursor: pointer;
`;

const ProductDescription = styled.p`
  color: black;
  font-family: 'Roboto';
  font-size: 1rem;
  width: 300;
  line-height: 1.5rem;
  margin-bottom: 4px;
  cursor: pointer;
`;

const CatchPhraseWrapper = styled.p`
  color: #88909b;
  font-family: 'Roboto';
  font-size: 1rem;
  width: 420px;
  line-height: 1.5rem;
`;
