import React from 'react';
import styled from 'styled-components';

export default function EP04() {
  return (
    <>
      <TitleArea>Data Preparation</TitleArea>
      <Author>Author</Author>
      <AuthorName>Richard Kang, ML Engineer</AuthorName>
      <Divider />
      <Body>
        <BodyText>
          <div>
            In today's fast-paced and data-driven business landscape, making
            informed decisions is key to achieving success. This is especially
            true in the realm of marketing, where companies strive to optimize
            their strategies for maximum impact and returns on investment. A
            powerful tool that has emerged to empower businesses is Media Mix
            Modeling (MMM), a method that provides valuable insights into the
            effectiveness of various marketing endeavors. However, harnessing
            the potential of this tool requires more than just a simple
            approach; it demands meticulous preparation and execution.
          </div>
        </BodyText>
        <SubTitle>The Foundation: Data Preparation</SubTitle>
        <BodyText>
          <div>
            At the heart of any successful Media Mix Modeling endeavor lies data
            preparation. Even the most advanced algorithms are bound to fail if
            the inputs are not meticulously examined and processed. To
            illustrate this point, consider the analogy of using cheap oil in a
            high-end car. Despite its premium parts, the car would fail to
            perform at the intended level because of the oil. Similarly, in the
            context of data analysis, the quality and thoroughness of data
            preparation significantly impact the effectiveness of the final
            insights generated.
          </div>
        </BodyText>
        <SubTitle>1. Data Collection and Consolidation</SubTitle>
        <BodyText>
          <div>
            To derive meaningful insights, it's imperative that the data used
            for Media Mix Modeling spans a significant time period and
            encompasses all marketing efforts. below.
          </div>
          <br></br>
          <div>
            The temporal breadth enables the capture of seasonal trends,
            economic fluctuations, and other external factors that can influence
            sales and overall performance. By encompassing these variables, the
            model becomes more robust and capable of accurately reflecting the
            real-world dynamics impacting the business. For dynamic businesses,
            experimenting with the time period may be required. For example, if
            a significant change occurred in advertising strategies or the
            product lineup, it may be better to only capture the period after
            that point in time for the model.
          </div>
          <br></br>
          <div>
            Perhaps a more difficult task lies in data collection itself. It is
            not uncommon to see the data scattered across different teams and
            databases. It may be human heuristic to neglect some types of
            marketing data, but for initial modeling purposes, it is recommended
            to confirm that data from every available source has been collected.
          </div>
          <div alignitems='center'>
            <ItemImage
              src={require('./Figures/EP04-01.png')}
              alt='modelPeriod'
            />
          </div>
        </BodyText>

        <SubTitle>2. Data Transformation</SubTitle>
        <BodyText>
          <div>
            Raw data rarely arrives in a format optimal for analysis. This is
            where data transformation comes into play. Transforming data
            involves tasks such as aggregating data at appropriate time
            intervals (daily, weekly, monthly), converting currency values to a
            standardized unit, checking for unit disparities and aligning
            timeframes. These transformations serve to enhance the consistency
            of the data and ensure that it can be effectively utilized within
            the modeling process.
          </div>
        </BodyText>
        <SubTitle>3. Incorporating External Factors</SubTitle>
        <BodyText>
          <div>
            Marketing efforts don't exist in isolation; it is inevitably
            influenced by external factors that can either amplify or dampen its
            effects. These external factors can span anywhere from currency
            rates and oil prices to offline pop-up events and holidays. Simply
            put, any element that may have a substantial impact on sales and
            consumer behavior is worth considering. Integrating these external
            factors into the Media Mix Model as context variables is a positive
            step in improving the model's predictive accuracy. By accounting for
            these influences, businesses can gain deeper insights into the true
            drivers of their marketing outcomes.
          </div>

          <div alignitems='center'>
            <ItemImage src={require('./Figures/EP04-02.png')} alt='factors' />
          </div>
          <div>
            Media Mix Modeling presents a powerful opportunity for businesses to
            gain invaluable insights into their marketing strategies. The first
            step towards a successful Media Mix Model is to prepare and process
            the data correctly, similar to using the finest oil in a
            high-performance car. By thoughtfully addressing questions of data
            collection, transformation, and external factors, companies can
            unlock the full potential of MMM and make data-driven decisions that
            drive their marketing efforts to new heights of success.
          </div>
        </BodyText>
      </Body>
    </>
  );
}

const TitleArea = styled.div`
  font-family: 'Poppins';
  font-size: 72px;
  line-height: 84px;
  width: 600px;

  @media (max-width: 980px) {
    font-size: 48px;
    line-height: 54px;
    width: 360px;
  }
`;

const Author = styled.div`
  font-family: 'Arial';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-top: 80px;
`;

const AuthorName = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 24px;
`;
const Divider = styled.div`
  height: 1px;
  background-color: black;
  margin-top: 14px;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1440px) {
    margin: 80px 280px 80px 280px;
  }
  @media (max-width: 1439px) {
    margin: 80px 180px 80px 180px;
  }
  @media (max-width: 980px) {
    margin: 20px 10px 140px 10px;
  } ;
`;

const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  margin-top: 50px;
  margin-bottom: 10px;

  @media (max-width: 980px) {
    margin-top: 30px;
  }
`;

const BodyText = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 32px;
  margin-top: 10px;
`;

const ItemImage = styled.img`
  display: block;
  margin: 80px auto; /* Centers the image horizontally */
  max-width: 100%;
  height: auto;
  min-width: 600px;

  @media (max-width: 980px) {
    min-width: 300px;
    margin: 40px auto;
  }
`;
