import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { INDEX_01, INDEX_02 } from './ABOUT_US_DATA';

import Headline from '../../components/Headline';
import Description from '../../components/Description';
import Button from '../../components/Button/Button';
import { ReactComponent as MobileRoadmapSVG } from '../../assets/images/MobileRoadmap.svg';
import BackgroundSVG from '../../assets/images/BackgroundDesign.svg';

import styled from 'styled-components';

export default function AboutUsContentMobile() {
  const isDesktop = useMediaQuery({ minWidth: 981 });
  const isUnder512 = useMediaQuery({ maxWidth: 512 });

  return (
    <>
      <Wrapper>
        <TopPage>
          <Title>We are a Mar-tech Company</Title>
          <TitleDescription>
            We provide leading marketing solutions that drive business growth.
          </TitleDescription>
          <Button type='contactUs' />
          <MobileRoadmapSVG />
        </TopPage>
      </Wrapper>
    </>
  );
}
const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: auto;
`;

const TopPage = styled.div`
  display: flex;
  margin-top: 120px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: medium;
  line-height: 34px;
  color: ${({ theme }) => theme.color.neutral.spaceGray1};
  text-align: center;
  width: 360px;
`;

const TitleDescription = styled.div`
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 24px;
  font-weight: 200;
  color: ${({ theme }) => theme.color.neutral.spaceGray3};
  text-align: center;
  width: 340px;
`;
