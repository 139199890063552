import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import { MAIN_DATA, SERVICE_DATA } from '../MAIN_DATA';
import Button from '../../../components/Button/Button';
import ServiceCard from '../../../components/ServiceCard';
import ProductCard from '../../../components/ProductCard';

import theme from '../../../styles/theme';
import { ReactComponent as Logo1 } from '../../../assets/logos/logo_Ariul.svg';
import { ReactComponent as Logo2 } from '../../../assets/logos/logo_Wishtrend.svg';
import { ReactComponent as Logo3 } from '../../../assets/logos/logo_Didier Dubot.svg';
import { ReactComponent as Logo4 } from '../../../assets/logos/logo_Ggumbi.svg';
import { ReactComponent as Logo5 } from '../../../assets/logos/logo_Hang5.svg';
import { ReactComponent as Logo6 } from '../../../assets/logos/logo_Infinity Ventures.svg';
import { ReactComponent as Logo7 } from '../../../assets/logos/logo_Be Attitude.svg';
import { ReactComponent as Logo8 } from '../../../assets/logos/logo_Klairs.svg';
import { ReactComponent as Logo9 } from '../../../assets/logos/logo_Nurihaus.svg';
import { ReactComponent as Logo10 } from '../../../assets/logos/logo_Untold Lable.svg';

export default function DesktopContents(props) {
  const { language } = props;
  return (
    <Wrapper>
      <HeadlineWrapper>
        <Title language={language}>{MAIN_DATA[language].title}</Title>
        <TitleDescription language={language}>
          {MAIN_DATA[language].subTitle}
        </TitleDescription>
      </HeadlineWrapper>
      <ButtonArea>
        <Button type='learnMore' />
        <Button type='contactUs' />
      </ButtonArea>
      {/* <LogoWrapper>
        <LogoSlider>
          <Logo1 style={{ margin: '0 30px', height: '26px' }} />
          <Logo2 style={{ margin: '0 30px', height: '24px' }} />
          <Logo3 style={{ margin: '0 30px', height: '23px' }} />
          <Logo4 style={{ margin: '0 30px', height: '22px' }} />
          <Logo5 style={{ margin: '0 30px', height: '24px' }} />
          <Logo6 style={{ margin: '0 30px', height: '22px' }} />
          <Logo7 style={{ margin: '0 30px', height: '22px' }} />
          <Logo8 style={{ margin: '0 30px', height: '27px' }} />
          <Logo9 style={{ margin: '0 30px', height: '24px' }} />
          <Logo10 style={{ margin: '0 30px', height: '22px' }} />
        </LogoSlider>
        <LogoSlider>
          <Logo1 style={{ margin: '0 30px', height: '26px' }} />
          <Logo2 style={{ margin: '0 30px', height: '24px' }} />
          <Logo3 style={{ margin: '0 30px', height: '23px' }} />
          <Logo4 style={{ margin: '0 30px', height: '22px' }} />
          <Logo5 style={{ margin: '0 30px', height: '24px' }} />
          <Logo6 style={{ margin: '0 30px', height: '22px' }} />
          <Logo7 style={{ margin: '0 30px', height: '22px' }} />
          <Logo8 style={{ margin: '0 30px', height: '27px' }} />
          <Logo9 style={{ margin: '0 30px', height: '24px' }} />
          <Logo10 style={{ margin: '0 30px', height: '22px' }} />
        </LogoSlider>
      </LogoWrapper> */}
      <SectionTitle>PRODUCTS</SectionTitle>
      <MainCardArea>
        {MAIN_DATA[language].mainCardsLogo.map((service, idx) => (
          <ProductCard
            key={idx}
            color={service.color}
            logo={service.logo}
            description={service.description}
            buttonType={service.buttonType}
            titleColor={service.titleColor}
            descriptionColor={service.descriptionColor}
          />
        ))}
        {MAIN_DATA[language].mainCards.map((service, idx) => (
          <ServiceCard
            key={idx}
            color={service.color}
            title={service.title}
            description={service.description}
            buttonType={service.buttonType}
            titleColor={service.titleColor}
            descriptionColor={service.descriptionColor}
          />
        ))}
      </MainCardArea>
      <BannerBox>
        <BannerTitle>{SERVICE_DATA[language].title}</BannerTitle>
        <div>
          <Button type='contactUs' isInNav={false} />
        </div>
      </BannerBox>
      <SectionTitle>SERVICES</SectionTitle>
      <MainCardArea>
        {SERVICE_DATA[language].mainCards.map((service, idx) => (
          <ServiceCard
            key={idx}
            color={service.color}
            title={service.title}
            description={service.description}
            buttonType={service.buttonType}
            titleColor={service.titleColor}
            descriptionColor={service.descriptionColor}
          />
        ))}
        {SERVICE_DATA[language].mainCardsLogo.map((service, idx) => (
          <ProductCard
            key={idx}
            color={service.color}
            logo={service.logo}
            description={service.description}
            buttonType={service.buttonType}
            titleColor={service.titleColor}
            descriptionColor={service.descriptionColor}
          />
        ))}
      </MainCardArea>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  top: 72px;
  margin-bottom: 200px;
  transition: all ease 4s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;

const HeadlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  width: 100vw;
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 72px;
  font-weight: 400;
  line-height: 84px;
  width: ${(props) => (props.language === 'en' ? '980px' : '860px')};
  color: black;
  text-align: center;
  letter-spacing: -3px;
`;

const TitleDescription = styled.div`
  font-family: Arial;
  font-size: 20px;
  line-height: 26px;
  font-weight: 200;
  color: black;
  width: ${(props) => (props.language === 'en' ? '600px' : '480px')};
  text-align: center;
  margin-top: 20px;
`;

const ButtonArea = styled.div`
  display: flex;
  align-itmes: center;
  flex-direction: row;
  gap: 20px;
  margin-top: 40px;
`;

const MainCardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.color.neutral.spaceGray3};
  font-family: 'Poppins';
  font-size: 20px;
  margin-top: 80px;
`;

const LogoWrapper = styled.div`
  margin-top: 60px;
  max-width: 900px;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
`;

const LogoSlider = styled.div`
  display: inline-block;
  gap: 40px;
  ${({ theme }) => theme.animation.infiniteLoop}
`;

const BannerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 180px;
  border: 0px solid black;
  overflow: hidden;
  background-color: none;
  margin-top: 40px;
`;

const BannerTitle = styled.h2`
  font-size: 32px;
  font-family: 'Roboto';
  line-height: 140%;
  color: black;
  text-align: center;
  font-weight: 400;
  margin-bottom: 30px;
`;

const ModelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 80px;
`;

const ModelHeadline = styled.div`
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: medium;
  line-height: 42px;
  color: ${({ theme }) => theme.color.neutral.spaceGray1};
  width: 500px;
  text-align: center;
`;

const ModelDescription = styled.div`
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 24px;
  font-weight: 200;
  color: ${({ theme }) => theme.color.neutral.spaceGray3};
  width: 800px;
  text-align: center;
  margin-bottom: 40px;
`;

const IntegrationWrapper = styled.div`
  display: flex;
  width: 100vw;
  background-color: ${({ theme }) => theme.color.neutral.spaceGray1};
  height: 360px;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

const IntegrationHeadline = styled.div`
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: medium;
  line-height: 42px;
  color: white;
  width: auto;
  text-align: center;
`;

const IntegrationDescription = styled.div`
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 24px;
  font-weight: 200;
  color: white;
  width: 800px;
  text-align: center;
  margin-bottom: 40px;
`;

const ProductArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  justify-content: space-between;
  margin: 60px 0px 80px 0px;
  width: 80vw;
  background-color: ${(color) => color || 'white'};

  @media (min-width: 1600px) {
    width: 60vw;
  }
`;

const ProductAreaLast = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  justify-content: space-between;
  margin: 40px 0px 40px 0px;
  background-color: ${(color) => color || 'white'};
  width: 80vw;
  @media (min-width: 1600px) {
    width: 60vw;
  }
`;

const DetailArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Headline = styled.div`
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: 200;
  line-height: 42px;
  color: ${({ theme }) => theme.color.neutral.spaceGray1};
  width: 420px;
`;

const Description = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 28px;
  font-weight: 200;
  color: ${({ theme }) => theme.color.neutral.spaceGray3};
  width: 480px;
  text-align: left;
  margin-bottom: 20px;
`;
