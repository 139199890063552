import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckIconSVG } from '../../assets/icons/CheckIcon.svg';
import { ReactComponent as CheckIconSmallSVG } from '../../assets/icons/CheckIconSmall.svg';
import { ReactComponent as TikTokPartnerSVG } from '../../assets/icons/TikTokPartner.svg';
import { useMediaQuery } from 'react-responsive';

import Footer from '../../components/Footer/Footer';
import Headline from '../../components/Headline';
import styled from 'styled-components';
import { SERVICE_OPTIONS } from './SERVICE_OPTIONS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function Services() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const isMobile = useMediaQuery({ minWidth: 981 });

  return (
    <Wrapper>
      <MainAreaWrapper>
        <HeadlineWrapper>Comprehensive ML Analytics Services</HeadlineWrapper>
        <HeadlineDescription>
          Our comprehensive ML analytics services offer a holistic approach to
          leveraging machine learning for business insights and decision-making.
        </HeadlineDescription>
        <ServiceCardArea>
          <ServiceCard color='white'>
            <CardTitle>Playbook</CardTitle>
            <CardDescription>
              Powered by advanced marketing mix modeling, Playbook provides
              analytics and simulations at the most granular level.
            </CardDescription>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.open('https://calendly.com/panomix/products', '_blank');
              }}
            >
              <ButtonName>Request Demo</ButtonName>
            </Button>
            <ServiceOptionWrapper>
              {!isMobile ? <CheckIconSmallSVG /> : <CheckIconSVG />}
              Advanced Marketing Mix Models
            </ServiceOptionWrapper>
            <ServiceOptionWrapper>
              {!isMobile ? <CheckIconSmallSVG /> : <CheckIconSVG />}
              Marketing Analytics
            </ServiceOptionWrapper>
            <ServiceOptionWrapper>
              {!isMobile ? <CheckIconSmallSVG /> : <CheckIconSVG />}
              Simulations
            </ServiceOptionWrapper>
            <ServiceOptionWrapper>
              {!isMobile ? <CheckIconSmallSVG /> : <CheckIconSVG />}
              Data Integrations
            </ServiceOptionWrapper>
          </ServiceCard>
          <ServiceCard color='#AEDAFF'>
            <CardTitle>Consulting</CardTitle>
            <CardDescription>
              We offer a range of tailored options to address the diverse needs
              of businesses at various stages in ML analytics.
            </CardDescription>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.open('https://calendly.com/panomix/products', '_blank');
              }}
            >
              <ButtonName>Contact Us</ButtonName>
            </Button>
            <ServiceOptionWrapper>
              {!isMobile ? <CheckIconSmallSVG /> : <CheckIconSVG />}
              Data System Audit
            </ServiceOptionWrapper>
            <ServiceOptionWrapper>
              {!isMobile ? <CheckIconSmallSVG /> : <CheckIconSVG />}
              Data Architecture
            </ServiceOptionWrapper>
            <ServiceOptionWrapper>
              {!isMobile ? <CheckIconSmallSVG /> : <CheckIconSVG />}
              Adhoc Marketing Mix Modeling
            </ServiceOptionWrapper>
            <ServiceOptionWrapper>
              {!isMobile ? <CheckIconSmallSVG /> : <CheckIconSVG />}
              Custom ETL
            </ServiceOptionWrapper>
            <ServiceOptionWrapper>
              {!isMobile ? <CheckIconSmallSVG /> : <CheckIconSVG />}
              Pixel & eAPI Audit
              {isMobile && <TikTokPartnerSVG />}
            </ServiceOptionWrapper>
          </ServiceCard>
        </ServiceCardArea>
      </MainAreaWrapper>
      <OptionDetailWrapper>
        <OptionWrapper>
          <OptionTitle>Advanced MMM</OptionTitle>
          <OptionDivider />
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Multi Channel Measurements</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Automated Model Updates</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Hyperparameter Tunings</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Automated Model Enhancer</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Custom Context Variables</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Automated UTM Measurement</div>
          </OptionDetail>
        </OptionWrapper>
        <OptionWrapper>
          <OptionTitle>Marketing Analytics</OptionTitle>
          <OptionDivider />
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Automated Real-time Dashboards</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>ROAS Analytics</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Organic Channel Attributions</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Lifetime Model Performance</div>
          </OptionDetail>
        </OptionWrapper>
        <OptionWrapper>
          <OptionTitle>Simulations</OptionTitle>
          <OptionDivider />
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Campaign Budget Optimzer</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Media Mix Planner(Beta)</div>
          </OptionDetail>
        </OptionWrapper>
        <OptionWrapper>
          <OptionTitle>Data Integrations</OptionTitle>
          <OptionDivider />
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Marketing Platforms</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Analytics Tools</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Organic Channels</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>APIs & more</div>
          </OptionDetail>
        </OptionWrapper>
        <OptionWrapper>
          <OptionTitle>Data System Audit</OptionTitle>
          <OptionDivider />
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Data Source Evaluation</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Data Quality Assessment</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Data Governance & Security</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Data Analytics Capabilities</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Data Storage & Infrastructure</div>
          </OptionDetail>
        </OptionWrapper>
        <OptionWrapper>
          <OptionTitle>Data Architecture</OptionTitle>
          <OptionDivider />
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Data Architecture Assessment</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Modeling & Schema Design</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Data Warehouse Design</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Cloud Data Architecture</div>
          </OptionDetail>
        </OptionWrapper>
        <OptionWrapper>
          <OptionTitle>Adhoc MMM</OptionTitle>
          <OptionDivider />
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Data Collection & Preparation</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Variable Selection</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Model Development & Tuning</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Insights & Reporting</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Last Click vs MMP vs MMM</div>
          </OptionDetail>
        </OptionWrapper>
        <OptionWrapper>
          <OptionTitle>Custom ETL</OptionTitle>
          <OptionDivider />
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>KPI Source Evaluation</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Data Extraction</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Data Transformation</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Data Loading to Playbook</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Error Handling & Monitoring</div>
          </OptionDetail>
        </OptionWrapper>
        <OptionWrapper>
          <OptionTitle>Pixel & eAPI Audit</OptionTitle>
          <OptionDivider />
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Pixel Implementation Review</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Tracking Accuracy Analysis</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Event & Custom Parameter Audit</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>eAPI Integration Review</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Documentation & Support</div>
          </OptionDetail>
        </OptionWrapper>
        <OptionWrapper>
          <OptionTitle>Support</OptionTitle>
          <OptionDivider />
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Onboarding Support</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Technical Support (if applicable)</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Customer Service & Email Support</div>
          </OptionDetail>
          <OptionDetail>
            <CheckIconSmallSVG />
            <div>Documentations</div>
          </OptionDetail>
        </OptionWrapper>
      </OptionDetailWrapper>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  bottom: 40px;
  margin-top: 80px;
  margin-bottom: 100px;
  transition: all ease 4s;
  ${({ theme }) => theme.animation.fadeInBottom}
`;

const MainAreaWrapper = styled.div`
  height: auto;
  padding: 100px;
  background-color: #0b8ffc;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const HeadlineWrapper = styled.div`
  text-align: center;
  color: white;
  font-family: 'Poppins';
  font-size: 72px;
  line-height: 84px;
  font-weight: 500;
  width: 830px;
  margin-bottom: 24px;
  letter-spacing: -1px;

  @media (max-width: 743px) {
    font-size: 32px;
    line-height: 42px;
    width: 360px;
  }

  @media (min-width: 744px) {
    font-size: 48px;
    line-height: 54px;
    font-weight: 400;
  }
  @media (min-width: 1301px) {
    font-size: 72px;
    line-height: 84px;
    font-weight: 500;
    width: 830px;
  }
`;
const HeadlineDescription = styled.p`
  text-align: center;
  justify-content: center;
  color: white;
  font-family: 'Arial';

  @media (max-width: 743px) {
    font-size: 14px;
    line-height: 18px;
    width: 320px;
    margin-bottom: 40px;
  }

  @media (min-width: 744px) {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    width: 460px;
    margin-bottom: 40px;
  }
  @media (min-width: 1301px) {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    width: 630px;
    margin-bottom: 80px;
  }
`;

const ServiceCardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 80vw;

  @media (max-width: 743px) {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 744px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 80vw;
  }
  @media (min-width: 1301px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    width: 80vw;
  }
`;

const ServiceCard = styled.div`
  width: 100%;
  height: auto;
  background-color: ${({ color }) => (color ? color : 'white')};
  @media (max-width: 743px) {
    padding: 40px 25px 30px 25px;
  }

  @media (min-width: 744px) {
    padding: 50px 45px 60px 45px;
  }
  @media (min-width: 1301px) {
    padding: 50px 45px 60px 45px;
  }
`;

const CardTitle = styled.div`
  font-family: 'Poppins';
  font-size: 60px;
  line-height: 84px;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: -1px;

  @media (max-width: 743px) {
    font-size: 32px;
    line-height: 42px;
  }

  @media (min-width: 744px) {
    font-size: 48px;
    line-height: 60px;
  }
  @media (min-width: 1301px) {
    font-size: 60px;
    line-height: 84px;
  }
`;

const CardDescription = styled.div`
  font-family: 'Arial';
  font-size: 16px;
  line-height: 24px;
  height: auto;
  font-weight: regular;
  margin-bottom: 36px;

  @media (max-width: 743px) {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
  }

  @media (min-width: 744px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  @media (min-width: 1301px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 45px;
  margin-bottom: 40px;

  background-color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.25);
    transform: translateY(-2px);
    transition: 0.75s;
  }
`;

const ButtonName = styled.span`
  text-align: center;
  font-weight: 400;
  font-family: Poppins;
  font-size: 16px;
  color: white;

  @media (max-width: 743px) {
    font-size: 14px;
  }

  @media (min-width: 744px) {
    font-size: 16px;
  }
  @media (min-width: 1301px) {
    font-size: 16px;
  }
`;

const ServiceOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Poppins';
  font-size: 26px;
  font-weight: regular;
  margin-bottom: 24px;
  letter-spacing: -1px;

  @media (max-width: 743px) {
    font-size: 16px;
  }

  @media (min-width: 744px) {
    font-size: 22px;
  }
  @media (min-width: 1301px) {
    font-size: 26px;
  }
`;

const OptionDetailWrapper = styled.div`
  justify-content: space-between;
  grid-gap: 20px;
  width: 80vw;

  margin: 80px 0px 80px 0px;

  @media (max-width: 743px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  @media (min-width: 744px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }
  @media (min-width: 1301px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px;
  }
`;
const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionTitle = styled.div`
  font-family: 'Poppins';
  font-size: 26px;
  letter-spacing: -1px;
  color: '#1A1F27';
`;

const OptionDivider = styled.div`
  width: 320px;
  height: 1px;
  background-color: black;
  margin: 20px 0px 20px 0px;
`;
const OptionDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Arial;
  color: '#44505F';
  font-size: 18px;
  gap: 10px;
  margin-bottom: 18px;
`;
