import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PLAYBOOK_BUTTON_OPTION,
  LEARNMORE_BUTTON_OPTION,
  CONTACT_US_BUTTON_OPTION,
  REQUEST_DEMO_BUTTON_OPTION,
  SCHEDULE_DEMO_BUTTON_OPTION,
  AI_CHAT_BUTTON_OPTION,
  PLAYBOOK_LEARN_MORE,
  SWEN_LEARN_MORE,
  CONSULTING_LEARN_MORE,
  SOCIALBOT_LEARN_MORE,
  CUSTOM_LEARN_MORE,
  TIKTOK_LEARN_MORE,
  BOTPRESS_LEARN_MORE,
} from './BUTTON_OPTIONS';
import styled, { css } from 'styled-components';
import { LanguageContext } from '../../contextApi/LanguageContext';

export default function Button({ type, buttonColor, nameColor, isInNav }) {
  const { language } = useContext(LanguageContext);

  const BUTTON_TYPE = {
    playbook: PLAYBOOK_BUTTON_OPTION,
    learnMore: LEARNMORE_BUTTON_OPTION,
    contactUs: CONTACT_US_BUTTON_OPTION,
    requestDemo: REQUEST_DEMO_BUTTON_OPTION,
    scheduleDemo: SCHEDULE_DEMO_BUTTON_OPTION,
    askAI: AI_CHAT_BUTTON_OPTION,
    playbookLearnMore: PLAYBOOK_LEARN_MORE,
    swenLearnMore: SWEN_LEARN_MORE,
    consultingLearnMore: CONSULTING_LEARN_MORE,
    socialbotLearnMore: SOCIALBOT_LEARN_MORE,
    customLearnMore: CUSTOM_LEARN_MORE,
    tiktokLearnMore: TIKTOK_LEARN_MORE,
    botpressLearnMore: BOTPRESS_LEARN_MORE,
  };

  const navigate = useNavigate();

  const moveToOtherPage = (url) => {
    navigate(url);
  };

  const moveToOtherWeb = (url) => {
    window.open(url, '_blank');
  };
  const triggerBot = () => {
    window.botpressWebChat.sendEvent({
      type: 'trigger',
      payload: {},
    });
  };

  return (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (BUTTON_TYPE[type].type === 'moveToOtherPage') {
          moveToOtherPage(BUTTON_TYPE[type].url);
        } else if (BUTTON_TYPE[type].type === 'moveToOtherWeb') {
          moveToOtherWeb(BUTTON_TYPE[type].url);
        } else if (BUTTON_TYPE[type].type === 'triggerBot') {
          triggerBot();
        }
      }}
      isInNav={isInNav}
      buttonColor={buttonColor || BUTTON_TYPE[type]?.buttonColor}
    >
      <ButtonName
        nameColor={nameColor || BUTTON_TYPE[type]?.nameColor}
        isInNav={isInNav}
      >
        {BUTTON_TYPE[type]?.name[language]}
      </ButtonName>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isInNav }) =>
    isInNav
      ? css`
          width: 160px;
          height: 40px;
        `
      : css`
          @media (max-width: 425px) {
            width: 160px;
            height: 40px;
          }
          @media (min-width: 426px) {
            width: 160px;
            height: 40px;
          }
        `}

  background-color: ${({ buttonColor, theme }) =>
    buttonColor || theme.color.primary.blue};
  border: none;

  z-index: 2;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 3px 7px rgba(9, 16, 55, 0.25);
    transform: translateY(-2px);
    transition: 0.75s;
  }
`;

const ButtonName = styled.span`
  text-align: center;
  font-weight: 200;
  font-family: 'Arial';
  font-size: 16px;
  line-height: 24px;
  color: ${({ nameColor, theme }) => nameColor || theme.color.white};
`;
