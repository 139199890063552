import React from 'react';
import { OverviewContent } from './AIDetails/OverviewContent';
import { Cases } from './AIDetails/Cases';

export const AI_DATA = {
  title: {
    en: 'Power of GenAI Automation Stack',
    ko: '생성형AI 스택을 통한 업무 자동화',
  },
  titleDescription: {
    en: 'Combining multiple products & solutions to architect GenAI solutions such as customer service chatbot and automated content management system.',
    ko: '여러 제품과 솔루션을 결합하여 고객 서비스 챗봇 및 자동화된 콘텐츠 관리 시스템과 같은 GenAI 솔루션을 설계합니다.',
  },
  sections: [
    {
      key: 'overview',
      title: {
        en: 'Overview',
        ko: '개요',
      },
      content: { desktop: <OverviewContent /> },
    },
    {
      key: 'cases',
      title: {
        en: 'Cases',
        ko: '케이스',
      },
      content: { desktop: 'Coming Soon!' },
    },
  ],
};
