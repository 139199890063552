export const PRICING_DATA = {
  title: 'Grow with our traffic-based pricing.',
  pricingTiers: [
    {
      title: 'Data Consulting & Adhoc Modeling',
      description: 'From data system consulting to adhoc modeling',
      desc_list: [
        '1 or 2 Paid Marketing Channels',
        'Monthly Ad Spending < $10,000',
        'Organic Channels like Instagram',
      ],
      button: {
        type: 'contactUs',
      },
      benefits: {
        title: '$250 per month',
        subTitle: 'Or $2,700 per year',
        list: [
          '✔️ Channel ROI Simulation',
          '✔️ Campaign ROI Dashboard',
          '✔️ Organic Channel Dashboard',
          '✔️ OAuth 2.0 Data Sources',
        ],
      },
    },
    {
      title: 'Professional',
      description:
        'Medium to large brands with multi channel marketing strategies.',
      desc_list: [
        'More than 2 Paid Marketing Channels',
        'Monthly Ad Spending > $10,000',
        'Multiple Organic Channels',
      ],
      button: {
        type: 'requestDemo',
      },
      benefits: {
        title: '$2,500 per month',
        subTitle: 'Or $27,000 per year',
        list: [
          '✔️ Channel ROI Simulation',
          '✔️ Campaign ROI Dashboard',
          '✔️ Organic Channel Dashboard',
          '✔️ Campaign ROI Simulation',
          '✔️ Monthly Model Calibration',
          '✔️ OAuth 2.0 Data Sources',
          '✔️ Custom ETL (Limited to APIs)',
        ],
      },
    },
    {
      title: 'Enterprise',
      description:
        'Enterprises with multiple data silos and in needs of ongoing custom ML models.',
      desc_list: [
        'Complex Marketing Channels',
        'Monthly Ad Spending > $300,000',
        'Multiple Sales Channels',
      ],
      button: {
        type: 'contactUs',
      },
      benefits: {
        title: 'Custom Pricing',
        subTitle: ' ',
        list: [
          '✔️ Channel ROI Simulation',
          '✔️ Campaign ROI Dashboard',
          '✔️ Organic Channel Dashboard',
          '✔️ Campaign ROI Simulation',
          '✔️ Ongoing Model Calibration',
          '✔️ Data System Consulting',
          '✔️ Custom ETL (All types)',
          '✔️ Early Access to Beta Features',
          '✔️ Dedicated ML Engineer',
        ],
      },
    },
  ],
  pricingDetails: [
    {
      title: 'On-Demand Feature Pricings',
      notes: '1 Credit = $ 1.00',
      items: [
        { key: 'Media Mix Plan', value: '50 Credits' },
        { key: 'Clustering', value: '50 Credits' },
        {
          key: 'MMM',
          value: 'Model Performance(R²) x Predicted Savings($) x Coef',
        },
      ],
    },
  ],
};
