import React from 'react';
import styled from 'styled-components';

export default function EP02() {
  return (
    <>
      <TitleArea>Playbook's Dynamic Model</TitleArea>
      <Author>Author</Author>
      <AuthorName>Richard Kang, ML Engineer</AuthorName>
      <Divider />
      <Body>
        <SubTitle>Introduction</SubTitle>
        <BodyText>
          <div>
            Media mix modeling has historically been an essential service
            offered by consulting companies to help large clients optimize their
            marketing budgets and maximize their return on investment (ROI).
            However, the high cost of consulting services made it difficult for
            smaller companies to access these services.
          </div>
          <br></br>
          <div>
            With the rise of Software-as-a-Service (SaaS) platforms, media mix
            modeling has become more accessible to companies of all sizes.
            Playbook, a leading media mix modeling platform, has been at the
            forefront of this transformation, providing a user-friendly,
            cost-effective solution for companies looking to improve their
            marketing performance.
          </div>
          <br></br>
          <div>
            In the past, media mix modeling required significant investment in
            manual data entry and simple regression analysis. However, with the
            advent of SaaS-based platforms like Playbook, the process has become
            more automated and sophisticated.
          </div>
          <br></br>
          <div>
            One of the key features that distinguishes Playbook from other
            SaaS-based media mix modeling platforms is its Dynamic Model.
            Previously, if unseen channels were added or the model performance
            was poor, a new model would need to be manually created or refined.
            This process could be time-consuming, expensive, and prone to
            errors.
          </div>
          <br></br>
          <div>
            Playbook's Dynamic Model resolves these issues in three steps.
          </div>
        </BodyText>
        <SubTitle>Data Detection</SubTitle>
        <BodyText>
          <div>
            First, Dynamic Model allows Playbook to watch for any new form of
            data. If new marketing channels are detected or unseen data is
            introduced, Playbook automatically updates models to reflect the new
            channel’s performance, while preserving the values learned from
            previous channels.
          </div>
        </BodyText>
        <div alignitems='center'>
          <ItemImage
            src={require('./Figures/DataDetection.png')}
            alt='DataDetection'
          />
        </div>
        <SubTitle>Optimal Convergence</SubTitle>
        <BodyText>
          <div>
            Second, Dynamic Model examines the model and refines it if the model
            performance (fit) is considered mediocre. By standard, a model-fit
            score, which ranges from 0 to 1, is considered high if it is above
            0.8. A model can converge to a suboptimal local minima and obtain a
            lower model fit score for several reasons, such as the nonlinear
            geographic of the objective function. Playbook iteratively examines
            completed models to minimize nonoptimal convergence and guarantee
            the best possible results.
          </div>
        </BodyText>
        <div alignitems='center'>
          <ItemImage
            src={require('./Figures/OptimalConvergence.png')}
            alt='OptimalConvergence'
          />
        </div>
        <SubTitle>Model Recency</SubTitle>
        <BodyText>
          Third, Playbook performs automatic daily model updates to maintain
          model recency and performance. In a digital setting where agility is
          highly valued, these updates ultimately provide clients with both
          long-term and short-term actionable insights. In particular, clients
          can benefit from the real-time information on marketing performance.
          With Playbook, clients can quickly identify underperforming channels
          and adjust their marketing budgets accordingly, maximizing their ROI.
        </BodyText>
        <SubTitle>Conclusion</SubTitle>
        <BodyText>
          <div>
            Another way Playbook distinguishes itself from other SaaS-based
            media mix modeling platforms is through its user-friendly interface.
            Playbook makes it easy for marketers to create, manage, and
            visualize media mix models, even if they have limited statistical
            knowledge or experience.
          </div>
          <br></br>
          <div>
            Playbook also offers a broad range of pre-built integrations with
            various data sources, including Google Ads, Facebook Ads, and
            Organic Media Channels, such as Youtube and Instagram. This feature
            makes it easy for marketers to connect their data sources to
            Playbook and start analyzing marketing performance without spending
            time and resources on data integration.
          </div>
          <br></br>
          <div>
            In conclusion, the history of media mix modeling has been shaped by
            the evolution of technology, the proliferation of data, and the rise
            of SaaS-based platforms like Playbook. With its Dynamic Model and
            user-friendly interface, Playbook enables companies of all sizes to
            make data-driven decisions quickly and effectively, saving time and
            resources, and maximizing ROI.
          </div>
        </BodyText>
      </Body>
    </>
  );
}

const TitleArea = styled.div`
  font-family: 'Poppins';
  font-size: 72px;
  line-height: 84px;
  width: 600px;

  @media (max-width: 980px) {
    font-size: 48px;
    line-height: 54px;
    width: 360px;
  }
`;

const Author = styled.div`
  font-family: 'Arial';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-top: 80px;
`;

const AuthorName = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 24px;
`;
const Divider = styled.div`
  height: 1px;
  background-color: black;
  margin-top: 14px;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1440px) {
    margin: 80px 280px 80px 280px;
  }
  @media (max-width: 1439px) {
    margin: 80px 180px 80px 180px;
  }
  @media (max-width: 980px) {
    margin: 20px 10px 140px 10px;
  } ;
`;
const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  margin-top: 50px;
  margin-bottom: 10px;

  @media (max-width: 980px) {
    margin-top: 30px;
  }
`;

const BodyText = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 32px;
  margin-top: 10px;
`;
const ItemImage = styled.img`
  display: block;
  margin: 80px auto; /* Centers the image horizontally */
  max-width: 100%;
  height: auto;
  min-width: 600px;

  @media (max-width: 980px) {
    min-width: 300px;
    margin: 40px auto;
  }
`;
