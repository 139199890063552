import React, { useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../../contextApi/LanguageContext';
import Button from '../../../components/Button/Button';

export const OverviewContent = () => {
  const { language } = useContext(LanguageContext);
  const DATA = {
    sectionTitle: {
      en: 'Media Mix Modeling for advanced growth teams',
      ko: '가장 고도화된 미디어 믹스 모델링을 만나보세요',
    },
    sectionBody: {
      en: (
        <>
          <SectionBody>
            Playbook empowers data and growth teams to measure marketing
            campaigns through advanced media mix modeling. From data collection
            to modeling, all processes are automated on secure and safe cloud
            servers.
          </SectionBody>
          <br></br>
          <SectionBody>
            Explore your marketing data in unprecedented detail with Playbook's
            cross kpi, campaign-level analyses that are powered by novel ML
            modules.
          </SectionBody>
        </>
      ),
      ko: (
        <>
          <SectionBody>
            플레이북은 데이터 및 그로스 팀이 고급 미디어 믹스 모델링을 통해
            마케팅 캠페인의 성과를 측정할 수 있게 해줍니다. 데이터 수집에서
            모델링까지 모든 과정을 안전하고 안전한 클라우드 서버에서
            자동화합니다.
          </SectionBody>
          <br></br>
          <SectionBody>
            플레이북의 혁신적인 ML 모듈로 구동되는 크로스 KPI 및 캠페인 레벨의
            분석을 통해 마케팅 데이터를 세부 수준으로 탐색해보세요.
          </SectionBody>
        </>
      ),
    },
  };

  return (
    <>
      <SectionTitle>{DATA.sectionTitle[language]}</SectionTitle>
      <SectionBody>{DATA.sectionBody[language]}</SectionBody>
      <div style={{ marginTop: '40px' }}>
        <Button type='requestDemo' />
      </div>
      <Divider />
    </>
  );
};

const SectionTitle = styled.h2`
  font-size: 44px;
  line-height: 50px;
  font-family: 'Poppins';
  font-weight: 300;
  width: auto;
  max-width: 620px;
  color: black;
  margin-bottom: 40px;
  align-text: left;
`;

const SectionBody = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-family: Arial;
  width: auto;
  color: black;
  font-weight: 200;
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 100%;
  margin-right: 40px;
  margin-top: 80px;
  margin-bottom: 48px;
`;
