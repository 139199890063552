import React from 'react';
import styled from 'styled-components';

export default function EP05() {
  return (
    <>
      <TitleArea>Improving Performance</TitleArea>
      <Author>Author</Author>
      <AuthorName>Richard Kang, ML Engineer</AuthorName>
      <Divider />
      <Body>
        <SubTitle>Introduction</SubTitle>
        <BodyText>
          <div>
            When it comes to gauging the performance of Media Mix Modeling, the
            most accessible metrics are R-squared error, Normalized Root Mean
            Squared Error, and Decomposition Root Sum of Squared Distance,
            Meta's measurement of model-business fit, which serve as indicators
            of the model's inferential and predictive capabilities. Higher
            scores on these metrics imbue confidence in the model's predictive
            capabilities, reassuring stakeholders that the insights drawn are
            robust. Conversely, poor performance metrics cast doubt on the
            model's effectiveness, rendering its results unreliable. Given the
            potential impact on a business's marketing budget, ensuring peak
            model performance is no longer a choice—it's a necessity.
          </div>
        </BodyText>
        <SubTitle>Strategies for Elevated MMM Performance</SubTitle>
        <SubBodyTitle>Increase Trials and Iterations</SubBodyTitle>
        <BodyText>
          <div>
            In the realm of complex datasets, the journey to model convergence
            can be demanding. Larger and more intricate datasets often
            necessitate longer iterations to ensure the model's convergence on
            the optimal set of parameters. Cutting iterations short risks
            leaving the model under-trained, inhibiting its capacity to deliver
            accurate predictions. By increasing the number of trials and
            iterations, the model is better able to capture intricate
            relationships hidden within the data, thus elevating its performance
            and reliability.
          </div>
          <div alignitems='center'>
            <ItemImage
              style={{ maxWidth: '300px' }}
              src={require('./Figures/EP05-01.png')}
              alt='Convergence'
            />
          </div>
          <Comment>
            Increased iterations and trials increase the possibility of
            converging on the most optimal minima
          </Comment>
        </BodyText>
        <SubBodyTitle>Incorporate Additional Explanatory Data</SubBodyTitle>
        <BodyText>
          <div>
            If the model's performance remains unsatisfactory even after
            extending iterations, the root cause might be insufficient data. The
            essence of Media Mix Modeling lies in identifying relationships
            between media variables and response variables. However, if the
            variability in media variables fails to encapsulate the fluctuations
            in the response variable, the model's convergence becomes an arduous
            task. Collaborating with the team to scrutinize both internal and
            external events that could impact Key Performance Indicators (KPIs)
            is essential. A dataset that was overlooked by human bias could be
            the missing piece of variability that the model needed.
          </div>
        </BodyText>
        <SubBodyTitle>Break Down Larger Media Variables</SubBodyTitle>
        <BodyText>
          <div>
            While the previous strategies likely improved model performance,
            other methods of enhancement yet remain. Breaking down large media
            variables is a method that can be particularly relevant in the
            context of media advertisements. Different ads are tailored to
            achieve distinct objectives, from bolstering brand awareness to
            driving direct purchases. Given this diversity, the impact of $1
            spent on awareness-focused ads versus conversion-focused ads varies
            significantly. By dissecting media channels according to their
            objectives, the model gains access to a richer tapestry of
            variability. This, in turn, equips the model to learn and predict
            more accurately, ultimately elevating its effectiveness in
            deciphering the complex interplay of marketing strategies. A
            different strategy may be to separate the channel ads based on the
            placement. An ad that appears in Instagram Reels will likely have a
            different effect than an ad that appears in a niche Facebook Group
            feed. Different experiments of breaking down available data may help
            the model understand the different factors that affected the KPI.
          </div>
          <div alignitems='center'>
            <ItemImage
              style={{ maxWidth: '400px', margin: '40px auto' }}
              src={require('./Figures/EP05-02.png')}
              alt='Convergence'
            />
          </div>
          <Comment>
            Breaking down a channel may provide the learning model with the
            variance it needed
          </Comment>
        </BodyText>
        <SubTitle>Conclusion</SubTitle>
        <BodyText>
          <div>
            Media Mix Modeling is a potent tool that empowers businesses with
            invaluable insights to drive marketing decisions. However, the
            reliability of these insights hinges on the model's performance.
            Elevating MMM performance involves a multifaceted approach, from
            ensuring sufficient trials and iterations to incorporating
            additional exploratory data and breaking down larger media
            variables. Running these experiments may seem daunting, but the
            powerful results that stem from a proper execution are undoubtedly
            worth it.
          </div>
        </BodyText>
      </Body>
    </>
  );
}

const TitleArea = styled.div`
  font-family: 'Poppins';
  font-size: 72px;
  line-height: 84px;
  width: 600px;

  @media (max-width: 980px) {
    font-size: 48px;
    line-height: 54px;
    width: 360px;
  }
`;

const Author = styled.div`
  font-family: 'Arial';
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-top: 80px;
`;

const AuthorName = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 24px;
`;
const Divider = styled.div`
  height: 1px;
  background-color: black;
  margin-top: 14px;
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1440px) {
    margin: 80px 280px 80px 280px;
  }
  @media (max-width: 1439px) {
    margin: 80px 180px 80px 180px;
  }
  @media (max-width: 980px) {
    margin: 20px 10px 140px 10px;
  } ;
`;

const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  margin-top: 50px;
  margin-bottom: 10px;

  @media (max-width: 980px) {
    margin-top: 30px;
  }
`;

const SubBodyTitle = styled.div`
  font-family: 'Arial';
  font-size: 22px;
  line-height: 34px;
  font-weight: bold;
  margin: 24px 0px 14px 0px;
`;

const BodyText = styled.div`
  font-family: 'Arial';
  font-size: 18px;
  line-height: 32px;
  margin-top: 10px;
`;

const Comment = styled.div`
  font-family: 'Arial';
  font-size: 14px;
  text-align: center;
  color: gray;
`;

const ItemImage = styled.img`
  display: block;
  margin: 0px auto; /* Centers the image horizontally */
  max-width: 100%;
  height: auto;
  min-width: 500px;

  @media (max-width: 980px) {
    min-width: 300px;
    margin: 40px auto;
  }
`;
