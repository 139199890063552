import React, { useState, useEffect, createContext } from 'react';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    // Detect user's browser language
    const userLanguage = (navigator.language || navigator.userLanguage).split(
      '-'
    )[0]; // Get the first part of the language code
    if (userLanguage === 'ko') {
      setLanguage('ko');
    } else return;
  }, []);

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider, LanguageContext };
