import React, { useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../../contextApi/LanguageContext';

export const ModelMaintenanceMobile = () => {
  const { language } = useContext(LanguageContext);
  const DATA = {
    sectionTitle: {
      en: 'Model Maintenance',
      ko: '모델 메인터넌스',
    },
    sectionBody: {
      en: (
        <>
          <SectionGrid>
            <div>
              <SectionGridTitle>AI Tuned Parameters</SectionGridTitle>
              <SectionGridDescription>
                AI powered algorithm tunes hyperparameters to account for the
                different characteristics of various media channels.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Model Anomaly Detection</SectionGridTitle>
              <SectionGridDescription>
                Each model is inspected to prevent the model from optimizing on
                an unwanted local minima.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Model Performance Improvement</SectionGridTitle>
              <SectionGridDescription>
                Subpar results go through supplementary procedures such as
                variable manipulations or model modifications to ensure each
                model is performing at its capacity.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Systematic Status Checks</SectionGridTitle>
              <SectionGridDescription>
                Playbook Monitor runs status checks across all models,
                confirming the goodness of both inputs and outputs, to establish
                integrity of the consequential data.
              </SectionGridDescription>
            </div>
          </SectionGrid>
        </>
      ),
      ko: (
        <>
          <SectionGrid>
            <div>
              <SectionGridTitle>AI Tuned Parameters</SectionGridTitle>
              <SectionGridDescription>
                AI 기반 알고리즘이 각 매체의 다양성을 고려해 파라미터 튜닝을
                이뤄 모델의 안정성을 높입니다.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Model Anomaly Detection</SectionGridTitle>
              <SectionGridDescription>
                각 모델은 세심한 점검을 통해 잘못된 국소 최저치에 최적화되지
                않습니다.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Model Performance Improvement</SectionGridTitle>
              <SectionGridDescription>
                기준에 미치지 못한 모델들은 플레이북만의 보충작업을 통해
                퍼포먼스를 증강시켜 주어진 데이터로 최적의 결과가 나올 수 있게
                합니다.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Data Coherence</SectionGridTitle>
              <SectionGridDescription>
                실시간 모니터링을 통해 각 모델의 퍼포먼스를 확인하고 도출된
                결과를 점검합니다.
              </SectionGridDescription>
            </div>
          </SectionGrid>
        </>
      ),
    },
  };

  return (
    <>
      <SectionTitle>{DATA.sectionTitle[language]}</SectionTitle>
      <SectionBody>{DATA.sectionBody[language]}</SectionBody>
      <Divider />
    </>
  );
};

const SectionTitle = styled.h2`
  font-size: 44px;
  line-height: 50px;
  font-family: 'Poppins';
  font-weight: 300;
  width: auto;
  max-width: 680px;
  color: black;
  margin-bottom: 40px;
  align-text: left;
`;

const SectionBody = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-family: Arial;
  width: auto;
  color: black;
  font-weight: 200;
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 100%;
  margin-right: 40px;
  margin-top: 80px;
  margin-bottom: 48px;
`;

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 4fr);
  grid-gap: 40px;
  margin-top: 40px;
`;

const SectionGridTitle = styled.div`
  font-size: 22px;
  line-height: 32px;
  font-family: Arial;
  width: auto;
  color: black;
  font-weight: bold;
`;

const SectionGridDescription = styled.div`
  font-size: 16px;
  font-family: Arial;
  line-height: 26px;
  max-width: 480px;
  color: black;
  margin-top: 10px;
`;
