import React from 'react';
import styled from 'styled-components';

export default function Terms() {
  return (
    <Wrapper>
      <Headline>Panomix Terms of Service</Headline>
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Use of Service
      </span>
      <br />
      Service is the web-based platform designed by Panomix Inc which helps you
      to automate and optimize advertising campaigns and maximize the efficiency
      of advertisements. In order to start using the Service you must create an
      account and provide us with a desirable level of access to the list of
      your advertising accounts, the list of managed pages, statistic data of
      your advertising campaigns, including ad impressions, site visits, site
      activities, advertising, account settings.
      <br />
      <br />
      We do not transfer your data to third parties, but reserve the right to
      use anonymous data on your statistics for aggregation with other data and
      training our algorithms.
      <br />
      <br />
      YOU ARE ENTITLED TO ACCESS, NAVIGATE AND USE THE SOFTWARE SOLELY FOR THE
      PURPOSES OF USING THE SERVICE AND OBLIGATIONS UNDER THIS AGREEMENT. YOU
      MAY NOT REVERSE ENGINEER, TAKE SCREENSHOTS OF, COPY, SELL, REDISTRIBUTE,
      LICENSE, OR TRANSFER THE SOFTWARE, OR REPRODUCTIONS OF THE SOFTWARE TO
      OTHER PARTIES IN ANY WAY.
      <br />
      <br />
      In your use of the Product and/or Services, you warrant that you will at
      all times comply with the applicable policies of third party platform
      provider (as applicable), including:
      <br />
      <br />
      comply with the advertising policy of Facebook available at
      https://www.facebook.com/ad_guidelines.php and as updated from time to
      time,
      <br />
      comply with the Facebook terms of use available at
      https://www.facebook.com/legal/terms and as updated from time to time,
      <br />
      comply with the Instagram terms of use available at
      https://www.instagram.com/about/legal/terms/api and as updated from time
      to time,
      <br />
      comply with the advertising policy of Google AdWords available at
      https://support.google.com/adwordspolicy/ and as updated from time to
      time,
      <br />
      comply with the Google AdWords terms of use available at
      https://billing.google.com/payments/termsandconditionsfinder (as
      applicable to your country) and as updated from time to time,
      <br />
      <br />
      authorize Facebook, Instagram, and Google AdWords to make use of any
      material or content included in the ads in connection with your ad
      campaign as stated in each provider’s terms of use. Panomix reserves the
      right, without liability to you, to terminate these Terms or disable your
      access to the Product if you breach the provisions of Facebook, Instagram
      and/or Google’s policies as stated herein.
      <br />
      <br />
      You will use all reasonable endeavors to prevent any unauthorized access
      to, or use of, the Product and, in the event of any such unauthorized
      access or use, promptly notify Panomix.
      <br />
      <br />
      Panomix is constantly innovating in order to provide the best possible
      experience for its users. You acknowledge and agree that the form and
      nature of the Service which Panomix provides may change from time to time
      without prior notice to you.
      <br />
      <br />
      As part of this continuing innovation, you acknowledge and agree that
      Panomix may stop (permanently or temporarily) providing the Service (or
      any features within the Service) to you or to users generally at Panomix's
      sole discretion, without prior notice to you. You may stop using the
      Service at any time. You do not need to specifically inform Panomix when
      you stop using the Service.
      <br />
      <br />
      You agree that you are solely responsible for (and that Panomix has no
      responsibility to you or to any third party for) any breach of your
      obligations under the Terms and for the consequences (including any loss
      or damage which Panomix may suffer) of any such breach.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Privacy
      </span>
      <br />
      For information about how we collect, use, and share the date we collect
      from and about you, please see our Privacy Policy, a current copy of which
      can be found at (privacy page link)
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Confidentiality
      </span>
      <br />
      Neither Party shall disclose any Confidential Information to any third
      party other than to its Personnel, employees, agents and/or independent
      contractors to whom disclosure is reasonably required provided that such
      individuals and entities have agreed, under an executed agreement, to keep
      such information confidential in the same or a substantially similar
      manner as provided for in these Terms. Neither Party will use any
      Confidential Information except as expressly permitted by, or as required
      to achieve the purposes of these Terms. Each Party will take reasonable
      security precautions to protect and safeguard the Confidential Information
      of the Disclosing Party against any unauthorized use, disclosure, transfer
      or publication, with at least the same degree of care and precaution as it
      uses to protect its own Confidential Information of a similar nature, but
      in no event with less than reasonable care. Either Party shall notify the
      other Party upon discovery of any unauthorized use or disclosure of the
      Confidential Information and take reasonable steps to regain possession of
      the Confidential Information and prevent further unauthorized actions or
      breach of these Terms. Nothing in this Agreement shall prevent a Receiving
      Party from disclosing Confidential Information it received hereunder
      pursuant to a binding order of a government agency or a court, provided
      that the Receiving Party (a) notifies the Disclosing Party of such release
      or disclosure with as much notice as reasonably possible so that such
      Disclosing Party may seek a protective order or other appropriate remedy;
      and (b) uses reasonable efforts to limit such release or disclosure only
      to the extent required. The Parties acknowledge that monetary damages may
      not be a sufficient remedy for unauthorized disclosure of Confidential
      Information and agree that the non-breaching Party shall be entitled,
      without waiving any other rights or remedies, to such injunctive or
      equitable relief as may be deemed proper by a court of competent
      jurisdiction. The provisions of this section shall continue in force for a
      period of five (5) years following the termination or expiration of these
      Terms.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Account
      </span>
      <br />
      To access the Service, you must register an account by providing your
      email address and any optional personal information that you elect to
      associate with your account or that your privacy settings on your Social
      Media account permit us to access. You agree to provide us with accurate,
      complete, and current registration information about yourself. It is your
      responsibility to ensure that your password remains confidential and
      secure. By registering, you agree that you are fully responsible for all
      activities that occur under your username and password. We may assume that
      any communications we receive under your account have been made by you.
      <br />
      <br />
      You are responsible for notifying us at info@panomix.io if you become
      aware of any unauthorized use of or access to your account. You understand
      and agree that we may require you to provide information that may be used
      to confirm your identity and help ensure the security of your account.
      Panomix will not be liable for any loss, damages, liability, expenses or
      attorneys’ fees that you may incur as a result of someone else using your
      password or account, either with or without your knowledge and/or
      authorization, and regardless of whether you have or have not advised us
      of such unauthorized use. You will be liable for losses, damages,
      liability, expenses and attorneys’ fees incurred by Panomix Inc. or a
      third party due to someone else using your account. If you wish to delete
      your Account you may send an email request to Panomix at info@panomix.io.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Subscription and Payment
      </span>
      <br />
      By subscribing to the Service and providing billing information, you agree
      to payment terms and condition. You agree to pay Panomix for any confirmed
      expenses made in connection with your Panomix Account in accordance with
      these Terms by one of the methods described on the Site or Application,
      e.g. by Stripe. You hereby authorize the collection of such amounts by
      charging the credit card provided as part of requesting purchase via a
      third-party online payment processor, Stripe.
      <br />
      <br />
      Panomix, at its sole discretion, may make promotional offers with
      different features and different pricing to any of Panomix’s customers.
      These promotional offers, unless made to you, will not apply to your offer
      or these Terms.
      <br />
      <br />
      Panomix’s invoice will consist of your advertising spending, which Panomix
      pays to advertising platforms, and a percentage based service fee of the
      amount spent on your advertising. Fees will be charged by the terms
      described on the Site (https://panomix.io/). In case you stop a campaign
      in the middle of your schedule or remove your payment method, any
      outstanding balance will be settled immediately. You will also be billed
      for your usage of optional features and functionalities. Fees for optional
      features and functionalities are provided in the account interface at the
      time such features are utilized.
      <br />
      <br />
      Panomix may revise fee rates and/or the billable amount structure for the
      Service from time to time and will provide you with email notice of any
      changes in fees at least thirty (30) days prior. You are responsible for
      providing complete and accurate billing information to Panomix Inc and for
      all taxes. Panomix Inc will charge tax when required to do so by law.
      <br />
      <br />
      Panomix's fees are exclusive of all taxes, levies, or duties imposed by
      taxing authorities, and you shall be responsible for payment of all such
      taxes, levies, or duties. Panomix reserves the right to suspend the
      Services if you are late in settling payments for remuneration. Late
      invoices are subject to interest of 1.5% per month on any outstanding
      balance, or the maximum rate permitted by law, whichever is less, plus all
      expenses of collection. You will continue to be charged for the Services
      during any period of suspension.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Intellectual Property
      </span>
      <br />
      The Service contain materials (including all software, design, text,
      editorial materials, informational text, photographs, illustrations, audio
      clips, video clips, artwork and other graphic materials, and names, logos,
      trademarks and services marks) which are derived in whole or in part from
      materials supplied by Panomix, and is protected copyright laws,
      international treaty provisions, trademarks, service marks and other
      intellectual property laws. You agree to abide by all applicable copyright
      and other laws, as well as any additional copyright notices or
      restrictions contained in the Service. You acknowledge that the Service
      have been developed, compiled, prepared, revised, selected, and arranged
      by Panomix. You agree to protect the proprietary rights of Panomix and all
      others having rights in the Service during and after the term of these
      Terms. You agree to notify Panomix immediately upon becoming aware of any
      claim that the Service infringe upon any copyright, trademark, or other
      contractual, statutory, or common law rights. All present and future
      rights in and to trade secrets, patents, copyrights, trademarks, service
      marks, know-how, and other proprietary rights of any type under the laws
      of any governmental authority, domestic or foreign, including without
      limitation rights in and to all applications and registrations relating to
      the Service shall, as between you and Panomix, at all times be and remain
      the sole and exclusive property of Panomix.
      <br />
      <br />
      Any unauthorized use of any material contained on or through the Services
      may violate copyright laws, trademark laws, the laws of privacy and
      publicity and communications regulations and statutes. As part of
      Panomix's Intellectual Property policy, Panomix will terminate user access
      to the Service if a user has been determined to be a repeat infringer. A
      repeat infringer is a user who has been notified of infringing activity
      more than twice.
      <br />
      <br />
      Use of Content: Content on the Site is provided to you for your
      information and personal use only and may not be used, modified, copied,
      distributed, transmitted, broadcasted, displayed, sold, licensed,
      de-compiled, or otherwise exploited for any other purposes whatsoever
      without our prior written consent. If you download or print a copy of the
      Content you must retain all copyright and other proprietary notices
      contained therein.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        User Submissions
      </span>
      <br />
      Responsibility: The Site may permit the submission, hosting, sharing and
      publishing of Content by you and other users ("User Submissions"). You
      understand that whether or not such User Submissions are published, we do
      not guarantee any confidentiality with respect to any User Submissions.
      You shall be solely responsible for your User Submissions and the
      consequences of posting, publishing or uploading them. We have complete
      discretion whether to publish your User Submissions and we reserve the
      right in our sole discretion and without further notice to you, to
      monitor, censor, edit, remove, delete, and/or remove any and all Content
      posted on the Site (including User Submissions) at any time and for any
      reason.
      <br />
      <br />
      Ownership: You represent and warrant that you own or have the necessary
      rights and permissions to use and authorize Panomix to use all
      Intellectual Property Rights (defined below) in and to your User
      Submissions, and to enable inclusion and use thereof as contemplated by
      the Site and these Terms. Unless the User Submissions are separately
      referred to, all references herein to Content shall include references to
      User Submissions. "Intellectual Property Rights" means any and all rights,
      titles and interests, whether foreign or domestic, in and to any and all
      trade secrets, patents, copyrights, service marks, trademarks, know-how,
      or similar intellectual property rights, as well as any and all moral
      rights, rights of privacy, publicity and similar rights of any type under
      the laws or regulations of any governmental, regulatory, or judicial
      authority, foreign or domestic. You retain all of your ownership rights in
      and to your User Submissions.
      <br />
      <br />
      License to User Submissions: By submitting the User Submissions to
      Panomix, you hereby grant Panomix a worldwide, irrevocable, non-exclusive,
      royalty-free, perpetual, sub-licenseable and transferable license to use,
      reproduce, distribute, prepare derivative works of display, and perform
      the User Submissions in connection with the Site and Panomix's business,
      including without limitation for redistributing part or all of your User
      Submissions (and derivative works thereof) in any media formats and
      through any media channels and, and you hereby waive any moral rights in
      your User Submissions, to the extent permitted by law. You also hereby
      grant each user of the Site or other viewer or user of the User Submission
      a non-exclusive right to use, reproduce, distribute, prepare derivative
      works of, display and perform such User Submissions, all in accordance
      with these Terms.
      <br />
      <br />
      Prohibited Content: You agree that you will not display, post, submit,
      publish, upload or transmit a User Submission that: (i) is unfair or
      deceptive under the consumer protection laws of any jurisdiction; (ii) is
      copyrighted, protected by trade secret or otherwise subject to third party
      proprietary rights, including privacy and publicity rights, unless you are
      the owner of such rights; (iii) creates a risk to a person’s safety or
      health, creates a risk to public safety or health, compromises national
      security, or interferes with an investigation by law enforcement;
      (iv)impersonates another person; (v) promotes illegal drugs, violates
      export control laws, relates to illegal gambling, or illegal arms
      trafficking; (vi) is unlawful, defamatory, libelous, threatening,
      pornographic, harassing, hateful, racially or ethnically offensive, or
      encourages conduct that would be considered a criminal offense, gives rise
      to civil liability, violates any law, or is inappropriate; (vii) involves
      theft or terrorism; or (viii) is otherwise malicious or fraudulent.
      <br />
      <br />
      Also, Ads platforms do not allow or have restrictions over certain kinds
      of advertising. If your campaign has one of those contents, Panomix will
      not be able to broadcast your advertisements. Please check out the links
      below and make sure your campaign does not fall into one of those
      categories that may not be approved by the ads networks.
      <br />
      <br />
      Google Advertising Policy: https://support.google.com/adspolicy/
      <br />
      Facebook Advertising Policy: https://www.facebook.com/policies/ads
      <br />
      Instagram Advertising Policy: https://help.instagram.com/1554245014870700
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Links to other web sites
      </span>
      <br />
      Third Party Providers: You acknowledge that the Service will enable or
      assist you to access the website content of, correspond with, manage your
      advertising from Facebook, Instagram, Google or other third parties via
      third-party websites, and you do so solely at its own risk. Panomix makes
      no representation or commitment and shall have no liability or obligation
      whatsoever in relation to the content or use of, or correspondence with,
      any such third-party website, or any transactions completed, and any
      contract entered into by you with any such third party. Any contract
      entered into and any transaction completed via any third-party website is
      between you and the relevant third party, and not Panomix. Panomix
      recommends that you refer to the third party’s website terms and
      conditions and privacy policy prior to using the relevant third-party.
      <br />
      <br />
      Panomix permits you to link to the Site provided that: (i) you link to but
      do not replicate any page on this Site; (ii) the hyperlink text shall
      accurately describe the Content as it appears on the Site; (iii) you shall
      not misrepresent your relationship with Panomix or present any false
      information about Panomix and shall not imply in any way that we are
      endorsing any services or products, unless we have given you our express
      prior consent; (iv) you shall not link from a website ("Third Party
      Website") which prohibited linking to third parties; (v) such Third party
      Website does not contain content that (a) is offensive or controversial
      (both at our discretion), or (b) infringes any intellectual property,
      privacy rights, or other rights of any person or entity; and/or (vi) you,
      and your website, comply with these Terms and applicable law.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Warranty Disclaimers
      </span>
      <br />
      This section applies whether or not the services provided under the Site
      are for payment. Applicable law may not allow the exclusion of certain
      warranties, so to that extent certain exclusions set forth herein may not
      apply.
      <br />
      <br />
      THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, AND WITHOUT
      WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. PANOMIX HEREBY DISCLAIMS
      ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
      WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE,
      NON-INFRINGEMENT, AND THOSE ARISING BY STATUTE OR FROM A COURSE OF DEALING
      OR USAGE OF TRADE. PANOMIX DOES NOT GUARANTEE THAT THE SITE WILL BE FREE
      OF BUGS, SECURITY BREACHES, OR VIRUS ATTACKS. THE SITE MAY OCCASIONALLY BE
      UNAVAILABLE FOR ROUTINE MAINTENANCE, UPGRADING, OR OTHER REASONS. YOU
      AGREE THAT PANOMIX WILL NOT BE HELD RESPONSIBLE FOR ANY CONSEQUENCES TO
      YOU OR ANY THIRD PARTY THAT MAY RESULT FROM TECHNICAL PROBLEMS OF THE
      INTERNET, SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER
      SERVERS.WE DO NOT WARRANT, ENDORSE OR GUARANTEE ANY CONTENT, PRODUCT, OR
      SERVICE THAT IS FEATURED OR ADVERTISED ON THE SITE BY A THIRD PARTY.
      <br />
      <br />
      PANOMIX DOES NOT WARRANT, ENDORSE OR GUARANTEE ANY CONTENT THAT APPEARS IN
      A USER SUBMISSION, AND DOES NOT MAKE ANY REPRESENTATION OR WARRANTY WITH
      RESPECT TO, AND DISCLAIMS ALL LIABILITY FOR, ANY SUCH CONTENT.
      <br />
      <br />
      YOU SPECIFICALLY ACKNOWLEDGE THAT PANOMIX SHALL NOT BE RESPONSIBLE FOR THE
      USER SUBMISSIONS OR CONDUCT (INCLUDING DEFAMATORY, OFFENSIVE, ILLEGAL, OR
      NEGLIGENT CONDUCT) OF ANY SITE USER AND THAT THE RISK OF HARM OR DAMAGE
      FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
      <br />
      <br />
      YOUR RELIANCE ON, OR USE OF, ANY USER SUBMISSION, OR INTERACTION WITH ANY
      SITE USER OR OWNER, IS AT YOUR SOLE RISK. IF YOU HAVE A DISPUTE WITH ANY
      SITE USER OR OWNER IN CONNECTION WITH THE SITE OR ANY USER SUBMISSION, YOU
      AGREE THAT PANOMIX IS NOT LIABLE FOR ANY CLAIMS OR DAMAGES ARISING OUT OF
      OR CONNECTED WITH SUCH A DISPUTE. PANOMIX RESERVES THE RIGHT, BUT HAS NO
      OBLIGATION, TO MONITOR ANY SUCH DISPUTE.
      <br />
      <br />
      EXCEPT AS EXPRESSLY STATED IN OUR PRIVACY POLICY, PANOMIX DOES NOT MAKE
      ANY REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, EXPRESS OR
      IMPLIED, AS TO THE SECURITY OF ANY INFORMATION YOU MAY PROVIDE OR
      ACTIVITIES YOU ENGAGE IN DURING THE COURSE OF YOUR USE OF THE SITE.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Limitation of Liability
      </span>
      <br />
      TO THE FULLEST EXTENT PERMISSIBLE BY LAW, PANOMIX SHALL NOT BE LIABLE FOR
      ANY DIRECT, INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, OR INCIDENTAL
      DAMAGES OF ANY KIND, OR FOR ANY LOSS OF DATA, REVENUE, PROFITS OR
      REPUTATION, ARISING UNDER THESE TERMS OR OUT OF YOUR USE OF, OR INABILITY
      TO USE, THE SITE, EVEN IF PANOMIX HAS BEEN ADVISED OF THE POSSIBILITY OF
      SUCH DAMAGES OR LOSSES. Some jurisdictions do not allow the limitation or
      exclusion of liability for incidental or consequential damages, so the
      above limitations may not apply to you.
      <br />
      <br />
      IN NO EVENT SHALL THE AGGREGATE LIABILITY OF PANOMIX FOR ANY DAMAGES
      ARISING UNDER THESE TERMS OR OUT OF YOUR USE OF, OR INABILITY TO USE, THE
      SITE EXCEED THE TOTAL AMOUNT OF FEES, IF ANY, PAID BY YOU TO PANOMIX FOR
      USING THE SITE DURING THE THREE (3) MONTHS PRIOR TO BRINGING THE CLAIM.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Indemnity
      </span>
      <br />
      You agree to defend, indemnify and hold harmless Panomix and our
      affiliates, and our respective officers, directors, employees and agents,
      from and against any and all claims, damages, obligations, losses,
      liabilities, costs and expenses (including but not limited to attorney's
      fees) arising from: (i) your use of, or inability to use, the Site; (ii)
      your User Submissions; (iii) your interaction with any Site user; or
      (iv)your violation of these Terms.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Terms and Termination
      </span>
      <br />
      These Terms are effective until terminated by Panomix or you. Panomix in
      its sole discretion, has the right to terminate these Terms and/or your
      access to the Site, or any part thereof, immediately at any time and with
      or without cause (including, without any limitation, for a breach of these
      Terms). Panomix shall not be liable to you or any third party for
      termination of the Site, or any part thereof. If you object to any term or
      condition of these Terms, or any subsequent modifications thereto, or
      become dissatisfied with the Site in any way, your only recourse is to
      immediately discontinue use of the Site. Upon termination of these Terms,
      you shall cease all use of the Site. Sections 2 (Privacy),
      3(Confidentiality) and 6 (Intellectual Property) to 16 (General) shall
      survive termination of these Terms.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Independent Contractors
      </span>
      <br />
      You and Panomix are independent contractors. Nothing in these Terms
      creates a partnership, joint venture, agency, or employment relationship
      between you and Panomix. You must not under any circumstances make, or
      undertake, any warranties, representations, commitments or obligations on
      behalf of Panomix.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Assignment
      </span>
      <br />
      These Terms, and any rights and licenses granted hereunder, may not be
      transferred or assigned by you but may be assigned by Panomix without
      restriction or notification to you.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        Modification
      </span>
      <br />
      We reserve the right, at our sole discretion, to modify or replace these
      Terms at any time. Such change will be effective (NUMBER) days following
      posting of the revised Terms on the Site, and your continued use of the
      Site thereafter means that you accept those changes.
      <br />
      <br />
      <br />
      <span style={{ fontWeight: '600', fontSize: '16px', color: 'gray' }}>
        General
      </span>
      <br />
      Panomix reserves the right to discontinue or modify any aspect of the Site
      at any time. These Terms and the relationship between you and Panomix
      shall be governed by and construed in accordance with the laws of the
      country where we are located in, without regard to its principles of
      conflict of laws. You agree to submit to the personal and exclusive
      jurisdiction of the courts located in the city where our headquarter is
      located in and waive any jurisdictional, venue, or inconvenient forum
      objections to such courts, provided that Panomix may seek injunctive
      relief in any court of competent jurisdiction. These Terms shall
      constitute the entire agreement between you and Panomix concerning the
      Site. If any provision of these Terms is deemed invalid by a court of
      competent jurisdiction, the invalidity of such provision shall not affect
      the validity of the remaining provisions of these Terms, which shall
      remain in full force and effect. No waiver of any term of these Terms
      shall be deemed a further or continuing waiver of such term or any other
      term, and a party's failure to assert any right or provision under these
      Terms shall not constitute a waiver of such right or provision.
      <br />
      <br />
      YOU AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR
      RELATED TO THE SITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
      ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
      <br />
      <br />
      ⓒ Panomix. All rights reserved.
      <br />
      <br />
    </Wrapper>
  );
}

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0px 30px;
  @media (min-width: 600px) {
    padding: 0px 120px;
  }

  font-size: 14px;
  font-family: Poppins;
  color: gray;

  word-break: break-word;
  word-wrap: break-word;
  text-align: left;
`;

const Headline = styled.h3`
  color: gray;
  font-family: Poppins;
  text-align: center;
  font-size: 30px;
  letter-spacing: -2px;
  @media (min-width: 600px) {
    font-size: 40px;
  }
  margin-bottom: 30px;
`;
