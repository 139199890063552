export const COPYRIGHT = 'Panomix © 2024. All Rights Reserved.';

export const TERMS_AND_POLYCY = [
  {
    name: 'Privacy Policy',
    link: '/privacy',
  },
  {
    name: 'Cookie Policy',
    link: '/cookie',
  },
  {
    name: 'Terms & Conditions',
    link: '/terms',
  },
];
