import { css } from 'styled-components';

const theme = {
  color: {
    primary: {
      blue: '#0B8FFC',
      green: '#05ed94',
    },

    secondary: {
      blue: '#17BAED',
      black: '#000000',
      white: '#ffffff',
    },

    others: {
      red: '#FF2869',
      purple: '#8F34FF',
      lightBlue: '#71EDFF',
      lightGreen: '#B9F489',
      yellow: '#FFED4E',
    },

    neutral: {
      spaceGray1: '#1A1F27',
      spaceGray2: '#242B35',
      spaceGray3: '#44505F',
      spaceGray4: '#88909B',
    },
  },
  animation: {
    fadeInBottom: css`
      animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

      @keyframes fade-in-bottom {
        0% {
          transform: translateY(50px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    `,
    infiniteLoop: css`
      @keyframes slide {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100%);
        }
      }
      animation: slide 20s linear infinite;
    `,
  },
};

export default theme;
