import React, { useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../../contextApi/LanguageContext';

export const FeatureContent = () => {
  const { language } = useContext(LanguageContext);
  const DATA = {
    sectionTitle: {
      en: 'Features',
      ko: '주요 기능',
    },
    sectionBody: {
      en: (
        <>
          <SectionGrid>
            <div>
              <SectionGridTitle>Forecast</SectionGridTitle>
              <SectionGridDescription>
                Campaign Forecast transcends traditional MMM’s limitations of
                channel-level simulation, granting users the ability to simulate
                at the campaign and ad group level.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Impact Analysis</SectionGridTitle>
              <SectionGridDescription>
                Cross-KPI Impact provides a comprehensive understanding of
                marketing efforts by analyzing a single campaign’s effect on all
                Key Performance Indicators (KPI) of interest.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Playbook Monitor</SectionGridTitle>
              <SectionGridDescription>
                Playbook Monitor executes daily model updates and examines the
                performance of every model. This encourages models to be
                contextually acceptable and consistent, setting the foundation
                for Campaign Contribution Analysis and Forecast.
              </SectionGridDescription>
            </div>
          </SectionGrid>
        </>
      ),
      ko: (
        <>
          <SectionGrid>
            <div>
              <SectionGridTitle>Forecast</SectionGridTitle>
              <SectionGridDescription>
                기존 MMM의 한계를 극복하다. 플레이북은 매체 시뮬레이션이 아닌
                캠페인과 애드 그룹 단위의 시뮬레이션을 제공해 더 세세한 단위에서
                비용을 조절할 수 있습니다.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Impact Analysis</SectionGridTitle>
              <SectionGridDescription>
                한 매체가 한 KPI에 미치는 영향을 측정하는 것을 넘어 각 매체의
                캠페인과 애드그룹이 모든 KPI에 미치는 영향을 측정할 수 있습니다.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Playbook Monitor</SectionGridTitle>
              <SectionGridDescription>
                플레이북 모니터는 모델 업데이트를 규칙적으로 실행하고 각 모델을
                점검해 최상의 퍼포먼스를 보장합니다. 이렇게 생성된 각 모델들의
                결과는 합리적이고 일정하며 캠페인 단위의 분석과 예측의 기반이
                됩니다.
              </SectionGridDescription>
            </div>
          </SectionGrid>
        </>
      ),
    },
  };

  return (
    <>
      <SectionTitle>{DATA.sectionTitle[language]}</SectionTitle>
      <SectionBody>{DATA.sectionBody[language]}</SectionBody>
      <Divider />
    </>
  );
};

const SectionTitle = styled.h2`
  font-size: 44px;
  line-height: 50px;
  font-family: 'Poppins';
  font-weight: 300;
  width: auto;
  max-width: 680px;
  color: black;
  margin-bottom: 40px;
  align-text: left;
`;

const SectionBody = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-family: Arial;
  width: auto;
  color: black;
  font-weight: 200;
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 100%;
  margin-right: 40px;
  margin-top: 80px;
  margin-bottom: 48px;
`;

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  margin-top: 40px;
`;

const SectionGridTitle = styled.div`
  font-size: 22px;
  line-height: 32px;
  font-family: Arial;
  width: auto;
  color: black;
  font-weight: bold;
`;

const SectionGridDescription = styled.div`
  font-size: 16px;
  font-family: Arial;
  line-height: 26px;
  color: black;
  margin-top: 10px;
`;
