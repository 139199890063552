import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import NavDesktop from '../../components/Nav/Desktop/NavDesktop';
import NavMobile from '../../components/Nav/Mobile/NavMobile';

import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';

import Services from './Services';
import ServicesMobile from './ServicesMobile';

function ServiceMain() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const isDesktop = useMediaQuery({ minWidth: 981 });

  return (
    <>
      {isDesktop ? <NavDesktop /> : <NavMobile />}
      <Wrapper>
        <Services />
        <Footer />
      </Wrapper>
    </>
  );
}

export default ServiceMain;

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: auto;
`;
