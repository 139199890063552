import styled from 'styled-components';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  height: auto;
  transition: all ease 4s;
  ${({ theme }) => theme.animation.fadeInBottom}
  @media (min-width: 1440px) {
    padding-left: calc((100% - 1440px) / 2);
    padding-right: calc((100% - 1440px) / 2);
  }
  margin-bottom: 140px;
`;

const MainWrapper = styled.div`
  margin-top: 68px;
  background-color: black;
  height: 580px;
  padding: 60px 40px 40px 40px;
`;

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 140px;
`;

const Title = styled.div`
  font-size: 44px;
  line-height: 50px;
  font-family: 'Poppins';
  width: 500px;
  color: white;
`;

const DescriptionArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TitleDescription = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-family: Arial;
  font-weight: regular;
  width: 480px;
  color: white;
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const MWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 40px 20px 40px 20px;
  bottom: 40px;
  transition: all ease 4s;
  ${({ theme }) => theme.animation.fadeInBottom}

  margin-top: 56px;
  background-color: black;
  gap: 20px;
`;

const MTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MTitle = styled.text`
  font-size: 32px;
  line-height: 38px;
  font-family: 'Poppins';
  width: 100%;
  color: white;
  margin-bottom: 20px;
  text-align: center;
`;

const MDescriptionArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MTitleDescription = styled.text`
  font-size: 18px;
  line-height: 24px;
  font-family: Arial;
  font-weight: regular;
  width: 100%;
  color: white;
  text-align: center;
  margin: 10px 0px 10px 0px;
`;

const MImage = styled.img`
  width: 100vw;
  height: auto;
  padding: 20px;
`;

export {
  Wrapper,
  MainWrapper,
  MainGrid,
  TitleArea,
  Title,
  DescriptionArea,
  TitleDescription,
  ButtonArea,
  MWrapper,
  MTitleArea,
  MTitle,
  MDescriptionArea,
  MTitleDescription,
  MImage,
};
