import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
${reset}

  * { 
    box-sizing: border-box;
    text-decoration: none;
    font-family: Arial, 'Poppins';
    color: black;
  }

  body{
    font-family: Arial, 'Poppins';
    background-color: rgba(255,255,255,0.98);
  }

  input,
  button{
    border:none;
    background: transparent;
  }

  input:focus,
  input:active,
  button:focus,
  button:active {
    outline: none;
  }
`;

export default GlobalStyle;
