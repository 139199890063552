import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main/Main';
import AboutUs from './pages/AboutUs/AboutUs';
import Pricing from './pages/Pricing/Pricing';
import Blog from './pages/Blog/Blog';
import BlogDetail from './pages/Blog/BlogDetail';
import TermsAndPrivacy from './pages/TermsAndPrivacy/TermsAndPrivacy';
import ServiceMain from './pages/Services/ServiceMain';
import Playbook from './pages/Solutions/Playbook';
import GenerativeAI from './pages/Solutions/GenerativeAI';

function Router() {
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    // Detect user's browser language
    const userLanguage = navigator.language.split('-')[0]; // Get the first part of the language code
    if (userLanguage === 'kr') {
      setLanguage('kr');
    } else return;
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/services' element={<ServiceMain />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog/:EP' element={<BlogDetail />} />
        <Route path='/playbook' element={<Playbook />} />
        <Route path='ai' element={<GenerativeAI />} />
        <Route path='/cookie' element={<TermsAndPrivacy type='cookies' />} />
        <Route path='/terms' element={<TermsAndPrivacy type='terms' />} />
        <Route path='/privacy' element={<TermsAndPrivacy type='privacy' />} />
        <Route path='*' element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
