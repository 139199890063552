import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as TrendingUp } from '../../../assets/icons/trendingUp.svg';
import { ReactComponent as TrendingUpSmall } from '../../../assets/icons/TrendingUps.svg';
export default function PercentCard(props) {
  const { title, percentage } = props;
  const diffColor = '#3BA0EB';
  const isDesktop = useMediaQuery({ minWidth: 981 });
  return (
    <CardWrapper>
      <TotalElement>
        <TotalLabel>{title}</TotalLabel>
        <CompareArea>
          {isDesktop ? <TrendingUp /> : <TrendingUpSmall />}
          <PercentArea diffColor={diffColor}>{percentage}%</PercentArea>
        </CompareArea>
      </TotalElement>
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  margin: 2px;
`;
const TotalElement = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 18px 24px 18px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);

  @media (max-width: 400px) {
    padding: 12px 8px 12px 8px;
  }
`;

const CompareArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 14px;
  gap: 6px;
`;
const PercentArea = styled.span`
  font-size: 32px;
  font-family: 'Arial'

  font-weight: 500;
  color: ${({ diffColor }) => diffColor};
  @media (max-width: 400px) {
    font-size: 24px;
  }
`;

const TotalLabel = styled.span`
  color: #242b35;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'Arial';

  @media (max-width: 400px) {
    font-size: 14px;
  }
`;
