import React, { useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../../contextApi/LanguageContext';

export const DataGovContentMobile = () => {
  const { language } = useContext(LanguageContext);
  const DATA = {
    sectionTitle: {
      en: 'Data Governance',
      ko: '데이터 거버넌스',
    },
    sectionBody: {
      en: (
        <>
          <SectionGrid>
            <div>
              <SectionGridTitle>Customized Data Extraction</SectionGridTitle>
              <SectionGridDescription>
                Customized data extraction from tens of media channels to fit
                various business needs.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Automated Data Processing</SectionGridTitle>
              <SectionGridDescription>
                Upon extraction, data is cleaned and processed in preparation
                for various visualizations and modeling.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Seamless Integrations</SectionGridTitle>
              <SectionGridDescription>
                Industry Standard OAuth2 Integrations ensure privacy and
                security.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Data Coherence</SectionGridTitle>
              <SectionGridDescription>
                Data pipelines are health checked and inspected for any
                anomalies.
              </SectionGridDescription>
            </div>
          </SectionGrid>
        </>
      ),
      ko: (
        <>
          <SectionGrid>
            <div>
              <SectionGridTitle>Customized Data Extraction</SectionGridTitle>
              <SectionGridDescription>
                각 매체의 특징을 고려한 맞춤형 데이터 추출 작업을 거칩니다.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Automated Data Processing</SectionGridTitle>
              <SectionGridDescription>
                가져온 데이터는 다양한 시각화와 모델링을 위해 자동화된 정제와
                변환 작업을 거칩니다.
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Seamless Integrations</SectionGridTitle>
              <SectionGridDescription>
                업계 표준인 OAuth2를 통해 데이터 보안과 개인정보 보호를
                보장합니다..
              </SectionGridDescription>
            </div>
            <div>
              <SectionGridTitle>Data Coherence</SectionGridTitle>
              <SectionGridDescription>
                데이터 파이프라인은 규칙적인 모니터링을 통해 상태를 점검합니다.
              </SectionGridDescription>
            </div>
          </SectionGrid>
        </>
      ),
    },
  };

  return (
    <>
      <SectionTitle>{DATA.sectionTitle[language]}</SectionTitle>
      <SectionBody>{DATA.sectionBody[language]}</SectionBody>
    </>
  );
};

const SectionTitle = styled.h2`
  font-size: 44px;
  line-height: 50px;
  font-family: 'Poppins';
  font-weight: 300;
  width: auto;
  max-width: 680px;
  color: black;
  margin-bottom: 40px;
  align-text: left;
`;

const SectionBody = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-family: Arial;
  width: auto;
  color: black;
  font-weight: 200;
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 100%;
  margin-right: 40px;
  margin-top: 80px;
  margin-bottom: 48px;
`;

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 4fr);
  grid-gap: 40px;
  margin-top: 40px;
  margin-bottom: 100px;
`;

const SectionGridTitle = styled.div`
  font-size: 22px;
  line-height: 32px;
  font-family: Arial;
  width: auto;
  color: black;
  font-weight: bold;
`;

const SectionGridDescription = styled.div`
  font-size: 16px;
  font-family: Arial;
  line-height: 26px;
  max-width: 480px;
  color: black;
  margin-top: 10px;
`;
