import React from 'react';
import styled from 'styled-components';

function PricingDetail({ data }) {
  const { title, notes, items } = data;
  return (
    <Wrapper>
      <TitleArea>
        <Title>{title}</Title>
        <Notes>{notes}</Notes>
      </TitleArea>
      <ListArea>
        {items.map((item, idx) => (
          <ItemArea key={idx}>
            <Key>{item.key}: </Key>
            <Value>{item.value}</Value>
          </ItemArea>
        ))}
      </ListArea>
    </Wrapper>
  );
}

export default PricingDetail;

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  font-family: 'Poppins';
`;

const TitleArea = styled.div`
  margin-bottom: 29px;
`;

const Title = styled.h6`
  width: fit-content;
  margin-bottom: 0px;
  border-bottom: solid 1px;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  color: #fff;
`;

const Notes = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  color: #fff;
`;

const ListArea = styled.ul``;

const ItemArea = styled.li`
  margin-bottom: 12px;
`;

const Key = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
`;

const Value = styled.span`
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
`;
